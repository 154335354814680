import React from 'react';

import {Spinner} from 'components/atoms/Spinner';
import {Footer} from 'features/navigation/Footer';
import {Navbar} from 'features/navigation/Navbar';

export const Loading: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-between">
      <div>
        <Navbar />
        <div className="flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      </div>
      <Footer />
    </div>
  );
};
