import React from 'react';
import {RadioGroup} from '@headlessui/react';
import {classNames} from 'lib/styles';
import {CheckCircleIcon} from '@heroicons/react/20/solid';

export type Props = {
  options: {
    value: string;
    label: React.ReactChild;
    description: React.ReactChild;
    id?: string;
  }[];
  onChange: (e: string) => void;
  value: string;
};

export const StyledRadioGroup = ({options, value, onChange}: Props) => {
  return (
    <RadioGroup value={value} onChange={e => onChange(e)} className="w-full">
      <div className="grid grid-cols-1 mt-4 gap-y-4 sm:grid-cols-1 sm:gap-x-4">
        {options.map(option => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            id={option?.id}
            className={({checked, active}) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-blue-500 ring-2 ring-blue-500' : '',
                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({checked, active}) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label className="block font-semibold text-gray-900 cursor-pointer text-md">
                      {option.label}
                    </RadioGroup.Label>
                    <RadioGroup.Description className="flex items-center mt-1 text-sm text-gray-600">
                      {option.description}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? 'invisible' : '',
                    'h-5 w-5 text-blue-600'
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
