import * as React from 'react';
import {Drawer as DrawerPrimitive} from 'vaul';
import {cn} from '../lib/utils';
import {FC, ReactElement, ReactNode, useState} from 'react';
import {Button} from './Button';

const DrawerContainer = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    {...props}
  />
);
DrawerContainer.displayName = 'DrawerContainer';

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({className, ...props}, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn('fixed inset-0 z-50 bg-black/80', className)}
    {...props}
  />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({className, children, ...props}, ref) => (
  <DrawerPortal>
    <DrawerOverlay />
    <DrawerPrimitive.Content
      ref={ref}
      className={cn(
        'fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border bg-background max-h-[96%]',
        className
      )}
      {...props}
    >
      <div className="mx-auto mt-4 h-[5px] w-[32px] rounded-full bg-drawer" />
      <div className="flex flex-col w-full max-w-md mx-auto overflow-auto">
        {children}
      </div>
    </DrawerPrimitive.Content>
  </DrawerPortal>
));
DrawerContent.displayName = 'DrawerContent';

const DrawerHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('grid gap-1.5 p-4 text-center', className)} {...props} />
);
DrawerHeader.displayName = 'DrawerHeader';

const DrawerFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('mt-auto flex flex-col gap-2 py-4', className)}
    {...props}
  />
);
DrawerFooter.displayName = 'DrawerFooter';

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({className, ...props}, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn(
      'text-lg font-semibold leading-none tracking-tight',
      className
    )}
    {...props}
  />
));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({className, ...props}, ref) => (
  <DrawerPrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

type DrawerProps = {
  open?: boolean;
  trigger?: ReactNode;
  title: string;
  description?: ReactNode;
  confirmButton?: ReactElement | string;
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => void;
  children: ReactNode;
  disabled?: boolean;
};

const Drawer: FC<DrawerProps> & {
  Footer: FC<React.HTMLAttributes<HTMLDivElement>>;
  CloseButton: FC<DrawerCloseButtonProps>;
} = ({
  title,
  description,
  onConfirm,
  onCancel,
  confirmButton,
  trigger,
  disabled,
  children,
  open,
}) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  return (
    <DrawerContainer open={open} onClose={onCancel}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle className="text-xl font-bold text-foreground">
            {title}
          </DrawerTitle>
          {description && <DrawerDescription>{description}</DrawerDescription>}
        </DrawerHeader>
        {children}
        {confirmButton && (
          <DrawerFooter className="p-4">
            {typeof confirmButton === 'string' ? (
              <Button
                disabled={isConfirmLoading || disabled}
                isLoading={isConfirmLoading}
                onClick={async () => {
                  setIsConfirmLoading(true);
                  await onConfirm?.();
                  setIsConfirmLoading(false);
                }}
              >
                {confirmButton}
              </Button>
            ) : (
              confirmButton
            )}
            <Drawer.CloseButton disabled={disabled} onClick={onCancel} />
          </DrawerFooter>
        )}
      </DrawerContent>
    </DrawerContainer>
  );
};

Drawer.Footer = DrawerFooter;

type DrawerCloseButtonProps = Pick<DrawerProps, 'disabled'> & {
  children?: ReactNode;
  onClick?: () => void;
};

Drawer.CloseButton = ({
  disabled,
  children,
  onClick,
}: DrawerCloseButtonProps) => {
  return (
    <DrawerClose onClick={onClick}>
      <Button as="div" variant="outline" disabled={disabled}>
        {children ?? 'Cancel'}
      </Button>
    </DrawerClose>
  );
};

export {
  Drawer,
  DrawerContainer,
  DrawerPortal,
  DrawerOverlay,
  DrawerTrigger,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerDescription,
};
