import {GetAuthKeyQuery, useGetAuthKeyQuery} from 'lib/graphql/API';
import {
  GetAuthKeyAnonymouslyQuery,
  useGetAuthKeyAnonymouslyQuery,
} from 'lib/graphql/publicAPI';
import {getBillerSlugFromUrl} from 'lib/url';
import {useMemo} from 'react';

export function useGetAuthKey({anonymous}: {anonymous?: boolean}) {
  const billerSlug = getBillerSlugFromUrl();
  const authKey = anonymous
    ? useGetAuthKeyAnonymouslyQuery({
        variables: {
          billerSlug,
        },
      })
    : useGetAuthKeyQuery();

  const result = useMemo(() => {
    const data = anonymous
      ? (authKey.data as GetAuthKeyAnonymouslyQuery)?.authKeyAnonymously
      : (authKey.data as GetAuthKeyQuery)?.authKey;

    return {...authKey, data};
  }, [authKey]);

  return result;
}
