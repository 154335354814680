import {FC} from 'react';
import {Icon} from './Icon';

import {inputVariants} from './Input';
import {cn} from '../lib/utils';
import {validatePhoneNumber} from 'payble-shared';

export type InputPhoneProps = React.InputHTMLAttributes<HTMLInputElement>;

const InputPhone: FC<InputPhoneProps> = ({
  value,
  onChange = () => {},
  className,
  placeholder = '0400 000 000',
  disabled,
}) => {
  return (
    <div className="relative">
      <input
        value={value}
        onChange={onChange}
        type="tel"
        autoFocus={true}
        maxLength={14}
        className={cn(inputVariants({className}), 'pl-10')}
        placeholder={placeholder}
        disabled={disabled}
      />
      <div className="absolute top-0 left-0 flex items-center h-full pl-3 text-icon">
        <Icon name="phone" />
      </div>
    </div>
  );
};

InputPhone.displayName = 'InputPhone';

const isValidPhoneNumber = (value: string) => {
  try {
    return validatePhoneNumber(value);
  } catch (e) {
    return false;
  }
};

export {InputPhone, isValidPhoneNumber};
