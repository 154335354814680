import {AbsoluteDate} from 'payble-shared';
import {FieldPolicy} from '@apollo/client';

export const AbsoluteDatePolicy: FieldPolicy<
  AbsoluteDate,
  string | AbsoluteDate
> = {
  merge: (_, incoming, schema) => {
    if (incoming == undefined) {
      return incoming;
    }

    if (incoming instanceof AbsoluteDate) {
      return incoming;
    }

    const date = AbsoluteDate.maybeFromISO(incoming);
    if (!date) {
      throw new Error(
        `${schema.fieldName} received invalid date: ${JSON.stringify(incoming)}`
      );
    }
    return date;
  },
};
