import React, {useContext, useEffect, useState} from 'react';
import * as Auth from 'lib/auth/Auth';
import {StyledSwitchGroup} from 'features/setup/components/StyledSwitchGroup';
import {useActor} from '@xstate/react';
import {useBillerConfig} from '../../lib/appConfig/useBillerConfig';

type Props = {
  onChange: (planIds: string[]) => void;
};

export const ApplyPaymentMethodToPlans = ({onChange}: Props) => {
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const activePlans = authState.context.instalmentPlans.filter(
    plan => !['cancelled', 'completed'].includes(plan.status)
  );
  const billerConfig = useBillerConfig();

  const [plansToUpdate, setPlansToUpdate] = useState<string[]>(
    activePlans.map(({id}) => id)
  );
  const [usePaymentMethodForAllPlans, setUsePaymentMethodForAllPlans] =
    useState(true);

  useEffect(() => {
    setPlansToUpdate(
      usePaymentMethodForAllPlans ? activePlans.map(({id}) => id) : []
    );
  }, [usePaymentMethodForAllPlans]);

  useEffect(() => {
    onChange(plansToUpdate);
  }, [plansToUpdate]);

  return (
    <>
      <StyledSwitchGroup
        checked={usePaymentMethodForAllPlans}
        onChange={() =>
          setUsePaymentMethodForAllPlans(!usePaymentMethodForAllPlans)
        }
      >
        Use this payment method for all active plans.
      </StyledSwitchGroup>

      {!usePaymentMethodForAllPlans &&
        activePlans.map(({account, id}) => (
          <StyledSwitchGroup
            key={id}
            checked={plansToUpdate.includes(id)}
            onChange={() =>
              setPlansToUpdate(
                plansToUpdate.includes(id)
                  ? plansToUpdate.filter(i => i !== id)
                  : [...plansToUpdate, id]
              )
            }
          >
            {`Use payment method for ${billerConfig.getAccountTypeTitle(
              account.type
            )} - ${account.description}`}
          </StyledSwitchGroup>
        ))}
    </>
  );
};
