import {FC, ReactNode} from 'react';
import {FormField} from './FormField';
import {useRegisterField} from '../hooks/useRegisterField';
import {Select, SelectProps} from './Select';

export interface FormSelectProps extends SelectProps {
  label?: ReactNode;
  name: string;
}

export const FormSelect: FC<FormSelectProps> = ({label, name, ...props}) => {
  const {id, field, form} = useRegisterField(name);

  return (
    <FormField id={id} name={name} label={label}>
      <Select
        id={id}
        {...props}
        {...field}
        disabled={
          props.disabled || field.disabled || form.formState.isSubmitting
        }
      />
    </FormField>
  );
};
