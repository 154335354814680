const BANK_NAME_OVERRIDES: Record<string, {newName: string}> = {
  WBC: {newName: 'Westpac'},
};

const BSB_NAME_OVERRIDES: Record<string, {newName: string}> = {
  '633-123': {newName: 'UP'},
  '704-230': {newName: 'VIC'},
};

export const getBankNameOverrides = (bsb: string, name: string) => {
  if (BANK_NAME_OVERRIDES?.[bsb]) {
    return BANK_NAME_OVERRIDES?.[name].newName;
  }

  if (BSB_NAME_OVERRIDES?.[bsb]) {
    return BSB_NAME_OVERRIDES?.[bsb].newName;
  }

  return name;
};

export function getFormattedBsb(bsb: string) {
  const unformattedBsb = getUnformattedBsb(bsb);
  const formattedBsb = `${unformattedBsb.slice(0, 3)}-${unformattedBsb.slice(
    3,
    6
  )}`;
  return formattedBsb;
}

export function getUnformattedBsb(bsb: string) {
  return bsb.replaceAll('-', '').trim();
}
