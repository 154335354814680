export type CardImageBrand =
  | 'masterCard'
  | 'visa'
  | 'americanExpress'
  | 'unknown'
  | 'diners'
  | 'jcb';

type CardImageType = CardImageBrand | 'error';

export const CARD_IMAGE: {
  [key in CardImageType]: string;
} = {
  unknown: '/cms/app-shared/card/empty.svg',
  jcb: '/cms/app-shared/card/empty.svg',
  diners: '/cms/app-shared/card/empty.svg',
  americanExpress: '/cms/app-shared/card/amex-small.svg',
  masterCard: '/cms/app-shared/card/mastercard-small.svg',
  visa: '/cms/app-shared/card/visa-small.svg',
  error: '/cms/app-shared/card/error-small.svg',
};
