import React from 'react';
import {createRoot} from 'react-dom/client';

import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import App from './App';

Sentry.init({
  dsn: 'https://faf845776a9949b49fb04cfe481edaa2@o1025930.ingest.sentry.io/6118201',
  integrations: [new Integrations.BrowserTracing()],
  environment: import.meta.env.VITE_STAGE as string,
  tracesSampler: __context => {
    if (window.location.hostname === 'localhost') {
      return 0;
    } else {
      return 1;
    }
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
