import {useNavigateWithCurrentParams} from 'lib/navigation/useNavigateWithSearchParams';

const BASE_URL = '/biller/:slug/pay-now';
type AnonymousPaymentSearchParams = {
  amountInCents: string;
};
export function useAnonymousPaymentNavigate() {
  const navigate = useNavigateWithCurrentParams<AnonymousPaymentSearchParams>();

  return (path: string, searchParams?: AnonymousPaymentSearchParams) => {
    navigate(`${BASE_URL}${path}`, searchParams);
  };
}
