import * as React from 'react';
import {cn} from '../lib/utils';

const List = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({className, ...props}, ref) => (
  <div
    ref={ref}
    className={cn('flex flex-col space-y-4', className)}
    {...props}
  />
));

List.displayName = 'List';

const ListItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({className, ...props}, ref) => (
  <div
    ref={ref}
    className={cn('flex justify-items-stretch gap-x-4', className)}
    {...props}
  />
));

ListItem.displayName = 'ListItem';

const ListItemColumnContent = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({className, ...props}, ref) => (
  <div ref={ref} className={cn('flex justify-start', className)} {...props} />
));

ListItemColumnContent.displayName = 'ListItemContent';

export {List, ListItem, ListItemColumnContent};
