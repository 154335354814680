import {z} from 'zod';
import {SurchargeRate} from '../valueObjects';
import {CARD_BRAND, CARD_TYPE} from './CardInfo';

const zSurcharge = z
  .number()
  .transform(n => SurchargeRate.fromJSON(n))
  .or(z.instanceof(SurchargeRate));

const BrandedAndTypedSurchargeSchema = z.object({
  type: z.literal('card_brand_and_type'),
  cardType: z.enum(CARD_TYPE),
  cardBrand: z.enum(CARD_BRAND),
  surchargeRate: zSurcharge,
});

const BrandedCardSurchargeSchema = z.object({
  type: z.literal('card_brand'),
  cardBrand: z.enum(CARD_BRAND),
  surchargeRate: zSurcharge,
});

const TypedCardSurchargeSchema = z.object({
  type: z.literal('card_type'),
  cardType: z.enum(CARD_TYPE),
  surchargeRate: zSurcharge,
});

const PaymentTypeSurchargeSchema = z.object({
  type: z.literal('card'),
  /**
   * In some scenarios we may want to apply the minimum surcharge
   * to all card payments that are accepted but their type may
   * not be "DEBIT" or "CREDIT".
   *
   * This hids the surcharge from the table of surcharges but
   * should be at most the minimum surcharge in the list.
   */
  fallback: z.boolean().optional(),
  surchargeRate: zSurcharge,
});

export type SurchargeConfigInput = z.input<typeof SurchargeConfigSchema>;
export type SurchargeConfig = z.infer<typeof SurchargeConfigSchema>;
const PRIORITY = [
  'card_brand_and_type',
  'card_brand',
  'card_type',
  'card',
] as const;

export const getSurchargeItemKey = (surcharge: SurchargeConfig[number]) => {
  switch (surcharge.type) {
    case 'card_brand_and_type':
      return `card:${surcharge.cardBrand}_${surcharge.cardType}`;
    case 'card_brand':
      return `card:${surcharge.cardBrand}`;
    case 'card_type':
      return `card:${surcharge.cardType}`;
    case 'card':
      return 'card';
  }
};
export const SurchargeConfigSchema = z
  .array(
    z.union([
      BrandedAndTypedSurchargeSchema,
      BrandedCardSurchargeSchema,
      TypedCardSurchargeSchema,
      PaymentTypeSurchargeSchema,
    ])
  )
  /**
   * Internal logic for the SurchargeCalculator assumes that the surcharges are
   * listed in order of priority.
   */
  .transform(surcharges =>
    surcharges.sort(
      (a, b) => PRIORITY.indexOf(a.type) - PRIORITY.indexOf(b.type)
    )
  );
