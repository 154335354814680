import {useId} from 'react';
import {useFormContext} from 'react-hook-form';

export const useRegisterField = (name: string) => {
  const form = useFormContext();
  const id = useId();
  const fieldId = `${name}-${id}`;
  const field = form.register(name);

  return {form, id: fieldId, field};
};
