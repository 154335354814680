import {cn} from '../lib/utils';

export const MaxContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        'z-10 flex flex-col flex-1 w-full h-full max-w-xl px-4 py-6 m-auto lg:py-16',
        className
      )}
    >
      {children}
    </div>
  );
};
