import {navigate as navigateTo} from 'lib/navigation/routes';
import {goBack} from 'lib/navigation/routes';
import React, {useEffect} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';

import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {Debbie} from 'components/organisms/Debbie';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {markDownToHtml} from 'lib/markDownToHtml';

export const CatchupFeatures = () => {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = useBillerConfig();
  const navigate = useSetupNavigate();

  useEffect(() => {
    if (!billerConfig.hasCatchUpPlans) {
      navigateTo('/biller/:slug/setup', {slug: billerSlug});
    }
  }, [billerConfig]);

  const terms = billerConfig.catchUpPlanTerms;

  if (!terms) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong"
        debbieMessage=""
        onClick={() => {
          navigateTo('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  return (
    <>
      <Debbie title="How does this look to you?" />
      <div className="flex flex-col items-center max-w-sm m-auto">
        <div className="mt-4 overflow-hidden rounded-md shadow ">
          <div className="px-4 py-4 bg-white sm:p-6">
            <div className="flex flex-col justify-center flex-1 h-full">
              {terms.map((term, index) => (
                <p
                  className="mt-2 mb-2 [&>a]:underline [&>ul]:list-disc [&>ul]:ml-4 [&>em]:italic [&>b]:font-bold [&>strong]:font-bold"
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: markDownToHtml(term, [
                      'a',
                      'b',
                      'strong',
                      'ul',
                      'li',
                      'em',
                    ]),
                  }}
                />
              ))}
            </div>
          </div>
        </div>

        <button
          type="button"
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() => navigate('/biller/:slug/setup/catch-up/schedule')}
        >
          Accept and Continue
        </button>
        <button
          className="mt-6 text-blue-600 transition hover:text-blue-700"
          onClick={() => goBack()}
        >
          Back
        </button>
      </div>
    </>
  );
};
