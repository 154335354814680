import {DomainError} from './DomainError';
import * as standardErrors from './StandardErrors';
import {Errors} from './Errors';
import {errs} from '..';

interface ApolloError {
  graphQLErrors: ReadonlyArray<{
    extensions: Record<string, any>;
    message: string;
  }>;

  networkError?: // Union to any so that Apollo types don't complain
  | any
    | {
        result?: {
          errors?: ReadonlyArray<{
            message: string;
            extensions: Record<string, any>;
          }>;
        };
      };
}

const errorMap = Object.fromEntries(
  Object.values(standardErrors).map(Klass => {
    const errorCode = Klass.prototype.code;

    return [errorCode, Klass];
  })
);

export const fromGraphQL = (root: ApolloError) => {
  const errors: DomainError[] = [];

  root.graphQLErrors.forEach(error => {
    const errorCode = error?.extensions?.code;
    const Klass = errorMap[errorCode] || standardErrors.UnexpectedError;

    const domainErr = Klass.create(error.message);
    domainErr.hydrateContext(error.extensions);
    errors.push(domainErr);
  });

  root.networkError?.result?.errors?.forEach?.(
    (error: ApolloError['graphQLErrors'][number]) => {
      const errorCode = error?.extensions?.code;
      const Klass = errorMap[errorCode] || standardErrors.UnexpectedError;

      const domainErr = Klass.create(error.message);
      domainErr.hydrateContext(error.extensions);
      errors.push(domainErr);
    }
  );

  return new Errors(errors);
};

interface HTTPError {
  code: string;
  message: string;
  context: Record<string, unknown>;
}

function isHTTPError(error: unknown): error is HTTPError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'code' in error &&
    'message' in error &&
    'context' in error
  );
}

export const fromHTTP = (response: unknown): DomainError => {
  if (!isHTTPError(response)) {
    return errs.UnexpectedError.create('Something went wrong', {
      response,
    });
  }
  const Klass = errorMap[response.code] || standardErrors.UnexpectedError;
  const domainErr = Klass.create(response.message, {
    response,
  });
  domainErr.hydrateContext(response.context);
  return domainErr;
};

export interface ReactQueryClientError {
  response: {
    errors: ReadonlyArray<{
      extensions: Record<string, any>;
      message: string;
    }>;
  };
  status: number;
}
export const fromReactQueryClient = (root: ReactQueryClientError) => {
  const errors: DomainError[] = [];

  root.response?.errors?.forEach(error => {
    const errorCode = error?.extensions?.code;
    const Klass = errorMap[errorCode] || standardErrors.UnexpectedError;

    const domainErr = Klass.create(error.message);
    domainErr.hydrateContext(error.extensions);
    errors.push(domainErr);
  });

  return new Errors(errors);
};
