import {
  NotificationSettingGroupConfig,
  NotificationSettingGroupName,
  NotificationTypes,
  NotificationTypeValue,
} from 'payble-shared';
import {z} from 'zod';

type NotificationTypeEnabled = Record<
  NotificationTypeValue,
  z.ZodOptional<z.ZodBoolean>
>;
type NotificationGroupSettingSchema = Record<
  NotificationSettingGroupName,
  z.ZodObject<NotificationTypeEnabled>
>;

export const notificationSettingsSchema = z.object(
  Object.keys(NotificationSettingGroupConfig).reduce(
    (acc, name) => ({
      ...acc,
      [name]: z.object(
        Object.values(NotificationTypes).reduce(
          (acc, type) => ({
            ...acc,
            [type.value]: z.boolean().optional(),
          }),
          {}
        )
      ),
    }),
    {} as NotificationGroupSettingSchema
  )
);
