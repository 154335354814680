import React from 'react';
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import {getBillerSlugFromUrl} from 'lib/url';
import {Field} from 'formik';
import {AccountTypeInfo} from 'payble-shared';
import {getAccountExternalIdLabel} from 'payble-shared/src/biller-config/handlers';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {BillerConfigComponent} from 'components/molecules/BillerConfigComponent';

type LookupAccountExternalIdInputProps = {
  accountTypeInfo: AccountTypeInfo;
  disabled: boolean;
};

const inputClasses =
  'mt-2 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300';

export const LookupAccountExternalIdInput: React.FC<
  LookupAccountExternalIdInputProps
> = ({accountTypeInfo, disabled}) => {
  const externalIdLabel = getAccountExternalIdLabel(
    getBillerSlugFromUrl(),
    accountTypeInfo.id
  );

  return (
    <div
      className="col-span-6 sm:col-span-3"
      data-testid="accountExternalIdForm"
    >
      <label
        htmlFor="accountExternalId"
        className="block text-sm font-bold text-navy"
      >
        {externalIdLabel}
      </label>
      <label className="text-sm font-medium text-gray-900">
        Enter the {externalIdLabel.toLowerCase()}{' '}
      </label>

      <Field name="accountExternalId">
        {({field, form: {setFieldValue}}: any) => (
          <>
            {accountTypeInfo.externalIdNumberformat ? (
              <NumberFormat
                name={field.name}
                id="accountExternalId"
                data-testid="numberInput"
                value={field.value}
                format={accountTypeInfo.externalIdNumberformat}
                placeholder={accountTypeInfo.externalIdNumberformat}
                mask="#"
                onValueChange={(values: NumberFormatValues) => {
                  setFieldValue(field.name, values.formattedValue);
                }}
                spellCheck={false}
                autoComplete="off"
                className={inputClasses}
              />
            ) : (
              <input
                name="accountExternalId"
                id="accountExternalId"
                data-testid={'textInput'}
                type="text"
                className={inputClasses}
                autoComplete={'off'}
                disabled={disabled}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export const VerifyAccountForm = () => {
  const billerConfig = useBillerConfig();
  const {accountType} = useSetupSearchParams();

  const config = billerConfig.getAccountTypeVerifyAccountDetails(accountType);

  return (
    <div className="col-span-6 mt-4 sm:col-span-3">
      <label className="block text-sm font-bold text-navy">
        Verify account details
      </label>
      <label className="text-sm font-medium text-gray-900">
        {config ? (
          <BillerConfigComponent config={config} />
        ) : (
          <>
            Enter the{' '}
            {billerConfig.getAccountTypeVerificationCodeSource(accountType)}.
          </>
        )}
      </label>
      <Field name="verificationCode">
        {({field}: any) => (
          <input
            id="verificationCode"
            data-testid="verificationCode"
            type="text"
            className={inputClasses}
            autoComplete={'off'}
            {...field}
          />
        )}
      </Field>
    </div>
  );
};
