import {Result} from 'payble-shared';
import * as Sentry from '@sentry/react';

import {
  CreateInstalmentPlanMutation,
  CreateInstalmentPlanInput,
  CreateInstalmentPlanDocument,
  PayAccountInFullInput,
  PayAccountInFullOutput,
  PayAccountInFullMutation,
  PayAccountInFullDocument,
  PayAccountInFullMutationVariables,
  PayAccountAmountOutput,
  PayAccountAmountMutation,
  PayAccountAmountDocument,
  PayAccountAmountMutationVariables,
  InstalmentFrequency,
  CreateInstalmentPlanMutationVariables,
  GetAuthKeyQuery,
  GetAuthKeyDocument,
  InstalmentPlan,
  PayInstalmentMutation,
  PayInstalmentMutationVariables,
  PayInstalmentDocument,
  InstalmentPlanMode,
} from 'lib/graphql/API';
import {client} from 'lib/graphql/client';

type CreatePlanOptions = {
  accountId: string;
  paymentMethodId: string;
  frequency: InstalmentFrequency;
};

export async function createInstalmentPlan({
  accountId,
  paymentMethodId,
  frequency,
}: CreatePlanOptions): Promise<Result<InstalmentPlan>> {
  await new Promise(r => setTimeout(r, 1000));

  const input: CreateInstalmentPlanInput = {
    accountId,
    paymentMethodId,
    frequency,
    instalmentCount: 1,
    payMode: InstalmentPlanMode.PayEveryX,
  };

  try {
    const result = await client.mutate<
      CreateInstalmentPlanMutation,
      CreateInstalmentPlanMutationVariables
    >({
      mutation: CreateInstalmentPlanDocument,
      variables: {
        input,
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      return new Error(result.errors[0].message);
    }

    return result?.data?.createInstalmentPlan as InstalmentPlan;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}

type PayInFullOptions = {
  accountId: string;
  paymentMethodId: string;
};

export async function payAccountInFull({
  accountId,
  paymentMethodId,
}: PayInFullOptions): Promise<Result<PayAccountInFullOutput>> {
  const input: PayAccountInFullInput = {
    accountId,
    paymentMethodId,
  };

  try {
    const result = await client.mutate<
      PayAccountInFullMutation,
      PayAccountInFullMutationVariables
    >({
      mutation: PayAccountInFullDocument,
      variables: {
        input,
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      return new Error(result.errors[0].message);
    }

    return result?.data?.payAccountInFull as PayAccountInFullOutput;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}

type PayAmountOptions = {
  accountId: string;
  paymentMethodId: string;
  amountInCents: number;
};

export async function payAccountAmount(
  input: PayAmountOptions
): Promise<Result<PayAccountAmountOutput>> {
  try {
    const result = await client.mutate<
      PayAccountAmountMutation,
      PayAccountAmountMutationVariables
    >({
      mutation: PayAccountAmountDocument,
      variables: {
        input,
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      return new Error(result.errors[0].message);
    }

    return result?.data?.payAccountAmount as PayAccountAmountOutput;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}

type GetAuthKeyOptions = {
  billerSlug: string;
};

type GetAuthKeyResult = {
  authKey: string;
  environment: 'test' | 'production';
  origin: string;
  tokenExId: string;
};

export async function getAuthKey({
  billerSlug,
}: GetAuthKeyOptions): Promise<Result<GetAuthKeyResult>> {
  try {
    const result = await client.query<GetAuthKeyQuery>({
      query: GetAuthKeyDocument,
      variables: {
        billerSlug,
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      return new Error(result.errors[0].message);
    }

    return {
      ...result.data.authKey,
      environment: result.data.authKey.environment as 'test' | 'production',
    };
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}

export async function payInstalment(
  instalmentPlanId: string,
  instalmentId: string
): Promise<Result<InstalmentPlan>> {
  try {
    const result = await client.mutate<
      PayInstalmentMutation,
      PayInstalmentMutationVariables
    >({
      mutation: PayInstalmentDocument,
      variables: {
        input: {
          instalmentId,
          instalmentPlanId,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      return new Error(result.errors[0].message);
    }

    return result?.data?.payInstalment as InstalmentPlan;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}
