import {FC, ReactNode} from 'react';
import {FormField, FormFieldProps} from './FormField';
import {useRegisterField} from '../hooks/useRegisterField';
import {Switch, SwitchProps} from './Switch';
import {Controller} from 'react-hook-form';

export interface FormSwitchProps extends SwitchProps {
  label?: ReactNode;
  labelPosition?: FormFieldProps['labelPosition'];
  description?: ReactNode;
  name: string;
}

export const FormSwitch: FC<FormSwitchProps> = ({
  label,
  labelPosition = 'left',
  description,
  name,
  ...props
}) => {
  const {id, form} = useRegisterField(name);

  return (
    <FormField
      id={id}
      name={name}
      label={<div className="text-sm font-medium">{label}</div>}
      labelPosition={labelPosition}
      description={description}
    >
      <Controller
        control={form.control}
        name={name}
        render={({
          formState: {isSubmitting},
          field: {onChange, value, ...f},
        }) => (
          <Switch
            id={id}
            {...props}
            onCheckedChange={onChange}
            defaultChecked={value}
            {...f}
            disabled={props.disabled || f.disabled || isSubmitting}
          />
        )}
      />
    </FormField>
  );
};

FormSwitch.displayName = 'FormSwitch';
