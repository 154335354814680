import React from 'react';
import {getBillerSlugFromUrl} from 'lib/url';
import {goBack} from 'lib/navigation/routes';
import {PaymentAmount} from 'features/setup/shared/PaymentAmount';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {usePatchSearchParams} from '../../../lib/navigation/usePatchSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {getFeatureConfig} from 'payble-shared/src/biller-config/handlers';

export const FlexiblePayAmount = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {instalmentFrequency, amountInCents} = useSetupSearchParams();
  const {patch} = usePatchSearchParams();
  const navigate = useSetupNavigate();

  return (
    <PaymentAmount
      debbieTitle={`How much do you want to pay ${instalmentFrequency}?`}
      heading={`Pay ${instalmentFrequency}`}
      description={`Enter an amount to pay ${instalmentFrequency}`}
      amount={amountInCents}
      minAmount={getFeatureConfig(billerSlug).FLEXIBLE_INSTALMENTS_AMOUNT_MIN}
      maxAmount={getFeatureConfig(billerSlug).FLEXIBLE_INSTALMENTS_AMOUNT_MAX}
      lockedAmount={
        getFeatureConfig(billerSlug).FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED
      }
      defaultAmount={0}
      onChangeValue={value => {
        patch({
          amountInCents: value?.toString() || '',
        });
      }}
      onClickNext={() => {
        navigate('/biller/:slug/setup/flexible/schedule');
      }}
      onClickBack={goBack}
    />
  );
};
