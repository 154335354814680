import {FC, ReactNode} from 'react';
import {useRegisterField} from '../hooks/useRegisterField';
import {FormField} from './FormField';
import {Controller} from 'react-hook-form';
import {InputProps} from './Input';
import {InputDate} from './InputDate';

export interface FormInputDateProps extends InputProps {
  label?: ReactNode;
  name: string;
}

export const FormInputDate: FC<FormInputDateProps> = ({label, name}) => {
  const {id, form} = useRegisterField(name);

  return (
    <FormField id={id} name={name} label={label} className="flex-1">
      <Controller
        control={form.control}
        name={name}
        render={({field}) => <InputDate {...field} title={label?.toString()} />}
      />
    </FormField>
  );
};

FormInputDate.displayName = 'FormInputDate';
