import {
  RiAlertFill,
  RiAlertLine,
  RiArrowLeftFill,
  RiArrowLeftLine,
  RiArrowUpFill,
  RiArrowUpLine,
  RiCheckFill,
  RiCheckLine,
  RiFileList3Fill,
  RiFileList3Line,
  RiHome5Line,
  RiHome6Fill,
  RiInformationFill,
  RiInformationLine,
  RiNotificationFill,
  RiNotificationLine,
  RiSeoFill,
  RiSeoLine,
  RiSmartphoneFill,
  RiSmartphoneLine,
  RiUserSmileFill,
  RiUserSmileLine,
  RiMoneyDollarCircleFill,
  RiMoneyDollarCircleLine,
  RiRepeatFill,
  RiRepeatLine,
  RiMindMap,
  RiExchangeFundsLine,
  RiExchangeFundsFill,
  RiFilter3Fill,
  RiFilter3Line,
  RiDropFill,
  RiDropLine,
  RiCurrencyFill,
  RiCurrencyLine,
  RiFireFill,
  RiFireLine,
  RiLightbulbFlashFill,
  RiLightbulbFlashLine,
  RiCarFill,
  RiCarLine,
  RiAddFill,
  RiAddLine,
  RiPencilFill,
  RiPencilLine,
  RiArrowRightSFill,
  RiArrowRightSLine,
  RiBankCard2Fill,
  RiBankCard2Line,
  RiHandCoinFill,
  RiHandCoinLine,
  RiQuestionFill,
  RiQuestionLine,
  RiLogoutBoxFill,
  RiLogoutBoxLine,
  RiUserFill,
  RiUserLine,
  RiEditFill,
  RiEditLine,
  RiCheckboxCircleFill,
  RiCheckboxCircleLine,
  RiCloseFill,
  RiCloseLine,
  RiCalendarCheckFill,
  RiCalendarCheckLine,
  RiNotificationBadgeFill,
  RiNotificationBadgeLine,
  RiExchangeDollarFill,
  RiExchangeDollarLine,
  RiCornerUpLeftDoubleFill,
  RiCornerUpLeftDoubleLine,
  RiCalendarEventFill,
  RiCalendarEventLine,
  RiCalendarTodoFill,
  RiCalendarTodoLine,
  RiCalendar2Fill,
  RiCalendar2Line,
  RiSettingsFill,
  RiSettingsLine,
} from '@remixicon/react';
import {FC} from 'react';

const IconLibrary = {
  home: {
    fill: RiHome6Fill,
    line: RiHome5Line,
  },
  file: {
    fill: RiFileList3Fill,
    line: RiFileList3Line,
  },
  arrowUp: {
    fill: RiArrowUpFill,
    line: RiArrowUpLine,
  },
  arrowLeft: {
    fill: RiArrowLeftFill,
    line: RiArrowLeftLine,
  },
  chevronRight: {
    fill: RiArrowRightSFill,
    line: RiArrowRightSLine,
  },
  doubleArrowUpLeft: {
    fill: RiCornerUpLeftDoubleFill,
    line: RiCornerUpLeftDoubleLine,
  },
  notification: {
    fill: RiNotificationFill,
    line: RiNotificationLine,
  },
  notificationBadge: {
    fill: RiNotificationBadgeFill,
    line: RiNotificationBadgeLine,
  },
  smile: {
    fill: RiUserSmileFill,
    line: RiUserSmileLine,
  },
  seo: {
    fill: RiSeoFill,
    line: RiSeoLine,
  },
  phone: {
    fill: RiSmartphoneFill,
    line: RiSmartphoneLine,
  },
  alert: {
    fill: RiAlertFill,
    line: RiAlertLine,
  },
  calendarCheck: {
    fill: RiCalendarCheckFill,
    line: RiCalendarCheckLine,
  },
  calendarEvent: {
    fill: RiCalendarEventFill,
    line: RiCalendarEventLine,
  },
  calendarTodo: {
    fill: RiCalendarTodoFill,
    line: RiCalendarTodoLine,
  },
  calendar: {
    fill: RiCalendar2Fill,
    line: RiCalendar2Line,
  },
  check: {
    fill: RiCheckFill,
    line: RiCheckLine,
  },
  checkboxCircle: {
    fill: RiCheckboxCircleFill,
    line: RiCheckboxCircleLine,
  },
  info: {
    fill: RiInformationFill,
    line: RiInformationLine,
  },
  water: {
    fill: RiDropFill,
    line: RiDropLine,
  },
  money: {
    fill: RiMoneyDollarCircleFill,
    line: RiMoneyDollarCircleLine,
  },
  moneyExchange: {
    fill: RiExchangeDollarFill,
    line: RiExchangeDollarLine,
  },
  repeat: {
    fill: RiRepeatFill,
    line: RiRepeatLine,
  },
  mindmap: {
    fill: RiMindMap,
    line: RiMindMap,
  },
  exchange: {
    fill: RiExchangeFundsFill,
    line: RiExchangeFundsLine,
  },
  filter: {
    fill: RiFilter3Fill,
    line: RiFilter3Line,
  },
  rates: {
    fill: RiCurrencyFill,
    line: RiCurrencyLine,
  },
  gas: {
    fill: RiFireFill,
    line: RiFireLine,
  },
  electricity: {
    fill: RiLightbulbFlashFill,
    line: RiLightbulbFlashLine,
  },
  infringements: {
    fill: RiCarFill,
    line: RiCarLine,
  },
  add: {
    fill: RiAddFill,
    line: RiAddLine,
  },
  pencil: {
    fill: RiPencilFill,
    line: RiPencilLine,
  },
  card: {
    fill: RiBankCard2Fill,
    line: RiBankCard2Line,
  },
  handCoin: {
    fill: RiHandCoinFill,
    line: RiHandCoinLine,
  },
  question: {
    fill: RiQuestionFill,
    line: RiQuestionLine,
  },
  logout: {
    fill: RiLogoutBoxFill,
    line: RiLogoutBoxLine,
  },
  user: {
    fill: RiUserFill,
    line: RiUserLine,
  },
  edit: {
    fill: RiEditFill,
    line: RiEditLine,
  },
  close: {
    fill: RiCloseFill,
    line: RiCloseLine,
  },
  setting: {
    fill: RiSettingsFill,
    line: RiSettingsLine,
  },
};

export type IconName = keyof typeof IconLibrary;

export type IconProps = {
  name: keyof typeof IconLibrary;
  size?: 20 | 28 | 80;
  variant?: 'fill' | 'line';
  color?: string;
  className?: string;
};

export const Icon: FC<IconProps> = ({
  name,
  variant = 'line',
  size = 20,
  color = 'currentColor',
  className,
}) => {
  const IconVariant = IconLibrary[name]?.[variant];

  if (!IconVariant) return <span>NoIcon</span>;

  return <IconVariant size={size} color={color} className={className} />;
};
