import React, {useEffect, useState} from 'react';
import {validateNumberInputInRange} from 'lib/validation';
import {Debbie} from 'components/organisms/Debbie';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from 'react-number-format';
import {NoticeOnInterest} from './NoticeOnIinterest';
import {getBillerSlugFromUrl} from 'lib/url';
import {formatToCents, formatToDollars} from 'payble-shared';

type Props = {
  debbieTitle: string;
  heading: string;
  description: string;
  amount: number;
  minAmount: number;
  maxAmount: number;
  lockedAmount: boolean;
  defaultAmount: number;
  onChangeValue: (value?: number) => void;
  onClickNext: () => void;
  onClickBack: () => void;
};
export const PaymentAmount: React.FC<Props> = ({
  debbieTitle,
  heading,
  description,
  amount,
  minAmount,
  maxAmount,
  lockedAmount,
  defaultAmount,
  onChangeValue,
  onClickNext,
  onClickBack,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorValue, setErrorValue] = useState(0);

  const billerSlug = getBillerSlugFromUrl();

  useEffect(() => {
    onChangeValue(defaultAmount);
  }, []);

  let currencyInputClass;

  if (errorMessage === '') {
    currencyInputClass =
      'focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md';
  } else {
    currencyInputClass =
      'ring-red-500 border-red-500 block sm:text-sm border-red-300 rounded-md';
  }

  const handleOnNext = () => {
    if (!amount) {
      setErrorMessage('Please enter a amount greater than zero');
      return;
    }

    const {
      valid,
      message = '',
      value = 0,
    } = validateNumberInputInRange(amount, minAmount, maxAmount);

    if (!valid) {
      setErrorMessage(message);
      setErrorValue(value / 100);
      return;
    }

    onClickNext();
  };

  return (
    <>
      <div className="relative h-full w-full mx-auto py-6 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="relative flex flex-col h-full">
          <Debbie title={debbieTitle} />
          <div className="shadow overflow-hidden rounded-md mt-4">
            <div className="px-4 py-4 bg-white sm:p-6">
              <div className="flex-1 flex flex-col h-full justify-center ">
                <h3 className="font-medium text-left text-base sm:text-lg mb-2">
                  {heading}
                </h3>
                <p className="text-sm text-gray-600 mb-2">{description}</p>
                {lockedAmount ? (
                  <NumberFormat
                    className="text-lg"
                    value={formatToDollars(defaultAmount)}
                    displayType={'text'}
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                ) : (
                  <CurrencyInput
                    disabled={lockedAmount}
                    className={currencyInputClass}
                    placeholder="Please enter an amount"
                    prefix="$"
                    defaultValue={formatToDollars(defaultAmount)}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    onFocus={() => {
                      setErrorMessage('');
                      setErrorValue(0);
                    }}
                    onValueChange={(value, __name) =>
                      // change value into cents
                      value && onChangeValue(formatToCents(parseFloat(value)))
                    }
                  />
                )}

                {errorMessage && (
                  <p className="text-sm text-red-500 mt-2">
                    {errorMessage}
                    {errorValue > 0 && (
                      <>
                        {' '}
                        <NumberFormat
                          value={errorValue}
                          displayType={'text'}
                          decimalSeparator="."
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </>
                    )}
                  </p>
                )}

                <NoticeOnInterest billerSlug={billerSlug} />
              </div>
            </div>
          </div>

          <button
            type="button"
            className="disabled:opacity-50 disabled:cursor-not-allowed transition mt-4 w-full text-center justify-center inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={handleOnNext}
          >
            Next
          </button>
          <button
            onClick={onClickBack}
            className="mt-6 text-blue-600 hover:text-blue-700 transition"
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};
