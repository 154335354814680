import {twMerge} from 'tailwind-merge';

/**
 * It takes an array of strings, filters out the falsy values, and joins the remaining strings with a
 * space
 * @param {string[]} classes - string[] - This is a rest parameter. It means that the function can take
 * any number of parameters.
 * @returns A string of all the classes that are truthy.
 */
export const classNames = (...classes: string[]) => {
  return twMerge(classes.filter(Boolean).join(' '));
};

export const buttonClasses =
  'mt-4 w-full text-center cursor-pointer justify-center inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition disabled:cursor-not-allowed disabled:opacity-50';
