import {
  cleanParams,
  isSearchParamsEqual,
  mergeSearchParams,
  searchParamsToObject,
} from 'lib/url';
import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

export function usePatchSearchParams<T extends Record<string, string>>(
  initialParams?: T
): {
  params: T;
  patch: (
    newParams: Record<string, string>,
    options?: {replace?: boolean}
  ) => void;
} {
  const [searchParams, setSearchParams] = useSearchParams(initialParams);

  const params = useMemo(
    () => cleanParams(searchParamsToObject(searchParams)),
    [searchParams]
  ) as T;

  const patch = useCallback(
    (newParams: Record<string, string>, options?: {replace?: boolean}) => {
      const nextSearchParams = mergeSearchParams(params, newParams);
      if (!isSearchParamsEqual(new URLSearchParams(params), nextSearchParams)) {
        setSearchParams(nextSearchParams, options);
      }
    },
    [params, setSearchParams]
  );

  return {
    params,
    patch,
  };
}
