import React, {useContext, useEffect, useState} from 'react';
import {useActor} from '@xstate/react';
import {XCircleIcon} from '@heroicons/react/20/solid';

import * as Auth from 'lib/auth/Auth';
import {buttonClasses, classNames} from 'lib/styles';

import OtpInput from 'react-otp-input';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Spinner} from 'components/atoms/Spinner';
import mobile from 'is-mobile';

export const ConfirmCode: React.FC<{phoneNumber: string}> = ({phoneNumber}) => {
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);

  const [code, setCode] = useState('');

  const onCodeChange = (code: string) => {
    setCode(code);

    if (code.length === 6) {
      authService.send('CONFIRM_CODE', {phoneNumber, code});
    }
  };

  const goBack = () => {
    authService.send('BACK');
  };

  useEffect(() => {
    if (authState.matches('incorrectCode')) {
      setCode('');
    }
  }, [authState]);

  const isConfirming = authState.matches('verifyingCode');

  const reload = () => {
    window.location.reload();
  };

  if (authState.matches('error')) {
    return (
      <div className="flex flex-col items-center justify-center mx-auto w-72">
        <ErrorMessage
          message={
            authState.context.errorMessage ?? 'There was an error logging in'
          }
        />
        <button className={buttonClasses} onClick={() => reload()}>
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center mt-8">
      <p className="mt-8 text-lg font-bold text-center w-72 text-navy">
        SMS verification
      </p>
      <div className="mt-8 text-center text-gray-600 w-72 ">
        Enter the code you received at{' '}
        <p className="font-bold text-navy">
          {authState.context.loggedInPhoneNumber ?? phoneNumber}
        </p>
      </div>
      <OtpInput
        value={code}
        onChange={onCodeChange}
        numInputs={6}
        containerStyle="flex flex-row justify-center text-center px-2 mt-5"
        isInputNum
        inputStyle={{
          width: '2.5rem',
          height: '2.5rem',
          borderRadius: '5px',
          margin: '5px',
          fontSize: '18px',
          border: '1px solid #dfe1e6',
        }}
        focusStyle="border-blue-600"
        errorStyle="border-red-400"
        hasErrored={authState.matches('incorrectCode')}
        isDisabled={isConfirming}
        shouldAutoFocus={!mobile()}
      />
      {authState.matches('verifyingCode') && (
        <div className="-mt-14">
          <Spinner />
        </div>
      )}
      {authState.matches('incorrectCode') && (
        <div className="p-4 mt-4 rounded-md bg-red-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="w-5 h-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {authState.context.errorMessage ??
                  'Incorrect code. Please try again'}
              </h3>
            </div>
          </div>
        </div>
      )}

      <button
        type="button"
        onClick={goBack}
        disabled={isConfirming}
        className={classNames(buttonClasses, 'w-auto')}
      >
        Go back
      </button>
    </div>
  );
};
