import {BrowserInfo} from './BrowserInfo';
import {StorageUtil} from './StorageUtil';
import {Session} from './Session';

export class AnalyticsContext {
  browser: BrowserInfo;
  storage: StorageUtil;
  session: Session;

  constructor() {
    this.browser = new BrowserInfo();
    this.storage = new StorageUtil(this.browser);
    this.session = new Session(this.storage);
  }
}
