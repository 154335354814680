import {PreviewInstalment} from 'lib/graphql/API';
import {formatToDollars} from 'payble-shared';
import React from 'react';
import NumberFormat from 'react-number-format';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {getArrangementPath} from '../helpers/getArrangementPath';

type SetupBreakdown = {
  setupInstalments: PreviewInstalment[];
  background?: 'white' | 'gray';
};

export const SetupBreakdown: React.FC<SetupBreakdown> = ({
  setupInstalments,
  background = 'gray',
}) => {
  const navigate = useSetupNavigate();
  const billerConfig = useBillerConfig();
  const setupInstalmentsTotal = setupInstalments.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);

  const arrangementPath = getArrangementPath(billerConfig);

  return (
    <div
      className={`bg-${
        background === 'white' ? 'white' : 'gray-100'
      } rounded p-4 mb-4 ${
        background === 'white' ? 'shadow-sm' : 'shadow-none'
      }`}
    >
      <div>
        <h2 className="text-lg font-semibold text-gray-900 mb-4">
          Missed payments
        </h2>
      </div>
      <div>
        <p>
          Before your payment plan can start you need to clear your outstanding
          balance of{' '}
          <strong>
            <NumberFormat
              value={formatToDollars(setupInstalmentsTotal)}
              displayType={'text'}
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={'$'}
            />
          </strong>
          .
        </p>

        {arrangementPath ? (
          <p className="text-blue-400 underline text-center py-2 cursor-pointer">
            <a onClick={() => navigate(arrangementPath)}>
              Unable to make these payments?
            </a>
          </p>
        ) : null}
      </div>
    </div>
  );
};
