import React, {useEffect} from 'react';

import {navigate} from 'lib/navigation/routes';
import {getBillerSlugFromUrl} from 'lib/url';
import {TryAgain} from 'components/molecules/TryAgain';
import {useSetupRoute} from '../components/SetupRoute';
import {useAuthState} from '../hooks/useAuthState';
import {ConfirmationPresenter} from '../components/ConfirmationPresenter';
import {useBrowserBack} from '../../../lib/navigation/useBrowserBack';

export const ConfirmationContainer = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {account, completedState, setCompletedState} = useSetupRoute();
  const {
    context: {biller},
  } = useAuthState();

  // If the user has not completed the setup process, send them back to the beginning
  useEffect(() => {
    if (!completedState) {
      navigate('/biller/:slug/setup', {slug: billerSlug});
    }
  }, [completedState]);

  // Prevent user from going back to the previous page, send them back to the beginning if they do so
  useBrowserBack(() => {
    setCompletedState(undefined);
    navigate('/biller/:slug', {slug: billerSlug});
  });

  if (!biller) {
    return (
      <TryAgain
        errorMessage="No biller details"
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  if (!completedState) {
    return (
      <TryAgain
        errorMessage="Something went wrong!"
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  if (
    completedState.instalmentPlan === undefined &&
    completedState.payment === undefined &&
    completedState.planRequest === undefined
  ) {
    return (
      <TryAgain
        errorMessage="Something went wrong!"
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  return (
    <ConfirmationPresenter
      mode={completedState.mode}
      account={account}
      contact={completedState.contact}
      payment={completedState.payment}
      paymentMethod={completedState.paymentMethod}
      instalmentPlan={completedState.instalmentPlan}
      planRequest={completedState.planRequest}
      billerSlug={billerSlug}
    />
  );
};
