import {useQuery} from '@tanstack/react-query';
import {useEffect, useRef, useState} from 'react';

export const VersionPoll: React.FC = () => {
  const lastManifest = useRef<string>();
  const [shouldRefreshApp, setShouldRefreshApp] = useState(false);
  const isLocal = window.location.port === '3000';

  const {data} = useQuery({
    queryKey: ['manifest.json'],
    queryFn: async () => {
      const response = await fetch('/manifest.json');
      return await response.text();
    },
    refetchInterval: 30000,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchIntervalInBackground: false,
    staleTime: 5,
    throwOnError: false,
    enabled: !isLocal,
  });

  useEffect(() => {
    if (data && lastManifest.current && data !== lastManifest.current) {
      setShouldRefreshApp(true);
    }
    lastManifest.current = data;
  }, [data]);

  return (
    shouldRefreshApp && (
      <div className="fixed top-0 z-50 p-4 -translate-x-1/2 bg-white border border-gray-300 rounded-md left-1/2">
        An updated version of the app is ready for you. Please click 'Refresh'
        to get the latest features.
        <button
          className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
      </div>
    )
  );
};
