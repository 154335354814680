import React from 'react';
import {formatToDollars, toTitleCase} from 'payble-shared';
import {CreatedPlanRequest} from '../graphql/actions';

export const ConfirmationPlanRequestSection = ({
  planRequest,
}: {
  planRequest?: CreatedPlanRequest;
}) => {
  if (!planRequest) {
    return null;
  }

  return (
    <>
      {planRequest.instalmentAmount && (
        <>
          <p className="text-sm text-gray-600">Instalment Amount</p>
          <p className="text-sm text-gray-600">
            ${formatToDollars(planRequest.instalmentAmount)}
          </p>
        </>
      )}
      {planRequest.frequency && (
        <>
          <p className="text-sm text-gray-600">Frequency</p>
          <p className="text-sm text-gray-600">
            {toTitleCase(planRequest.frequency)}
          </p>
        </>
      )}

      {planRequest.startAt && (
        <>
          <>
            <p className="text-sm text-gray-600">Start Date</p>
            <p className="text-sm text-gray-600">
              {planRequest.startAt.toFormat('dd/MM/yyyy')}
            </p>
          </>
        </>
      )}
    </>
  );
};
