import React, {ReactNode} from 'react';
import {ErrorPage} from 'features/navigation/Error';
import {captureException} from '@sentry/react';

export class ErrorBoundary extends React.Component<
  {children: ReactNode},
  {error?: Error}
> {
  state: {error?: Error};
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return {error};
  }

  componentDidCatch(error: Error) {
    console.error(error.message);
    captureException(error);
  }

  render() {
    if (this.state.error) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
