import {getBillerSlugFromUrl} from 'lib/url';
import {goBack} from 'lib/navigation/routes';
import {PaymentSchedule} from 'features/setup/shared/PaymentSchedule';
import {formatToDollars} from 'payble-shared';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {getFeatureConfig} from 'payble-shared/src/biller-config/handlers';

export const FlexiblePaySchedule = () => {
  const billerSlug = getBillerSlugFromUrl();
  const navigate = useSetupNavigate();
  const {instalmentStartAt, amountInCents, instalmentFrequency} =
    useSetupSearchParams();

  return (
    <PaymentSchedule
      startAt={instalmentStartAt ?? undefined}
      debbieTitle={
        amountInCents
          ? `When do you want to start your ${instalmentFrequency} payments of $${formatToDollars(
              amountInCents
            )}?`
          : `When do you want to start your ${instalmentFrequency} payments?`
      }
      description={
        amountInCents
          ? `Choose to start your ${instalmentFrequency} payments from a date that suits you`
          : ''
      }
      onClickNext={startDate => {
        navigate('/biller/:slug/setup/payment-method', {
          paymentStartDate: startDate.toISO(),
        });
      }}
      onClickBack={goBack}
      scheduleMaxDays={
        getFeatureConfig(billerSlug).FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS
      }
    />
  );
};
