import {FC} from 'react';

interface FormErrorMessageProps {
  message?: string;
}

export const FormErrorMessage: FC<FormErrorMessageProps> = ({message}) => {
  if (message) return <div className="text-xs text-destructive">{message}</div>;
  return null;
};
