import React, {useLayoutEffect, useState} from 'react';
import {Router, HistoryRouterProps} from 'react-router-dom';

/**
 * A custom router that enables us to inject our own history instance
 * This makes it easy to programatically navigate to a specific route in routes.ts
 */
export const CustomRouter: React.FC<HistoryRouterProps> = ({
  history,
  ...props
}) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
