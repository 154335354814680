import {
  PaymentMethodType,
  SurchargeRate,
  TokenexCardInfo,
  cardBrandToPretty,
  cardTypeToPretty,
} from '..';
import {
  SurchargeConfig,
  SurchargeConfigInput,
  SurchargeConfigSchema,
  getSurchargeItemKey,
} from './SurchargeConfig';

const once = <T>(fn: () => Promise<T>) => {
  let promise: Promise<T> | undefined;
  return async () => {
    if (!promise) {
      promise = fn();
    }
    return promise;
  };
};

interface Surcharge {
  readonly surchargedAmount: number;
  readonly rate: number;
  readonly transactRate: string;
}

export interface ChargeableAmount {
  readonly total: number;
  readonly originalAmount: number;

  readonly surcharge: Surcharge | null;
}

export class SurchargeCalculator {
  private config: SurchargeConfig;
  constructor(config: SurchargeConfigInput) {
    this.config = SurchargeConfigSchema.parse(config);
    const deduped = new Set(this.config.map(getSurchargeItemKey)).size;
    if (deduped !== this.config.length) {
      throw new Error('Duplicate surcharge config found');
    }
  }

  get hasSurcharges() {
    return !!this.config.length;
  }

  /**
   * When a biller has a single rate for a payment type return it or undefined.
   */
  getSingleTypeSurcharge(__: PaymentMethodType) {
    if (this.config.length !== 1) {
      return undefined;
    }
    const surcharge = this.config[0];
    if (surcharge.type !== 'card') {
      return undefined;
    }
    return surcharge.surchargeRate;
  }

  /**
   * Resolve the surcharge rate for a payment method given card
   * info.
   *
   * This can be used on the frontend using the Tokenex IFrame
   * BIN lookup or by using the API side BIN lookup resolvers.
   */
  async resolveSurchargeRate(args: {
    paymentMethodType: PaymentMethodType;
    loadCardInfo: () => Promise<TokenexCardInfo>;
  }): Promise<SurchargeRate | undefined> {
    const {paymentMethodType} = args;
    if (args.paymentMethodType !== 'card') {
      return undefined;
    }
    const loadCardInfo = once(args.loadCardInfo);
    for (const surcharge of this.config) {
      switch (surcharge.type) {
        case 'card': {
          if (paymentMethodType === 'card') {
            return surcharge.surchargeRate;
          }
          break;
        }
        case 'card_brand_and_type': {
          const cardInfo = await loadCardInfo();
          if (
            cardInfo.brand === surcharge.cardBrand &&
            cardInfo.type === surcharge.cardType
          ) {
            return surcharge.surchargeRate;
          }
          break;
        }
        case 'card_brand': {
          const cardInfo = await loadCardInfo();
          if (cardInfo.brand === surcharge.cardBrand) {
            return surcharge.surchargeRate;
          }
          break;
        }
        case 'card_type': {
          const cardInfo = await loadCardInfo();
          if (cardInfo.type === surcharge.cardType) {
            return surcharge.surchargeRate;
          }
          break;
        }
      }
    }
    return undefined;
  }

  /**
   * Get the charges in a format presentable to consumers.
   * Hides fallback card charges when a biller expects all card
   * charges to be surcharged.
   *
   * @example
   * {
   *   'Visa Credit': '1.2%',
   *   'American Express': '1.2%',
   * }
   */
  prettyPrint(): {
    [chargeName: string]: string;
  } {
    return Object.fromEntries(
      this.config
        .filter(c => (c.type === 'card' ? !c.fallback : true))
        .map(c => {
          switch (c.type) {
            case 'card_brand_and_type':
              return [
                `${cardBrandToPretty(c.cardBrand)} ${cardTypeToPretty(c.cardType)}`,
                c.surchargeRate.toPretty(),
              ];
            case 'card_brand':
              return [
                cardBrandToPretty(c.cardBrand),
                c.surchargeRate.toPretty(),
              ];
            case 'card_type':
              return [cardTypeToPretty(c.cardType), c.surchargeRate.toPretty()];
            case 'card':
              return ['Card', c.surchargeRate.toPretty()];
            default: {
              const __exhaustiveCheck: never = c;
              throw new Error(`Unknown surcharge type ${__exhaustiveCheck}`);
            }
          }
        })
    );
  }

  debugPrint() {
    return Object.fromEntries(
      this.config.map(item => {
        return [getSurchargeItemKey(item), item.surchargeRate.toPretty()];
      })
    );
  }
}
