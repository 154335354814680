const DEFAULT_OPTIONS_FORMAT_MONEY: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'AUD',
} as const;

type formatMoneyOptions = {
  hasDecimal?: boolean;
  locales?: string;
  format?: Intl.NumberFormatOptions;
};

export const formatMoney = (
  value: number,
  {hasDecimal = true, locales = 'en-AU', format = {}}: formatMoneyOptions = {}
) =>
  new Intl.NumberFormat(locales, {
    ...DEFAULT_OPTIONS_FORMAT_MONEY,
    ...format,
  }).format(hasDecimal ? value / 100 : value);

export const mask = (str: string, num = 4, mask = '*') =>
  `${str}`.slice(-num).padStart(`${str}`.length, mask);

export const getValueFromUnknownObjectByKey = (
  unknownObject: unknown,
  key: string
): unknown =>
  !!unknownObject && typeof unknownObject === 'object'
    ? Object.entries(unknownObject)[
        Object.keys(unknownObject).indexOf(key)
      ]?.[1]
    : undefined;

/** Format a number into "1st", "2nd", "3rd" */
export const formatOrdinal = (n: number): string => {
  const absolute = Math.abs(n);
  const mod100 = absolute % 100;
  if (mod100 > 10 && mod100 < 20) {
    return `${n}th`;
  }
  switch (absolute % 10) {
    case 1:
      return `${n}st`;
    case 2:
      return `${n}nd`;
    case 3:
      return `${n}rd`;
    default:
      return `${n}th`;
  }
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export function convertCamelCaseToSnakeCase(string: string) {
  return string.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toLowerCase();
}

export function capitalize(string: string, separator = '_') {
  const parts = separator ? string.split(separator) : [string];
  return parts
    .map(part => (part[0] ?? '').toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');
}

export const camelCaseToSentance = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1');
};

export const formatLast4 = (value: string) => {
  if (value.length > 4) {
    return value;
  }
  return `**** ${value}`;
};

/**
 * Given a row with string interpolated fields, correctly escape and format it
 * as a CSV row
 *
 * @example
 * csvEscape`${A},${B},${C}`
 *
 * https://datatracker.ietf.org/doc/html/rfc4180
 */
export const csvEscapeFields = (
  strings: TemplateStringsArray,
  ...values: string[]
) => {
  return strings
    .map((string, index) => {
      const value = values[index] ?? '';
      return `${string}${csvEscapeField(value)}`;
    })
    .join('');
};

export function csvEscapeField(field: string) {
  let value = field;
  // Quotes must be replaced with "" as their escape sequence
  if (value.includes('"')) {
    value = value.replace(/"/g, '""');
  }
  // Newlines, commas and quotes require the value to be wrapped in quotes
  if (
    value.includes('"') ||
    value.includes(',') ||
    value.includes('\n') ||
    value.includes('\r')
  ) {
    value = `"${value}"`;
  }
  return value;
}

/**
 * Convert a set of JSON rows into a CSV string.
 *
 * @example
 * jsonToCSV([{a: 'value in a', b: 'value in b'}], {a: 'New Column', b: 'Names'})
 *
 * # Outputs:
 * # New Column,Names
 * # value in a,value in b
 */
export function jsonToCSV<T extends Record<string, string | undefined>>(
  rows: T[],
  columns: readonly (keyof T & string)[]
) {
  const headerRow = columns
    .map(newColumnName => csvEscapeField(newColumnName))
    .join(',');

  const stringRows: string[] = [headerRow];

  for (const jsonArray of rows) {
    stringRows.push(
      columns.map(key => csvEscapeField(jsonArray[key] ?? '')).join(',')
    );
  }
  return stringRows.join('\n');
}

export const formatToCents = (value: number) => {
  return parseFloat((value * 100).toFixed());
};

export const formatToDollars = (value: number) => {
  return parseFloat((value / 100).toFixed(2));
};
