/**
 * It validates a direct debit object by checking that the account number is less than 9 digits, the
 * BSB is 6 digits
 * @param value - {
 * @returns A boolean value
 */
export function validateDirectDebit(value: {
  accountNumber: string;
  bsbNumber: string;
}) {
  const hasValidAccountNumber =
    value.accountNumber.length > 0 &&
    value.accountNumber.replaceAll('-', '').length <= 9;

  const hasValidBsb =
    value.bsbNumber.length > 0 &&
    value.bsbNumber.replaceAll('-', '').length === 6;

  return hasValidAccountNumber && hasValidBsb;
}

/**
 * Validates that a number input is within a specified range.
 * Returns an object with a boolean indicating whether the input is valid,
 * and a warning message and/or the valid input value if it is not.
 */
export function validateNumberInputInRange(
  inputValue: number,
  minimumValue: number,
  maximumValue: number,
  lessThanMessage = 'Amount must be less than or equal to',
  greaterThanMessage = 'Amount must be greater than or equal to'
): {valid: boolean; message?: string; value?: number} {
  if (inputValue < minimumValue) {
    return {
      valid: false,
      message: greaterThanMessage,
      value: minimumValue,
    };
  }
  if (inputValue > maximumValue) {
    return {
      valid: false,
      message: lessThanMessage,
      value: maximumValue,
    };
  }

  return {valid: true};
}
