import {payAccountAmount} from 'features/biller/pages/pay/graphql/actions';
import {
  CreatedInstalmentPlan,
  CreatedPlanRequest,
  createInstalmentPlan,
  createPlanRequest,
} from 'features/setup/graphql/actions';
import {
  Account,
  Contact,
  InstalmentFrequency,
  InstalmentPlanMode,
  Payment,
  PaymentMethod,
} from 'lib/graphql/API';
import storage from 'local-storage-fallback';
import {err} from 'payble-shared';
import {useCallback, useState} from 'react';
import {analytics} from '../../../analytics/hooks/useAnalytics';
import {useSetupSearchParams} from './useSetupSearchParams';

const getStateFromMode = (mode: string) => {
  switch (mode) {
    case 'PAY_AMOUNT':
      return 'CREATE_PAY_AMOUNT';
    case 'PAY_BALANCED':
      return 'CREATE_PAY_BALANCED';
    case 'PAY_AUTO':
      return 'CREATE_PAY_X_ON_Y';
    case 'PAY_IN_FULL':
      return 'CREATE_PAY_IN_FULL';
    case 'PAY_X_EVERY_Z':
      return 'CREATE_PAY_X_EVERY_Z';
    case 'PAY_ON_Y':
      return 'CREATE_SCHEDULED_PAYMENT';
    case 'PAY_EVERY_X':
      return 'CREATE_PAY_EVERY_X';
    case 'TOKENISE':
      return 'TOKENISE';
    case 'PAY_SMOOTH':
      return 'CREATE_PAY_SMOOTH';
    case 'PAY_CATCH_UP':
      return 'CREATE_PAY_CATCH_UP';
    case 'REQUEST_PLAN':
      return 'CREATE_PLAN_REQUEST';
    default:
      throw new Error(`Invalid pay mode: ${mode}`);
  }
};

export function useSubmitSetupMutation({mode}: {mode: string}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [payment, setPayment] = useState<Payment>();
  const [instalmentPlan, setInstalmentPlan] = useState<CreatedInstalmentPlan>();
  const [planRequest, setPlanRequest] = useState<CreatedPlanRequest>();
  const {
    instalmentStartAt,
    paymentTargetDate,
    paymentStartDate,
    instalmentFrequency,
    instalmentTargetDate,
  } = useSetupSearchParams();

  const pay = useCallback(
    async (
      amountInCents: number,
      options: {
        account: Account;
        paymentMethod: PaymentMethod;
        contact: Contact;
      }
    ) => {
      const action = getStateFromMode(mode);
      setLoading(true);
      setError('');

      try {
        setInstalmentPlan(undefined);
        setPayment(undefined);
        setPlanRequest(undefined);

        const {account, paymentMethod, contact} = options;

        switch (action) {
          case 'CREATE_PAY_AMOUNT': {
            const response = await payAccountAmount({
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              amountInCents,
            });
            if (err(response)) throw response;
            setPayment(response.payment);
            break;
          }
          case 'CREATE_SCHEDULED_PAYMENT': {
            if (!paymentTargetDate) {
              throw new Error(
                'Could not create a scheduled payment without a target date'
              );
            }

            const result = await createInstalmentPlan({
              payMode: InstalmentPlanMode.PayOnY,
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: InstalmentFrequency.Weekly,
              targetDate: paymentTargetDate,
              amount: amountInCents,
            });
            if (err(result)) throw result;
            setInstalmentPlan(result);
            break;
          }
          case 'CREATE_PAY_X_ON_Y': {
            if (!paymentMethod?.id) {
              throw new Error(
                `Could not create an instalment plan without a contact payment method id for accountId: ${account.id}`
              );
            }

            const result = await createInstalmentPlan({
              payMode: InstalmentPlanMode.PayXOnY,
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              amount: account.amountOwing,
            });
            if (err(result)) throw result;
            setInstalmentPlan(result);
            break;
          }
          case 'CREATE_PAY_BALANCED': {
            if (!paymentMethod.id) {
              throw new Error(
                'Could not create an instalment plan without a contact payment method id'
              );
            }

            if (!instalmentStartAt) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            if (!instalmentFrequency) {
              throw new Error(
                'Could not create an instalment plan without an instalment frequency'
              );
            }

            const result = await createInstalmentPlan({
              payMode: InstalmentPlanMode.BalancedPayEveryX,
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
            });
            if (err(result)) {
              throw result;
            }
            setInstalmentPlan(result);
            break;
          }
          case 'CREATE_PAY_SMOOTH': {
            if (!paymentMethod.id) {
              throw new Error(
                'Could not create an instalment plan without a contact payment method id'
              );
            }

            if (!instalmentStartAt) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            if (!instalmentFrequency) {
              throw new Error(
                'Could not create an instalment plan without an instalment frequency'
              );
            }

            if (amountInCents < 0) {
              throw new Error(
                `Could not create an instalment plan with ${amountInCents}. Value has to number greater than 0`
              );
            }

            const result = await createInstalmentPlan({
              payMode: InstalmentPlanMode.SmoothPay,
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
              amount: amountInCents,
              offPeriodInstalmentAmount: amountInCents,
            });
            if (err(result)) {
              throw result;
            }
            setInstalmentPlan(result);
            break;
          }
          case 'CREATE_PAY_EVERY_X': {
            if (!paymentMethod?.id) {
              throw new Error(
                'Could not create an instalment plan without a contact payment method id'
              );
            }

            if (!instalmentStartAt) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            const result = await createInstalmentPlan({
              payMode: InstalmentPlanMode.PayEveryX,
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
            });

            if (err(result)) throw result;
            setInstalmentPlan(result);
            break;
          }
          case 'CREATE_PAY_X_EVERY_Z': {
            if (!paymentStartDate) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            const result = await createInstalmentPlan({
              payMode: InstalmentPlanMode.PayXEveryZ,
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: paymentStartDate,
              amount: amountInCents,
            });
            if (err(result)) {
              throw result;
            }
            setInstalmentPlan(result);
            break;
          }
          case 'CREATE_PAY_CATCH_UP': {
            if (!instalmentStartAt) {
              throw new Error(
                'Could not create an instalment plan without a start date'
              );
            }

            if (!instalmentTargetDate) {
              throw new Error(
                'Could not create an instalment plan without a target date'
              );
            }

            const result = await createInstalmentPlan({
              payMode: InstalmentPlanMode.CatchUp,
              accountId: account.id,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
              targetDate: instalmentTargetDate,
            });

            if (err(result)) {
              throw result;
            }
            setInstalmentPlan(result);
            break;
          }
          case 'CREATE_PLAN_REQUEST': {
            if (!instalmentStartAt) {
              throw new Error(
                'Could not create a plan request without a start date'
              );
            }

            const result = await createPlanRequest({
              accountId: account.id,
              instalmentAmount: amountInCents,
              paymentMethodId: paymentMethod.id,
              frequency: instalmentFrequency,
              startAt: instalmentStartAt,
              contactId: contact.id,
            });

            if (err(result)) {
              throw result;
            }

            setPlanRequest(result);
            break;
          }
          default: {
            throw new Error('Invalid pay mode');
          }
        }

        storage.setItem('signed-up', 'true');
        setLoading(false);
        analytics.addEvent(
          action === 'CREATE_PAY_AMOUNT' ? 'payment_complete' : 'setup_complete'
        );
      } catch (error) {
        setError((error as Error).message);
        setLoading(false);
      }
    },
    []
  );

  return {pay, payment, instalmentPlan, planRequest, loading, error};
}
