import React, {FC, ReactNode, useMemo} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';
import {RawBillerConfig, BillerConfig, BillerSlug} from 'payble-shared';

export const Context = React.createContext<BillerConfig | undefined>(undefined);

export const Provider: React.FC<{children: ReactNode; ErrorComponent: FC}> = ({
  children,
  ErrorComponent,
}) => {
  const billerSlug = getBillerSlugFromUrl();

  /**
   * This should be replaced by an async query, for now biller config is hardcoded in packages/shared
   */
  const config = useMemo(
    () => RawBillerConfig[billerSlug as BillerSlug],
    [billerSlug]
  );

  if (!config) {
    return <ErrorComponent />;
  }

  return (
    <Context.Provider
      value={new BillerConfig(config, billerSlug as BillerSlug)}
    >
      {children}
    </Context.Provider>
  );
};
