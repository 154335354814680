import {useGetAccountTypeInfo} from '../hooks/useGetAccountTypeInfo';

export type PaymentFrequency = 'weekly' | 'fortnightly' | 'monthly';

export type PaymentFrequencyOption = {
  id: string;
  value: PaymentFrequency;
  label: string;
  description: string;
};

export const getPaymentFrequencyOptions = (): PaymentFrequencyOption[] => {
  const accountInfo = useGetAccountTypeInfo();
  const accountTitle = accountInfo.title.toLowerCase();

  return [
    {
      id: 'plan-frequency-weekly',
      value: 'weekly',
      label: 'Weekly',
      description: `Pay an amount weekly to catch up and get on top of your ${accountTitle}`,
    },
    {
      id: 'plan-frequency-fortnightly',
      value: 'fortnightly',
      label: 'Fortnightly',
      description: `Pay an amount fortnightly to catch up and get on top of your ${accountTitle}`,
    },
    {
      id: 'plan-frequency-monthly',
      value: 'monthly',
      label: 'Monthly',
      description: `Pay an amount monthly to catch up and get on top of your ${accountTitle}`,
    },
  ];
};
