import React from 'react';
import {Debbie} from 'components/organisms/Debbie';
import {CheckIcon} from '@heroicons/react/20/solid';
import {useSetupNavigate} from '../hooks/useSetupNavigate';

interface Props {
  onConfirm: () => void;
  onBack: () => void;
}

const FlexibleLikePayFeatures = (props: Props) => {
  const {onBack, onConfirm} = props;

  return (
    <>
      <Debbie title="Does this sound like what you are looking for?" />
      <div className="mt-4 overflow-hidden rounded-md shadow">
        <div className="px-4 py-4 bg-white sm:p-6">
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-blue-50">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-blue-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
              />
            </svg>
          </span>

          <div className="flex flex-col justify-center flex-1 h-full ">
            <h3 className="mb-2 text-base font-medium text-left sm:text-lg">
              Flexible payments
            </h3>
            <p className="mb-2 text-sm text-gray-600">
              Schedule weekly, fortnightly, monthly payments on a schedule that
              suits you
            </p>
            <dl className="text-base leading-7 text-gray-600">
              {[
                'Choose your payment frequency',
                'Receive SMS reminder notifications',
                'Update your payment methods anytime',
                '2x payment retry attempts if a payment fails',
                'Skip payments',
              ].map((feature, key) => (
                <div key={key} className="relative mt-3 pl-9">
                  <dt className="text-gray-900 font-regular">
                    <CheckIcon
                      className="absolute left-0 w-5 h-5 text-blue-900 top-1"
                      aria-hidden="true"
                    />
                    {feature}
                  </dt>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={onConfirm}
      >
        Set up flexible payments
      </button>
      <button
        className="mt-6 text-blue-600 transition hover:text-blue-700"
        onClick={onBack}
      >
        Back
      </button>
    </>
  );
};

export const BalancedPayFeatures = () => {
  const navigate = useSetupNavigate();
  return (
    <FlexibleLikePayFeatures
      onBack={() => {
        navigate('/biller/:slug/setup/options');
      }}
      onConfirm={() => {
        navigate('/biller/:slug/setup/balanced/frequency');
      }}
    />
  );
};

export const FlexiblePayXEveryZFeatures = () => {
  const navigate = useSetupNavigate();

  return (
    <FlexibleLikePayFeatures
      onBack={() => {
        navigate('/biller/:slug/setup/options');
      }}
      onConfirm={() => {
        navigate('/biller/:slug/setup/flexible/frequency');
      }}
    />
  );
};

export const FlexiblePayEveryXFeatures = () => {
  const navigate = useSetupNavigate();

  return (
    <FlexibleLikePayFeatures
      onBack={() => {
        navigate('/biller/:slug/setup/options');
      }}
      onConfirm={() => {
        navigate('/biller/:slug/setup/flexible/installments');
      }}
    />
  );
};
