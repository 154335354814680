import {Debbie} from 'components/organisms/Debbie';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {useSetupRoute} from '../components/SetupRoute';
import {goBack} from 'lib/navigation/routes';

export const SmoothPaySkipStraight = () => {
  const {account} = useSetupRoute();
  const navigate = useSetupNavigate();
  const billerConfig = useBillerConfig();
  const type = billerConfig.getAccountTypeTitle(account.type);

  return (
    <>
      <Debbie title="Looks like you don't have any amounts outstanding" />
      <div className="mt-4 overflow-hidden rounded-md shadow">
        <div className="px-4 py-4 text-center bg-white sm:p-6">
          <p>Let's skip straight to preparing for your next {type} notice</p>
        </div>
      </div>
      <button
        type="button"
        className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={() => {
          navigate('/biller/:slug/setup/smooth/next');
        }}
      >
        Next
      </button>
      <button
        onClick={goBack}
        className="mt-6 text-blue-600 transition hover:text-blue-700"
      >
        Back
      </button>
    </>
  );
};
