import {useCallback, useState} from 'react';

type UseDrawerProps = {[key: string]: boolean};

export const useDrawer = (defaultState?: UseDrawerProps) => {
  const [drawerOpened, setDrawerOpened] = useState<{[key: string]: boolean}>(
    defaultState ?? {}
  );

  const openDrawer = useCallback(
    (name: string) => setDrawerOpened({...drawerOpened, [name]: true}),
    [drawerOpened]
  );

  const closeDrawer = useCallback(
    (name: string) =>
      setDrawerOpened({
        ...drawerOpened,
        [name]: false,
      }),
    [drawerOpened]
  );

  return {
    drawerOpened,
    openDrawer,
    closeDrawer,
  };
};
