import {getInstalmentPlans, getPayments} from 'features/setup/graphql/actions';
import {Biller, Contact, InstalmentPlan, Payment} from 'lib/graphql/API';
import {getBillerSlugFromUrl} from 'lib/url';
import {devToolsEnabled, err} from 'payble-shared';
import _refiner from 'refiner-js';
import {Interpreter, assign, createMachine, interpret} from 'xstate';
import {analytics} from '../../analytics/hooks/useAnalytics';
import {Session, loadSessionData} from './initialisation';
import {
  clear,
  confirmCode,
  getBiller,
  getSessionContact,
  logout,
  sendSMSCode,
  validate,
} from './token';

export interface Context {
  session?: Session;
  contact?: Contact;
  biller?: Biller;
  errorMessage?: string;
  instalmentPlans: InstalmentPlan[];
  payments: Payment[];
  loggedInPhoneNumber?: string;
}

export type AuthState =
  | 'initialising'
  | 'authenticating'
  | {authenticated: 'idle' | 'refreshing'}
  | 'unauthenticated'
  | 'error'
  | 'sendingSMS'
  | 'smsFailed'
  | 'enteringCode'
  | 'verifyingCode'
  | 'incorrectCode';

export type Machine = {
  value: AuthState;
  context: Context;
};

export type SendSMSEvent = {
  type: 'SEND_SMS';
  phoneNumber: string;
};

export type ConfirmCodeEvent = {
  type: 'CONFIRM_CODE';
  code: string;
  phoneNumber: string;
};

export type ResendEvent = {
  type: 'RESEND';
};

export type SignOutEvent = {
  type: 'SIGN_OUT';
  slug: string;
};

type Event =
  | SendSMSEvent
  | ConfirmCodeEvent
  | ResendEvent
  | SignOutEvent
  | {type: 'BACK'}
  | {type: 'REFRESH_CONTACT'}
  | {type: 'CLEAR_SESSION'};

export type MachineInterpreter = Interpreter<Context, any, Event, Machine>;
export const authMachine = createMachine<Context, Event, Machine>({
  context: {
    instalmentPlans: [],
    payments: [],
  },
  id: 'payble.consumerWeb.authMachine',
  preserveActionOrder: true,
  initial: 'authenticating',
  states: {
    authenticating: {
      invoke: {
        src: async () => {
          await validate();
        },
        id: 'authenticate',
        onDone: [
          {
            target: 'initialising',
          },
        ],
        onError: [
          {
            actions: () => clear(),
            target: 'unauthenticated',
          },
        ],
      },
    },
    initialising: {
      invoke: {
        src: async () => {
          const billerSlug = getBillerSlugFromUrl();
          const sessionData = await loadSessionData();
          const contact = await getSessionContact();
          if (err(contact)) {
            console.error(contact);
            throw contact;
          }
          analytics.reidentify();

          // @ts-expect-error This is used by e2e tests
          window.contactId = contact.id;

          const biller = await getBiller();

          if (err(biller)) {
            throw biller;
          }

          if (!biller) {
            throw new Error(`Undefined biller ${billerSlug}`);
          }

          if (billerSlug !== biller.slug) {
            throw new Error('Invalid session. Please try again to continue');
          }

          const instalmentPlans = await getInstalmentPlans();

          if (err(instalmentPlans)) {
            throw instalmentPlans;
          }

          const payments = await getPayments();

          if (err(payments)) {
            throw payments;
          }

          return {
            sessionData,
            contact,
            biller,
            instalmentPlans,
            payments,
          };
        },
        id: 'initialising',
        onDone: [
          {
            actions: [
              assign({
                session: (_, event) => event.data.sessionData,
                contact: (_, event) => event.data.contact,
                biller: (_, event) => event.data.biller,
                instalmentPlans: (_, event) => event.data.instalmentPlans,
                payments: (_, event) => event.data.payments,
              }),
              (context, __event) => {
                _refiner('identifyUser', {
                  id: context.contact?.id,
                  email: context.contact?.email,
                  name: `${context.contact?.givenName} ${context.contact?.familyName}`,
                  biller_slug: context.biller?.slug,
                });
              },
            ],
            target: 'authenticated',
          },
        ],
        onError: [
          {
            actions: [
              () => clear(),
              assign({
                errorMessage: (__context, event) => {
                  return event.data.message;
                },
              }),
            ],
            target: 'error',
          },
        ],
      },
    },
    error: {},
    authenticated: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            REFRESH_CONTACT: {
              target: 'refreshing',
            },
            CLEAR_SESSION: {
              actions: assign({
                session: (__context, __event) => {
                  return {type: 'empty'};
                },
              }),
              target: 'idle',
              internal: false,
            },
          },
        },
        refreshing: {
          invoke: {
            src: async () => {
              const billerSlug = getBillerSlugFromUrl();
              const contact = await getSessionContact();
              if (err(contact)) {
                console.error('Refreshing contact failed', contact);
                throw contact;
              }

              const biller = await getBiller();

              if (err(biller)) {
                throw biller;
              }

              if (!biller) {
                throw new Error(`Undefined biller ${billerSlug}`);
              }

              if (billerSlug !== biller.slug) {
                throw new Error(
                  'Invalid session. Please try again to continue'
                );
              }

              const instalmentPlans = await getInstalmentPlans();

              if (err(instalmentPlans)) {
                throw instalmentPlans;
              }

              const payments = await getPayments();

              if (err(payments)) {
                throw payments;
              }

              return {contact, instalmentPlans, payments};
            },
            onDone: [
              {
                actions: [
                  assign({
                    contact: (_, event) => event.data.contact,
                    instalmentPlans: (_, event) => event.data.instalmentPlans,
                    payments: (_, event) => event.data.payments,
                  }),

                  (context, __event) => {
                    _refiner('identifyUser', {
                      id: context.contact?.id,
                      email: context.contact?.email,
                      name: `${context.contact?.givenName} ${context.contact?.familyName}`,
                      biller_slug: context.biller?.slug,
                    });
                  },
                ],
                target: 'idle',
              },
            ],
            onError: [
              {
                target: 'idle',
              },
            ],
          },
        },
      },
      on: {
        SIGN_OUT: {
          actions: async (__, {slug}) => {
            analytics.addEvent('logged_out');
            await logout(slug);
          },
          target: 'unauthenticated',
        },
      },
    },
    unauthenticated: {
      on: {
        SEND_SMS: {
          target: 'sendingSMS',
        },
      },
    },
    sendingSMS: {
      invoke: {
        src: async (_, event) => {
          const response = await sendSMSCode(
            (event as SendSMSEvent).phoneNumber as string
          );
          return {
            ...event,
            formattedPhoneNumber: response.formattedPhoneNumber,
          };
        },
        onDone: [
          {
            actions: [
              assign({
                loggedInPhoneNumber: (_, event) =>
                  event.data.formattedPhoneNumber,
              }),
              () => {
                analytics.addEvent('auth_sms_sent');
              },
            ],
            target: 'enteringCode',
          },
        ],
        onError: [
          {
            actions: [
              assign({
                errorMessage: (__context, event) => {
                  console.error(event);
                  if (event.data instanceof Error) {
                    return event.data.message;
                  }
                  if (typeof event.data === 'string') {
                    return event.data;
                  }
                  return 'Error while sending SMS';
                },
              }),
              () => {
                analytics.addEvent('auth_sms_failed');
              },
            ],
            target: 'smsFailed',
          },
        ],
      },
    },
    smsFailed: {
      on: {
        SEND_SMS: {
          target: 'sendingSMS',
        },
      },
    },
    enteringCode: {
      on: {
        RESEND: {
          target: 'sendingSMS',
        },
        CONFIRM_CODE: {
          target: 'verifyingCode',
        },
        BACK: {
          target: 'unauthenticated',
        },
      },
    },
    verifyingCode: {
      invoke: {
        src: async (_, event) => {
          await confirmCode(
            (event as ConfirmCodeEvent).phoneNumber,
            (event as ConfirmCodeEvent).code
          );
        },
        onDone: [
          {
            target: 'initialising',
          },
        ],
        onError: [
          {
            actions: [
              assign({
                errorMessage: (__context, event) => {
                  console.error(event.data);
                  if (event.data instanceof Error) {
                    return event.data.message;
                  }

                  if (typeof event.data === 'string') {
                    return event.data;
                  }
                  return 'Incorrect code. Please try again';
                },
              }),
              () => {
                analytics.addEvent('auth_code_failed');
              },
            ],
            target: 'incorrectCode',
          },
        ],
      },
    },
    incorrectCode: {
      on: {
        CONFIRM_CODE: {
          target: 'verifyingCode',
        },
        RESEND: {
          target: 'sendingSMS',
        },
        BACK: {
          target: 'unauthenticated',
        },
      },
    },
  },
});

interpret(authMachine, {
  devTools: devToolsEnabled(window.location.hostname),
}).start();
