import {AbsoluteDate} from 'payble-shared';
import {z} from 'zod';

export const okayResponse = z.object({
  ok: z.literal(true),
});

export const zAbsoluteDate = z
  .string()
  .transform((value, ctx) => {
    const date = AbsoluteDate.maybeFromISO(value);
    if (!date) {
      ctx.addIssue({
        code: 'invalid_date',
        message: 'Invalid date format',
      });
      return z.NEVER;
    }
    return date;
  })
  .or(z.instanceof(AbsoluteDate));

export const zISODate = z
  .string()
  .transform((value, ctx) => {
    const date = new Date(value);
    if (isNaN(date.getTime()) || value !== date.toISOString()) {
      ctx.addIssue({
        code: 'invalid_date',
        message: 'Invalid date format',
      });
      return z.NEVER;
    }
    return date;
  })
  .or(z.instanceof(Date));
