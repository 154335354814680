import React from 'react';
import {SetupRoutes} from '../routes';
import {goBack} from 'lib/navigation/routes';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {PaymentAmountForm} from '../components/PaymentAmountForm';
import {useSetupRoute} from '../components/SetupRoute';
import {useSetupNavigate} from '../hooks/useSetupNavigate';

export const OneOffAmount = () => {
  const {account} = useSetupRoute();
  const {mode} = useSetupSearchParams();
  const navigate = useSetupNavigate();

  let heading: string, description: string, nextRoute: keyof SetupRoutes;

  const billerConfig = useBillerConfig();
  const paymentConfig =
    (account &&
      billerConfig.getAccountPaymentConfig({
        ...account,
        setupBreakdown: account.setupBreakdown ?? [],
        nextInstalmentAmount: account.targetInstalments?.[0]?.amount,
      })) ||
    {};

  const {minAmount, maxAmount, lockedAmount, defaultAmount} = paymentConfig;

  const prefix = lockedAmount ? 'Confirm the amount' : 'Enter an amount';

  if (mode === 'PAYMENT_MODE') {
    heading = 'Pay now';
    description = prefix + ' to pay now';
    nextRoute = '/biller/:slug/setup/payment-method';
  } else {
    heading = 'Schedule payment';
    description = prefix + ' to pay in the future';
    nextRoute = '/biller/:slug/setup/one-off/schedule';
  }

  return (
    <PaymentAmountForm
      debbieTitle={
        lockedAmount
          ? 'Please confirm the payment amount'
          : 'How much do you want to pay now?'
      }
      heading={heading}
      description={description}
      minAmount={minAmount}
      maxAmount={maxAmount}
      lockedAmount={lockedAmount}
      defaultAmount={defaultAmount}
      onSubmit={values => {
        navigate(nextRoute, {
          amountInCents: values.amountInCents.toString(),
          ...(mode === 'PAYMENT_MODE' ? {mode: 'PAY_AMOUNT'} : {}),
        });
      }}
      onClickBack={goBack}
    />
  );
};
