import React from 'react';
import {DateTime} from 'luxon';

import {
  NoSymbolIcon,
  CalendarIcon,
  CheckIcon,
  CreditCardIcon,
  ExclamationTriangleIcon,
  PauseIcon,
  PlusSmallIcon,
  ArrowPathIcon,
  XMarkIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import {ScaleIcon} from '@heroicons/react/20/solid';
import {TooltipMessage} from 'components/organisms/TooltipMessage';

type EventType =
  // UI events
  | 'next_instalment_due'
  // Real events
  | 'cancelled'
  | 'created'
  | 'completed'
  | 'instalment_missed'
  | 'instalment_payment_failed'
  | 'instalment_payment_succeeded'
  | 'instalment_payment_processing'
  | 'instalment_skipped'
  | 'payment_method_updated'
  | 'rebalanced'
  | 'remainder_payment_failed'
  | 'remainder_payment_succeeded'
  | 'remainder_payment_processing'
  | 'instalments_updated'
  | 'update_recurring_amount'
  | 'instalment_updated';

const friendly: Record<EventType, string> = {
  next_instalment_due: 'Next payment due',
  cancelled: 'Cancelled',
  created: 'Created',
  completed: 'Completed',
  instalment_missed: 'Missed payment',
  instalment_payment_failed: 'Payment failed',
  instalment_payment_processing: 'Payment processing',
  instalment_payment_succeeded: 'Payment succeeded',
  instalment_skipped: 'Skipped payment',
  payment_method_updated: 'Payment method updated',
  rebalanced: 'Amount owing changed',
  remainder_payment_failed: 'Remainder failed',
  remainder_payment_processing: 'Remainder processing',
  remainder_payment_succeeded: 'Remainder paid',
  instalments_updated: 'Scheduled updated',
  update_recurring_amount: 'Recurring amount updated',
  instalment_updated: 'Payment amount updated',
};

const colors: Record<EventType, string> = {
  cancelled: 'bg-yellow-400',
  created: 'bg-gray-400',
  completed: 'bg-green-400',
  instalment_missed: 'bg-red-400',
  instalment_skipped: 'bg-yellow-400',
  instalment_payment_failed: 'bg-red-400',
  instalment_payment_processing: 'bg-gray-400',
  instalment_payment_succeeded: 'bg-green-400',
  next_instalment_due: 'bg-orange-400',
  payment_method_updated: 'bg-blue-400',
  rebalanced: 'bg-blue-400',
  remainder_payment_failed: 'bg-red-400',
  remainder_payment_processing: 'bg-gray-400',
  remainder_payment_succeeded: 'bg-green-400',
  instalments_updated: 'bg-blue-400',
  update_recurring_amount: 'bg-blue-400',
  instalment_updated: 'bg-blue-400',
};

const icons: Record<
  EventType,
  (props: React.ComponentProps<'svg'>) => JSX.Element
> = {
  next_instalment_due: CalendarIcon,
  instalment_payment_failed: XMarkIcon,
  instalment_missed: ExclamationTriangleIcon,
  cancelled: NoSymbolIcon,
  completed: CheckIcon,
  instalment_payment_processing: ArrowPathIcon,
  instalment_payment_succeeded: CheckIcon,
  instalment_skipped: PauseIcon,
  payment_method_updated: CreditCardIcon,
  rebalanced: ScaleIcon,
  remainder_payment_failed: XMarkIcon,
  remainder_payment_processing: ArrowPathIcon,
  remainder_payment_succeeded: CheckIcon,
  created: PlusSmallIcon,
  instalments_updated: ScaleIcon,
  update_recurring_amount: ScaleIcon,
  instalment_updated: ScaleIcon,
};

type TimelineEventProps = {
  tooltipText?: string;
  type: string;
  date: string;
};

export const TimelineEvent: React.FC<TimelineEventProps> = ({
  tooltipText,
  type,
  date,
}) => {
  const eventType = type.replace('instalment_plan.', '') as EventType;

  const Icon = icons[eventType];
  const color = colors[eventType];
  const title = friendly[eventType];

  if (!title) {
    console.warn(`Unknown event type: ${eventType}`);
    return null;
  }

  return (
    <div className="relative flex space-x-3">
      <div>
        <span
          className={`${color} h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`}
        >
          <Icon className="w-5 h-5 text-white" aria-hidden="true" />
        </span>
      </div>
      <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
        <div className="flex">
          <p className="text-sm text-gray-500 capitalize cursor-default mr-2">
            {title}
          </p>
          {tooltipText && (
            <TooltipMessage
              tooltipText={tooltipText}
              tooltipBgColorAndHue="gray-100"
              tooltipTextColorAndHue="gray-500"
            >
              <button className="p-0 m-0 inline -mt-2">
                <QuestionMarkCircleIcon className="h-5 w-5 inline text-gray-500" />
              </button>
            </TooltipMessage>
          )}
        </div>
        <div className="text-right text-sm whitespace-nowrap text-gray-500">
          {DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)}
        </div>
      </div>
    </div>
  );
};
