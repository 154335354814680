import React, {useContext, useEffect} from 'react';
import {useActor} from '@xstate/react';
import {useParams} from 'react-router';
import NumberFormat from 'react-number-format';

import * as Auth from 'lib/auth/Auth';
import {getBillerSlugFromUrl} from 'lib/url';
import {
  PaymentMethod,
  PaymentMethodStatus,
  useGetInstalmentPlanQuery,
  usePayInstalmentPlanRemainderMutation,
} from 'lib/graphql/API';

import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Debbie} from 'components/organisms/Debbie';
import {ChoosePaymentMethod} from 'components/organisms/ChoosePaymentMethod';
import {ChevronRightIcon, HomeIcon, PlusIcon} from '@heroicons/react/20/solid';
import {navigate} from 'lib/navigation/routes';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {PaymentMethodRow} from '../components/PaymentMethodRow';
import {buttonClasses, classNames} from 'lib/styles';
import {TryAgain} from 'components/molecules/TryAgain';
import {RolloverNotice} from 'components/atoms/RolloverNotice';
import {getYearlyRollOverStatusByBillerSlug} from 'payble-shared/src/biller-config/handlers';

export const PayRemainder: React.FC = () => {
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const billerSlug = getBillerSlugFromUrl();
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const hasYearlyRollOver = getYearlyRollOverStatusByBillerSlug(billerSlug);

  // When page first loads ensure we have the most up to date contact data
  useEffect(() => {
    authService.send('REFRESH_CONTACT');
    document.title = 'Payble - Pay Remainder';
  }, []);

  // State for this control
  const [paymentMethodId, setPaymentMethodId] = React.useState('');
  const [showPaymentMethodChooser, setShowPaymentMethodChooser] =
    React.useState(false);

  // Get current payment method so we can show it as selected
  if (billerSlug === '') return <>Cannot load without a biller slug</>;
  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  const {
    loading: instalmentPlanLoading,
    error: instalmentPlanError,
    data: instalmentPlanData,
  } = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (instalmentPlanData?.instalmentPlan?.paymentMethodId) {
      setPaymentMethodId(instalmentPlanData?.instalmentPlan?.paymentMethodId);
    }
  }, [instalmentPlanData]);

  const [
    payRemainder,
    {loading: payRemainderLoading, error: payRemainderError},
  ] = usePayInstalmentPlanRemainderMutation();

  if (instalmentPlanLoading || !authState.matches({authenticated: 'idle'}))
    return <Loading />;
  if (instalmentPlanError) {
    return (
      <TryAgain
        errorMessage={instalmentPlanError.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }
  if (!authState.context.contact) {
    // There is some weird refresh contact bug investigate
    // for now enjoy this terrible terrible hack to try and resolve it
    if (window.location.search.indexOf('reload') === -1) {
      window.location.search = '?reload';
    }
    return <ErrorMessage message="No contact loaded in session" />;
  }
  // Select payment method
  // Change payment method

  const onPayRemainder = async () => {
    if (paymentMethodId === '') {
      console.error('Please choose a payment method');
      return;
    }

    const {errors} = await payRemainder({
      variables: {
        input: {
          instalmentPlanId,
          paymentMethodId,
        },
      },
    });

    if (!errors) {
      navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
        slug: billerSlug,
        instalmentPlanId,
      });
    }
  };

  const contactPaymentMethod = authState.context.contact?.paymentMethods?.find(
    x => x.id === paymentMethodId
  );

  if (!contactPaymentMethod) {
    return <ErrorMessage message="Payment method not found." />;
  }

  const isPaymentMethodActive =
    contactPaymentMethod.status === PaymentMethodStatus.Active;

  return (
    <div className="relative">
      <Debbie
        title="You can pay off your plan early"
        message="Please choose a payment method below to pay off your plan. Once successfully processed your plan will be completed."
      />

      <div className="flex mt-5 mb-2">
        <div className="flex-1">
          <nav className="flex" aria-label="Breadcrumb">
            <ol
              role="list"
              className="flex px-6 space-x-4 bg-white rounded-md shadow"
            >
              <li className="flex">
                <div className="flex items-center">
                  <button
                    onClick={() =>
                      navigate('/biller/:slug', {slug: billerSlug})
                    }
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </button>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <button
                    onClick={() =>
                      navigate(
                        '/biller/:slug/instalment-plan/:instalmentPlanId',
                        {slug: billerSlug, instalmentPlanId}
                      )
                    }
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    Payment Plan
                  </button>
                </div>
              </li>
            </ol>
          </nav>
        </div>

        <div className="flex pl-4">
          <button
            onClick={() =>
              navigate('/biller/:slug/profile/add-payment-method', {
                slug: billerSlug,
              })
            }
            type="button"
            className={classNames(
              'transition inline-flex items-center px-4 md:py-[0.6rem] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
              showPaymentMethodChooser ? '' : 'opacity-0'
            )}
          >
            <PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            Add Payment Method
          </button>
        </div>
      </div>

      <div className="mt-5 overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          {showPaymentMethodChooser ? (
            <ChoosePaymentMethod
              paymentMethods={
                authState.context.contact.paymentMethods as PaymentMethod[]
              }
              selectedPaymentMethodId={paymentMethodId}
              onChange={e => {
                setPaymentMethodId(e);
                setShowPaymentMethodChooser(false);
              }}
            />
          ) : (
            <div className="flex-col">
              <div className="relative flex items-start py-4">
                <PaymentMethodRow paymentMethod={contactPaymentMethod} />

                <button
                  onClick={() => {
                    if (!payRemainderLoading) {
                      setShowPaymentMethodChooser(true);
                    }
                  }}
                  className="flex items-center ml-3 place-self-center"
                >
                  <ChevronRightIcon className="w-10 h-10 text-gray-400 hover:text-gray-800" />
                </button>
              </div>
            </div>
          )}
          <RolloverNotice showNotice={hasYearlyRollOver} />

          {payRemainderError && (
            <div>
              <ErrorMessage message={payRemainderError.message} />
            </div>
          )}
          <button
            className={buttonClasses}
            onClick={onPayRemainder}
            disabled={
              showPaymentMethodChooser ||
              payRemainderLoading ||
              !isPaymentMethodActive
            }
          >
            {payRemainderLoading && <ButtonSpinner />}
            <span className="mr-1">Pay remaining</span>
            <NumberFormat
              value={
                (instalmentPlanData?.instalmentPlan?.amountDue as number) / 100
              }
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
