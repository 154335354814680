import React, {useEffect} from 'react';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useLinkAccount} from '../hooks/useLinkAccount';
import {getBillerSlugFromUrl} from 'lib/url';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {Loading} from 'components/atoms/Loading';

export const LinkContactInfringement: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {data: account, loading} = useGetAccountFromSearchParams();
  const navigate = useSetupNavigate();
  const {verificationCode, accountType} = useSetupSearchParams();

  const {linkAccount} = useLinkAccount();

  useEffect(() => {
    if (accountType && accountType !== 'infringements') {
      navigate('/biller/:slug/setup');
    }
  }, [accountType]);

  useEffect(() => {
    if (!loading && account) {
      linkAccount({
        accountExternalId: account.externalId,
        accountType: account.type,
        billerSlug,
        verificationCode,
      })
        .then(() => navigate('/biller/:slug/setup/flexible/installments'))
        .catch(() => {
          throw new Error(`Failed to link account with id: ${account.id}`);
        });
    }
  }, [loading, account]);

  return <Loading />;
};
