import {usePatchSearchParams} from 'lib/navigation/usePatchSearchParams';
import {getBillerSlugFromUrl} from 'lib/url';
import {getBillerConfig} from 'payble-shared';

export function useAnonymousPaymentParams() {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = getBillerConfig(billerSlug);
  const accountTypesInfo = billerConfig.getAccountTypesInfo();

  const {params, patch} = usePatchSearchParams<{
    accountType: string;
    accountExternalId: string;
    verificationCode: string;
    lookingUpAnother: string;
    amountInCents: string;
  }>({
    accountType: accountTypesInfo[0].id,
    accountExternalId: '',
    verificationCode: '',
    lookingUpAnother: '',
    amountInCents: '',
  });

  const {
    accountType,
    accountExternalId,
    verificationCode,
    lookingUpAnother,
    amountInCents,
  } = params;

  return {
    accountType,
    accountExternalId,
    verificationCode,
    lookingUpAnother,
    amountInCents: parseInt(amountInCents || '0', 10) || 0,
    patch,
  };
}
