import {FC, ReactNode} from 'react';
import {useRegisterField} from '../hooks/useRegisterField';
import {RadioGroup, RadioGroupProps} from './RadioGroup';
import {FormField} from './FormField';
import {Controller} from 'react-hook-form';

export interface FormRadioGroupProps extends RadioGroupProps {
  label?: ReactNode;
  name: string;
}

export const FormRadioGroup: FC<FormRadioGroupProps> = ({
  label,
  name,
  ...props
}) => {
  const {id, form} = useRegisterField(name);

  return (
    <FormField id={id} name={name} label={label} className="flex-1">
      <Controller
        control={form.control}
        name={name}
        render={({formState, field: {onChange, ...f}}) => (
          <RadioGroup
            id={id}
            onValueChange={onChange}
            {...props}
            {...f}
            disabled={props.disabled || f.disabled || formState.isSubmitting}
          />
        )}
      />
    </FormField>
  );
};

FormRadioGroup.displayName = 'FormRadioGroup';
