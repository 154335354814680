import {classNames} from 'lib/styles';
import React from 'react';

type StepProps = {
  step: number;
  progress: number;
  screenReaderText: string;
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  onClick?: () => void;
  isLast?: boolean;
};

export const Step: React.FC<StepProps> = ({
  step,
  progress,
  screenReaderText,
  icon: Icon,
  onClick,
  isLast,
}) => {
  return (
    <li className={classNames(!isLast ? 'pr-8 sm:pr-20' : '', 'relative')}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div
          className={classNames(
            'h-0.5 w-full',
            progress > step ? 'bg-blue-600' : 'bg-gray-300'
          )}
        />
      </div>
      <a
        onClick={progress > step ? onClick : undefined}
        className={classNames(
          'relative w-8 h-8 flex items-center justify-center  rounded-full',
          progress > step ? 'bg-blue-600' : '',
          progress === step ? 'bg-white border-blue-600 border-2 ' : '',
          progress < step ? 'border-2 border-gray-300 bg-white' : ''
        )}
      >
        <Icon
          className={classNames(
            'w-5 h-5',
            progress > step ? 'text-white' : '',
            progress === step ? 'text-blue-600' : '',
            progress < step ? 'text-gray-300' : ''
          )}
          aria-hidden="true"
        />
        <span className="sr-only">{screenReaderText}</span>
      </a>
    </li>
  );
};
