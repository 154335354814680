export abstract class DomainError extends Error {
  previousError?: DomainError | Error;

  message: string;

  abstract get code(): string;
  context: Record<string, any> = {};

  httpCode = 500;

  constructor(message: string) {
    super(message);
    this.message = message;
  }

  toHTTP() {
    return {
      statusCode: this.httpCode,
      body: {
        code: this.code,
        message: this.message,
        context: this.dehydrateContext(),
      },
    };
  }

  getCause(): Error | undefined {
    return this.previousError;
  }

  getRootCause(): Error | undefined {
    const previousErrors = this.getPreviousErrors();
    return previousErrors[previousErrors.length - 1];
  }

  getPreviousErrors(): Error[] {
    const {previousError} = this;
    if (!previousError) return [];
    if (previousError instanceof DomainError) {
      return [previousError, ...previousError.getPreviousErrors()];
    }
    return [previousError];
  }

  get name(): string {
    return this.previousError
      ? `${this._name}(${this.previousError.name})`
      : this._name;
  }

  hydrateContext(context: Record<string, unknown>): void {
    this.context = {...this.context, ...context};
  }

  /**
   * This is intentionally empty by default. It is meant to be overridden by
   * sub classes that require additional context data to be set on the error
   * sent to clients that can be rehydrated using `hydrateContext`.
   */
  dehydrateContext(): Record<string, unknown> {
    return {};
  }

  private get _name(): string {
    return this.constructor.name;
  }

  toString(): string {
    const currentErrorString = `${this._name}: ${this.message}`;
    return this.previousError
      ? `${currentErrorString}\n\t${this.previousError.toString()}`
      : currentErrorString;
  }

  static wrap<T extends typeof DomainError>(
    this: T,
    error: unknown,
    message?: string,
    context: InstanceType<T>['context'] = {}
  ): InstanceType<T> {
    let previousError: Error;
    if (error instanceof Error) {
      previousError = error;
    } else {
      previousError = new Error(`Unexpected error value thrown: "${error}"`);
    }

    let finalMessage: string;
    if (message) {
      finalMessage = message;
    } else if (previousError instanceof DomainError) {
      finalMessage = previousError.message;
    } else {
      finalMessage = 'Something went wrong';
    }

    const domainError = new (this as any)(finalMessage);
    domainError.previousError = previousError;
    domainError.context = context;
    if (previousError instanceof Error) {
      this.mergeTrace(domainError, previousError);
    }

    return domainError as InstanceType<T>;
  }

  static create<T extends typeof DomainError>(
    this: T,
    message: string,
    context: InstanceType<T>['context'] = {}
  ): InstanceType<T> {
    const error = new (this as any)(message);
    error.context = context;
    error.previousError = undefined;
    return error as InstanceType<T>;
  }

  private static mergeTrace(target: Error, old?: Error): void {
    if (old && target.stack && old.stack) {
      const messageLines = (target.message.match(/\n/g) || []).length + 1;
      target.stack = `${target.stack
        .split('\n')
        .slice(0, messageLines + 1)
        .join('\n')}\n${old.stack}`;
    }
  }
}
