import React, {useContext, useState} from 'react';
import {ExclamationCircleIcon} from '@heroicons/react/24/outline';
import * as Auth from 'lib/auth/Auth';
import {useActor} from '@xstate/react';
import {InstalmentPlanFragment} from 'lib/graphql/API';
import {buttonClasses} from 'lib/styles';
import {getSupportEmail} from 'payble-shared/src/biller-config/handlers';

type CancelIncorrectBalanceProps = {
  instalmentPlan: InstalmentPlanFragment;
};

export const CancelIncorrectBalance: React.FC<CancelIncorrectBalanceProps> = ({
  instalmentPlan,
}) => {
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const user = authState.context.contact;
  const [name, setName] = useState<string>(
    `${user?.givenName} ${user?.familyName}`
  );
  const [email, setEmail] = useState<string | undefined>(user?.email);
  const [query, setQuery] = useState<string>('');
  const supportEmail = getSupportEmail(authState.context.biller!.slug);
  return (
    <>
      <ExclamationCircleIcon className="self-center w-20 mb-5" />
      <div className="mb-3">
        Any changes to your amount owing will be synchronised from{' '}
        {authState.context.biller?.name} and reflected in Payble. If your
        balance increases or decreases your scheduled payments will be adjusted
        automatically. If this happens, you'll be sent an SMS to let you know.
      </div>
      <div className="mb-3">
        If you would like to clarify or enquire about the balancing owing,
        please contact support below or at{' '}
        <a
          href={`mailto:${supportEmail}`}
          className="text-blue-600 hover:underline"
        >
          {supportEmail}
        </a>
      </div>
      <label
        htmlFor="name"
        className="block text-sm font-semibold text-gray-700 mb-2"
      >
        Please enter your name
      </label>
      <div className="mt-1 mb-5">
        <input
          id="name"
          name="name"
          type="text"
          className="shadow-sm focus:ring-blue-500 ring-offset-2 ring-2 focus:border-gray-300 block w-full sm:text-sm border-gray-300 rounded-md"
          defaultValue={name}
          onChange={e => setName(e.target.value)}
        />
      </div>

      <label
        htmlFor="email"
        className="block text-sm font-semibold text-gray-700 mb-2"
      >
        Please enter your email
      </label>
      <div className="mt-1 mb-5">
        <input
          id="email"
          name="email"
          type="email"
          className="shadow-sm focus:ring-blue-500 ring-offset-2 ring-2 focus:border-gray-300 block w-full sm:text-sm border-gray-300 rounded-md"
          defaultValue={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>

      <label
        htmlFor="query"
        className="block text-sm font-semibold text-gray-700 mb-2"
      >
        Please type your concern below
      </label>
      <div className="mt-1 mb-5">
        <textarea
          rows={4}
          name="query"
          id="query"
          disabled={false}
          className="shadow-sm focus:ring-blue-500 ring-offset-2 ring-2 focus:border-gray-300 block w-full sm:text-sm border-gray-300 rounded-md"
          defaultValue={query}
          onChange={e => setQuery(e.target.value)}
          placeholder="In a few words please tell us why you deem the shown balance amount is incorrect."
        />
      </div>
      <a
        type="button"
        href={`mailto:${supportEmail}?subject=${name} - ${instalmentPlan}: Support Enquiry Regarding Incorrect Balance&body=${query}`}
        target={'_blank'}
        className={buttonClasses}
      >
        Enquire now
      </a>
    </>
  );
};
