import React from 'react';
import {Spinner} from './Spinner';

export const Loading: React.FC = () => {
  return (
    <div className="max-w-lg mx-auto px-2 flex justify-center">
      <Spinner />
    </div>
  );
};
