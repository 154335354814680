import React from 'react';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {buttonClasses} from 'lib/styles';

type TryAgainProps = {
  errorMessage: string;
  onClick: Function;
  buttonText?: string;
};

export const TryAgain: React.FC<TryAgainProps> = ({
  errorMessage,
  onClick,
  buttonText,
}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <ErrorMessage message={errorMessage} />
      <button className={buttonClasses} onClick={() => onClick()}>
        {buttonText || 'Try Again'}
      </button>
    </div>
  );
};
