import React from 'react';
import {CalendarIcon, CheckIcon, ClockIcon} from '@heroicons/react/20/solid';
import {InstalmentPlan, Payment} from 'lib/graphql/API';
import {DateTime} from 'luxon';

interface Props {
  plan: InstalmentPlan;
}

export const Instalments: React.FC<Props> = ({plan}) => {
  const payments = plan.instalments[0]?.payments as Payment[];
  const amount = payments[0].amount;

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8 mt-4">
        <li>
          <div className="relative pb-8">
            <span
              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-blue-600"
              aria-hidden="true"
            />

            <div className="relative flex space-x-3">
              <div>
                <span
                  className={
                    'bg-blue-600 h-8 w-8 rounded-full flex items-center justify-center'
                  }
                >
                  <CheckIcon
                    className="w-5 h-5 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p className="text-sm text-gray-500 capitalize">
                    First instalment paid - ${amount}
                  </p>
                </div>
                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                  {DateTime.fromISO(plan.createdAt).toLocaleString(
                    DateTime.DATE_MED
                  )}
                </div>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div className="relative pb-8">
            <span
              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />

            <div className="relative flex space-x-3">
              <div>
                <span
                  className={
                    'border-2 border-blue-600 bg-white h-8 w-8 rounded-full flex items-center justify-center '
                  }
                >
                  <CalendarIcon
                    className="w-5 h-5 text-blue-600"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p className="text-sm text-gray-500 capitalize">
                    Next instalment - ${amount}
                  </p>
                </div>
                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                  {DateTime.fromISO(plan.createdAt)
                    .plus({
                      days: 365 / plan.instalments.length,
                    })
                    .toLocaleString(DateTime.DATE_MED)}
                </div>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div className="relative pb-8">
            <div className="relative flex space-x-3">
              <div>
                <span
                  className={
                    'border-2 border-gray-300 bg-white h-8 w-8 rounded-full flex items-center justify-center '
                  }
                >
                  <ClockIcon
                    className="w-5 h-5 text-gray-300"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p className="text-sm text-gray-500 capitalize">
                    +{plan.instalments.length - 2} recurring payments
                  </p>
                </div>
                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                  {/* <time dateTime={''}>
                  {DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)}
                </time> */}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
