import {z} from 'zod';
import {FormConfig} from '../../types';

export const auspaynet = {
  title: 'AusPayNet BSB API',
  configType: 'global',

  validation: z.object({
    subscriptionKey: z.string(),
    test: z.boolean().optional(),
  }),

  form: [
    {
      type: 'text',
      name: 'subscriptionKey',
      label: 'Subscription Key',
    },
  ],
} satisfies FormConfig;
