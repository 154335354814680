import React from 'react';
import {useFormikContext} from 'formik';

export function LookupAccountSubmitButton({
  cta,
  loading,
}: {
  cta: string;
  loading?: boolean;
}) {
  const {
    values: {accountExternalId, accountType},
  } = useFormikContext<{
    accountExternalId: string;
    accountType: string;
  }>();

  return (
    <button
      type="submit"
      disabled={!accountExternalId || accountType === '' || loading}
      className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      {cta}
    </button>
  );
}
