import React, {useEffect} from 'react';
import {HomeV1} from './components/HomeV1';

export const Home: React.FC = () => {
  useEffect(() => {
    document.title = 'Payble - Home';
  }, []);

  return <HomeV1 />;
};
