import React from 'react';
import {Stream} from '@cloudflare/stream-react';
import {VideoSkeleton} from 'components/atoms/VideoSkeleton';

type HowToVideoRowProps = {
  title: string;
  cloudfareStreamVideoID: string;
  videoLink: string;
  htmlId: string;
};

export const HowToVideoRow: React.FC<HowToVideoRowProps> = ({
  title,
  cloudfareStreamVideoID,
  videoLink,
  htmlId,
}) => {
  return (
    <>
      <div className="sm:flex justify-between items-center mb-5">
        <div className="text-base text-gray-500 m-1">
          <a className="pb-2" target="_blank" href={videoLink}>
            {title}
          </a>
        </div>

        <div className="relative w-100 md:w-80 h-100 sm:w-96">
          <VideoSkeleton />
          <div id={htmlId} className="aspect-video">
            <Stream controls responsive src={cloudfareStreamVideoID} />
          </div>
        </div>
      </div>
    </>
  );
};
