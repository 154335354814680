import {DomainError} from './DomainError';

export class UnexpectedError extends DomainError {
  get code() {
    return 'FAILED';
  }
}
export class AuthenticationError extends DomainError {
  override httpCode = 401;
  get code() {
    // This code is required for consumer-web to continue functioning.
    return 'UNAUTHENTICATED';
  }
}

export class PersistenceError extends DomainError {
  get code() {
    return 'PERSISTENCE';
  }
}

export class AuthorizationError extends DomainError {
  override httpCode = 403;
  get code() {
    return 'AUTHORIZATION';
  }
}

export class ClientError extends DomainError {
  override httpCode = 400;
  get code() {
    return 'CLIENT';
  }
}

export class ValidationError extends ClientError {
  get code() {
    return 'VALIDATION';
  }
}

export class NotFoundError extends DomainError {
  override httpCode = 404;
  get code() {
    return 'NOT_FOUND';
  }

  static of(modelName: string, context?: Record<string, unknown>) {
    return NotFoundError.create(`${modelName} not found`, context);
  }
}

export class PaymentGatewayError extends DomainError {
  override httpCode = 400;

  declare context: {
    response?: unknown;
  };

  get response(): unknown {
    return this.context.response ?? null;
  }

  get code() {
    return 'PAYMENT_GATEWAY';
  }
}

export class TimeoutError extends DomainError {
  override httpCode = 500;

  get code() {
    return 'TIMEOUT';
  }
}

export class AccountVerificationError extends DomainError {
  override httpCode = 400;

  declare context: {
    type?: (typeof AccountVerificationError.types)[number];
  };
  static types = ['required', 'failed'] as const;

  get code() {
    return 'ACCOUNT_VERIFICATION';
  }

  dehydrateContext(): Record<string, unknown> {
    return {
      type: this.type,
    };
  }

  get type(): (typeof AccountVerificationError.types)[number] {
    const type = this.context.type;
    if (!type) {
      return 'failed';
    }
    return AccountVerificationError.types.includes(type) ? type : 'failed';
  }
}

export class ContactWithMobileNumberAlreadyExists extends DomainError {
  override httpCode = 409;

  declare context: {
    type: (typeof ContactWithMobileNumberAlreadyExists.types)[number];
    contactId: string;
  };

  static types = ['conflict'] as const;

  get code() {
    return 'CONTACT_WITH_MOBILE_NUMBER_ALREADY_EXISTS';
  }

  dehydrateContext(): Record<string, unknown> {
    return {
      type: this.type,
      contactId: this.context.contactId,
    };
  }

  get type(): (typeof ContactWithMobileNumberAlreadyExists.types)[number] {
    return this.context.type;
  }
}
