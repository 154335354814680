import React from 'react';
import {
  CalendarIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  ClipboardIcon,
  ExclamationTriangleIcon,
  EnvelopeIcon,
  ArrowPathIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import {InstalmentPlanFragment} from 'lib/graphql/API';
import {DateTime} from 'luxon';
import {TimelineEvent} from './TimelineEvent';
import {navigate} from 'lib/navigation/routes';
import {getBillerSlugFromUrl} from 'lib/url';

// Reverse timeline order:
// Hide future events beyond the next instalment
// - Button to show this
// Due Date
// Change of payment method
// All instalments
// Created instalment plan at

type Status =
  | 'pending'
  | 'paid'
  | 'repair'
  | 'failed'
  | 'action required'
  | 'created'
  | 'planStarted'
  | 'smsSent';

const __colors: Record<Status, string> = {
  'action required': 'bg-red-400',
  failed: 'bg-red-400',
  repair: 'bg-yellow-400',
  pending: 'bg-gray-400',
  created: 'bg-gray-400',
  smsSent: 'bg-blue-400',
  planStarted: 'bg-blue-400',
  paid: 'bg-green-400',
};

const __icons: Record<
  Status,
  (props: React.ComponentProps<'svg'>) => JSX.Element
> = {
  'action required': ExclamationTriangleIcon,
  created: ClipboardIcon,
  smsSent: EnvelopeIcon,
  failed: XMarkIcon,
  repair: ArrowPathIcon,
  planStarted: ClipboardDocumentCheckIcon,
  pending: CalendarIcon,
  paid: CheckIcon,
};

type TimelineEvent = {
  id: string;
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  target: string;
  iconBackground: string;
  content: string;
  datetime: DateTime;
};

type TimelineProps = {
  instalmentPlan: InstalmentPlanFragment;
  isPaymentMethodActive: boolean;
};

export const Timeline: React.FC<TimelineProps> = ({
  instalmentPlan,
  isPaymentMethodActive,
}) => {
  if (
    !instalmentPlan ||
    !instalmentPlan.events ||
    !instalmentPlan.events.length
  ) {
    return (
      <div>
        <p>No activity</p>
      </div>
    );
  }

  const slug = getBillerSlugFromUrl();

  const isAnyInstalmentProcessing = instalmentPlan.instalments.some(
    x => x.status === 'processing'
  );

  const isAnyInstalmentPayble = instalmentPlan.instalments.some(
    i => i.status === 'scheduled'
  );

  const showNextInstalmentDueDate =
    !isAnyInstalmentProcessing &&
    isAnyInstalmentPayble &&
    instalmentPlan.status !== 'cancelled' &&
    instalmentPlan.status !== 'completed' &&
    instalmentPlan.status !== 'processing' &&
    !!instalmentPlan.nextInstalmentDueAt;

  const canPayNextInstalment =
    showNextInstalmentDueDate && isPaymentMethodActive;

  const payNextInstalment = () => {
    navigate('/biller/:slug/instalment-plan/:instalmentPlanId/pay-instalment', {
      instalmentPlanId: instalmentPlan?.id as string,
      slug,
    });
  };

  return (
    <ul role="list" className="mt-4 -mb-8">
      {showNextInstalmentDueDate && (
        <li key={instalmentPlan.id}>
          <div className="relative pb-8">
            <span
              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
            {instalmentPlan.nextInstalmentDueAt && (
              <TimelineEvent
                type="next_instalment_due"
                date={instalmentPlan.nextInstalmentDueAt.toISO()}
                key={`${instalmentPlan.id}-next-due`}
              />
            )}
            {isPaymentMethodActive ? (
              <button
                onClick={payNextInstalment}
                disabled={!canPayNextInstalment}
                className="transition ml-10 mt-2 -mb-2 md:absolute md:right-32 md:-top-2.5 text-sm font-semibold border border-transparent rounded-md shadow-sm text-white bg-blue-600 focus:ring-2 focus:ring-blue-600 px-5 py-2 hover:bg-blue-700 focus:outline-none focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              >
                Pay early
              </button>
            ) : (
              <div className="flex flex-col items-start">
                <button
                  onClick={() => {
                    if (instalmentPlan) {
                      navigate(
                        '/biller/:slug/instalment-plan/:instalmentPlanId/change-payment-method',
                        {
                          instalmentPlanId: instalmentPlan.id,
                          slug,
                        }
                      );
                    }
                  }}
                  className="px-5 py-2 mt-2 ml-10 -mb-2 text-sm font-semibold text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm focus:ring-2 focus:ring-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                >
                  Change payment method
                </button>
              </div>
            )}
          </div>
        </li>
      )}

      {instalmentPlan.events.map((event, index) => {
        // Exclude any admin fix events which are not really for the UI
        if (event.type.includes('_fixed')) {
          return [];
        }

        // When a direct debit payment is processing then we should show
        // a tooltip as users find the processing time confusing.
        const tooltipText =
          index === 0 &&
          (isAnyInstalmentProcessing ||
            instalmentPlan.status === 'processing') &&
          event.type.includes('_payment_processing')
            ? 'Direct debit can take 3-5 business days.'
            : undefined;

        return (
          <li key={index}>
            <div className="relative pb-8">
              {instalmentPlan?.events &&
                index !== instalmentPlan.events.length - 1 && (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                )}

              <TimelineEvent
                tooltipText={tooltipText}
                type={event.type}
                date={event.createdAt}
                key={event.id}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};
