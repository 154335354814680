import qs from 'qs';

export const toQueryString = (obj: unknown) => {
  return qs.stringify(recurseToJSON(obj));
};

export const fromQueryString = (str: string) => {
  return qs.parse(str);
};

/**
 * Call toJSON on any nested objects that satisfy that interface and return an object
 */
function recurseToJSON(obj: unknown): unknown {
  if (Array.isArray(obj)) {
    return obj.map(recurseToJSON);
  }

  if (typeof obj === 'object' && obj !== null) {
    if ('toJSON' in obj && typeof obj.toJSON === 'function') {
      return obj.toJSON();
    }

    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [k, recurseToJSON(v)])
    );
  }

  return obj;
}
