import {ApolloError} from '@apollo/client';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {TryAgain} from 'components/molecules/TryAgain';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {InstalmentPlanFragment} from 'lib/graphql/API';
import {navigate} from 'lib/navigation/routes';
import {buttonClasses} from 'lib/styles';
import React from 'react';
import {Link} from 'react-router-dom';

type CancelConfirmationProps = {
  instalmentPlan: InstalmentPlanFragment;
  cancelError?: ApolloError;
  cancelLoading: boolean;
  billerSlug: string;
  instalmentPlanId: string;
  canCancel: boolean;
  onCancel: () => Promise<void>;
};

export const CancelConfirmation: React.FC<CancelConfirmationProps> = ({
  instalmentPlan,
  cancelError,
  cancelLoading,
  billerSlug,
  instalmentPlanId,
  canCancel,
  onCancel,
}) => {
  const billerConfig = useBillerConfig();
  return (
    <>
      <h2 className="text-base font-bold text-left sm:text-lg">
        Acknowledgement and confirmation
      </h2>
      <div className="mt-2 mb-2">
        I agree that I want to cancel my payment plan for{' '}
        {billerConfig
          .getExternalIdLabel(instalmentPlan.account.type)
          .toLowerCase()}{' '}
        {instalmentPlan.account.externalId}
        <div className="mt-3 mb-3">{instalmentPlan.account.description}</div>
        <p>
          I understand that by cancelling my payments, no further scheduled
          payments will be made through Payble. Any payments already submitted
          will continue to be processed. Any amounts owing will still need to be
          paid by the due date on your notice.
        </p>
      </div>
      {cancelError && (
        <div>
          <TryAgain
            errorMessage={cancelError.message}
            onClick={() => {
              navigate('/biller/:slug/setup', {slug: billerSlug});
            }}
          />
        </div>
      )}
      <button
        disabled={cancelLoading || !canCancel}
        className={buttonClasses}
        onClick={onCancel}
      >
        {cancelLoading && <ButtonSpinner />}
        Confirm cancellation
      </button>
      <Link
        to={`/biller/${billerSlug}/instalment-plan/${instalmentPlanId}`}
        className="mt-5 text-center"
      >
        No, I have changed my mind
      </Link>
    </>
  );
};
