import React from 'react';

import {Debbie} from 'components/organisms/Debbie';

import {Formik, Form, Field} from 'formik';
import {SetupOptionsRadio} from '../components/SetupOptionsRadio';
import {PaymentOption} from 'payble-shared';
import {goBack} from 'lib/navigation/routes';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';
import {trackSetupProgress} from '../../../analytics/hooks/useAnalytics';

export const SetupOptions: React.FC = () => {
  const navigate = useSetupNavigate();
  const {accountType} = useSetupSearchParams();
  const billerConfig = useBillerConfig();

  const handleOnNext = (mode?: PaymentOption['value']) => {
    trackSetupProgress('mode_selected', {mode});
    switch (mode) {
      case 'PAY_AMOUNT':
        // user can select now or scheduled
        navigate('/biller/:slug/setup/one-off', {mode});
        break;
      case 'PAYMENT_MODE':
        // user cannot select now or scheduled, take them directly to the amount
        navigate('/biller/:slug/setup/one-off/amount', {mode});
        break;
      case 'PAY_AUTO':
        navigate('/biller/:slug/setup/auto', {mode});
        break;
      case 'PAY_X_EVERY_Z':
        navigate('/biller/:slug/setup/flexible', {mode});
        break;
      case 'PAY_EVERY_X':
        navigate(
          billerConfig.extendPlanTerms
            ? '/biller/:slug/setup/extended-flexible-plan'
            : '/biller/:slug/setup/flexible-plan',
          {mode}
        );

        break;
      case 'PAY_BALANCED':
        navigate('/biller/:slug/setup/balanced', {mode});
        break;
      case 'PAY_SMOOTH':
        navigate('/biller/:slug/setup/smooth', {mode});
        break;
      case 'PAY_CATCH_UP':
        navigate('/biller/:slug/setup/catch-up', {mode});
        break;
      default:
        navigate('/biller/:slug/setup');
    }
  };

  return (
    <Formik<{mode?: PaymentOption['value']}>
      initialValues={{}}
      onSubmit={({mode}) => handleOnNext(mode)}
    >
      <Form className="relative">
        <Debbie
          title={`How would you like to pay your ${billerConfig.getAccountTypeTitle(
            accountType
          )}?`}
        />
        <div className="flex flex-col items-center justify-center flex-1 h-full">
          <SetupOptionsRadio />

          <Field>
            {({form}: any) => (
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={!form.values.mode}
              >
                Next
              </button>
            )}
          </Field>
          <button
            type="button"
            onClick={() => {
              goBack();
            }}
            className="mt-6 text-blue-600 transition hover:text-blue-700"
          >
            Back
          </button>
        </div>
      </Form>
    </Formik>
  );
};
