import {defineHTTPEndpoint} from '../utils';
import {notificationSettingsSchema} from '../schemas/notification';

export const updateNotificationSettings = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'PUT /updateNotificationSettings',
  requestSchema: notificationSettingsSchema,
  responseSchema: null,
});

export const getNotificationSettings = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'GET /getNotificationSettings',
  queryKey: () => ['notification-settings'],
  requestSchema: null,
  responseSchema: notificationSettingsSchema,
});
