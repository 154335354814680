import {z} from 'zod';
import {FormConfig} from '../../types';

export const twilio_international = {
  title: 'Twilio International',
  configType: 'global',

  validation: z.object({
    accountSid: z.string().min(1, 'Account SID is required'),
    authToken: z.string().min(1, 'Auth token is required'),
    sender: z.string().min(1, 'Sender is required'),
  }),

  form: [
    {
      type: 'text',
      name: 'accountSid',
      label: 'Account SID',
    },
    {
      type: 'text',
      name: 'authToken',
      label: 'Auth token',
    },
    {
      type: 'text',
      name: 'sender',
      label: 'Sender',
      description:
        'This should be the +61* mobile number to send international SMS from',
    },
  ],
} satisfies FormConfig;
