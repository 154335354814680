import React, {useContext, useEffect} from 'react';

import * as Auth from 'lib/auth/Auth';
import {getBillerSlugFromUrl} from 'lib/url';
import {ConfirmationInstalmentPlan} from '../../setup/components/ConfirmationInstalmentPlan';
import {useParams} from 'react-router';
import {Contact, useGetInstalmentPlanQuery} from 'lib/graphql/API';
import {Loading} from 'components/atoms/Loading';
import {useActor} from '@xstate/react';
import {navigate} from 'lib/navigation/routes';
import {TryAgain} from 'components/molecules/TryAgain';

export const Confirmation: React.FC = () => {
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const slug = getBillerSlugFromUrl();
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);

  useEffect(() => {
    document.title = 'Payble - Payment Plan Confirmation';
  }, []);

  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  const {loading, error, data} = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
    fetchPolicy: 'network-only',
  });

  // Handle loading states
  if (loading) return <Loading />;
  if (error)
    return (
      <TryAgain
        errorMessage={error.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug});
        }}
      />
    );
  if (!data || !data?.instalmentPlan) return <>No payment plan found</>;

  return (
    <ConfirmationInstalmentPlan
      billerSlug={slug}
      instalmentPlanId={instalmentPlanId}
      contact={authState.context.contact as Contact}
    />
  );
};
