import React, {useEffect, useState} from 'react';
import {RadioGroup} from '@headlessui/react';

import {Debbie} from 'components/organisms/Debbie';

import {PaymentMethodType, useGetAuthKeyQuery} from 'lib/graphql/API';
import {getBillerSlugFromUrl} from 'lib/url';

import {TryAgain} from 'components/molecules/TryAgain';
import {TokenExCreditCardForm} from 'components/organisms/TokenExCreditCardForm';
import {DirectDebit} from 'components/organisms/DirectDebit';
import {
  PaymentMethodPicker,
  PaymentType,
} from 'components/organisms/PaymentMethodPicker';
import {useAuthState} from '../hooks/useAuthState';
import {Loading} from 'components/atoms/Loading';
import {useSetupSearchParams} from '../hooks/useSetupSearchParams';
import {goBack, navigate as navigateTo} from 'lib/navigation/routes';
import {useAddPaymentMethodToContactMutation} from '../hooks/useAddPaymentMethodToContact';
import {useSubmitSetupMutation} from '../hooks/useSubmitSetupMutation';
import {useSetupRoute} from '../components/SetupRoute';
import {useSetupNavigate} from '../hooks/useSetupNavigate';
import {DirectDebitNZ} from '../../../components/organisms/DirectDebitNZ';
import {NoticeOnSurcharge} from '../shared/NoticeOnSurcharge';

export const PaymentMethod: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const authState = useAuthState();
  const navigate = useSetupNavigate();
  const {account, setCompletedState} = useSetupRoute();
  const {data: authKey, loading: loadingAuthKey} = useGetAuthKeyQuery();
  const {mode, amountInCents} = useSetupSearchParams();
  const [paymentType, setPaymentType] = useState<PaymentType | undefined>();
  const [completed, setCompleted] = useState(false);
  const {
    add: addPaymentMethod,
    loading: addingPaymentMethod,
    error,
    data: addPaymentMethodResult,
  } = useAddPaymentMethodToContactMutation();

  const {
    pay,
    loading: paying,
    error: errorPaying,
    payment,
    instalmentPlan,
    planRequest,
  } = useSubmitSetupMutation({mode});

  /**
   * Only allow customers to start receiving receipts in this flow when it is
   * their first time on their journey. After that it should only be available
   * in the profile or notification preferences.
   */
  const [showSendReceipts] = useState<boolean>(
    !authState.context.contact?.email
  );

  useEffect(() => {
    if (
      completed &&
      !errorPaying &&
      (payment || instalmentPlan || planRequest)
    ) {
      setCompletedState({
        mode,
        contact: addPaymentMethodResult!.contact,
        paymentMethod: addPaymentMethodResult!.paymentMethod,
        payment,
        instalmentPlan,
        planRequest,
      });
      navigate('/biller/:slug/setup/confirmation');
    }
  }, [
    completed,
    mode,
    addPaymentMethodResult,
    payment,
    instalmentPlan,
    planRequest,
  ]);

  const loading = loadingAuthKey || paying || addingPaymentMethod;

  if (loading) return <Loading />;

  if (!authState.context.biller) {
    return (
      <TryAgain
        errorMessage="No biller"
        onClick={() => {
          navigateTo('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  if (!loadingAuthKey && !authKey) {
    return (
      <TryAgain
        errorMessage="No auth key"
        onClick={() => {
          navigateTo('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );
  }

  const onCardComplete: React.ComponentProps<
    typeof TokenExCreditCardForm
  >['onCardComplete'] = async ({card, contact, sendReceipts}) => {
    const result = await addPaymentMethod({
      paymentMethodType: 'card',
      contact,
      card,
      sendReceipts,
    });

    await pay(amountInCents, {
      account,
      contact: result!.contact,
      paymentMethod: result!.paymentMethod,
    });

    setCompleted(true);
  };

  const onBankComplete: React.ComponentProps<
    typeof DirectDebit
  >['onComplete'] = async ({bank, contact, sendReceipts}) => {
    const result = await addPaymentMethod({
      paymentMethodType: 'direct_debit',
      contact,
      bank,
      sendReceipts,
    });

    await pay(amountInCents, {
      account,
      contact: result!.contact,
      paymentMethod: result!.paymentMethod,
    });

    setCompleted(true);
  };

  const onNZBankComplete: React.ComponentProps<
    typeof DirectDebitNZ
  >['onComplete'] = async ({contact, sendReceipts, nzBank}) => {
    const result = await addPaymentMethod({
      paymentMethodType: 'nz_direct_debit',
      contact,
      sendReceipts,
      accountName: nzBank.accountName,
      accountNumber: nzBank.accountNumber.toJSON(),
    });

    await pay(amountInCents, {
      account,
      contact: result!.contact,
      paymentMethod: result!.paymentMethod,
    });

    setCompleted(true);
  };

  return (
    <div className="relative" data-testid="paymentMethod">
      <Debbie
        title="You're almost there!"
        message="Choose your payment method."
      />

      <div className="relative" id="payment-section">
        <RadioGroup value={paymentType} onChange={setPaymentType}>
          <PaymentMethodPicker
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            billerSlug={billerSlug}
            accountType={account.type}
          />
        </RadioGroup>

        <NoticeOnSurcharge
          paymentMethodType={PaymentMethodType.Card}
          showNotice={!paymentType}
          billerSlug={billerSlug}
        />

        <div
          className={
            paymentType?.type === PaymentMethodType.NzDirectDebit
              ? 'min-w-full'
              : 'hidden'
          }
        >
          <DirectDebitNZ
            onComplete={onNZBankComplete}
            onCompleteError={error || errorPaying}
            contact={authState.context.contact}
            showSendReceipts={showSendReceipts}
            disabled={loading}
          />
        </div>

        <div
          className={
            paymentType?.type === PaymentMethodType.DirectDebit
              ? 'min-w-full'
              : 'hidden'
          }
        >
          <DirectDebit
            onComplete={onBankComplete}
            onCompleteError={error || errorPaying}
            contact={authState.context.contact}
            showSendReceipts={showSendReceipts}
            disabled={loading}
          />
        </div>
        {paymentType?.type === PaymentMethodType.Card ? (
          <div className="min-w-full">
            <TokenExCreditCardForm
              onCardComplete={onCardComplete}
              onCompleteError={error || errorPaying}
              contact={authState.context.contact}
              showSendReceipts={showSendReceipts}
              disabled={loading}
            />
          </div>
        ) : null}
      </div>

      {!paymentType && (
        <button
          onClick={goBack}
          className="w-full mt-6 text-blue-600 transition hover:text-blue-700"
        >
          Back
        </button>
      )}
    </div>
  );
};
