import {z} from 'zod';
import {FormConfig} from '../../types';

export const boomi = {
  title: 'Boomi Integration',
  configType: 'biller',

  validation: z.object({
    apiKey: z.string().min(1),
    enableInfringements: z.boolean().optional(),
    /**
     * https://apigateway.cgd.vic.gov.au/ws/rest/PropertyRating/Infringements
     */
    infringementAPIURL: z.string().url().optional(),
  }),

  form: [
    {
      label: 'API Key',
      name: 'apiKey',
      type: 'text',
    },
    {
      label: 'Enable Infringements',
      name: 'enableInfringements',
      type: 'toggle',
    },
    {
      label: 'Infringement API URL',
      name: 'infringementAPIURL',
      type: 'text',
      placeholder:
        'https://apigateway.cgd.vic.gov.au/ws/rest/PropertyRating/Infringements',
      description:
        'Typically looks like "https://apigateway.cgd.vic.gov.au/ws/rest/PropertyRating/Infringements"',
    },
  ],
} satisfies FormConfig;
