import {classNames} from 'lib/styles';
import React from 'react';

import {
  TOKENEX_CVV_ID,
  TokenExCreditCardFormRef,
  useTokenexIframe,
} from 'lib/tokenex/useTokenexIframe';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {GetAuthKeyQuery} from '../../lib/graphql/API';
import {useGetAuthKey} from 'lib/auth/useGetAuthKey';

export type TokenExCreditCardFormProps = {
  billerSlug: string;
  anonymous?: boolean;
  onBlur?: () => void;
  onValidate?: (data: any) => void;
  onError?: (data: any) => void;
  onCvvBlur?: () => void;
};

export const TokenExCreditCardInternal = React.forwardRef<
  TokenExCreditCardFormRef,
  TokenExCreditCardFormProps & {
    data: Omit<GetAuthKeyQuery['authKey'], '__typename'>;
    refetchAuthKey: () => void;
  }
>((props, ref) => {
  const {onBlur, onCvvBlur, onValidate, onError, data, refetchAuthKey} = props;

  const {loaded, cvvFocused, cvvError, containerRef} = useTokenexIframe({
    data,
    refetchAuthKey,
    onBlur,
    onCvvBlur,
    onValidate,
    onError,
    ref,
  });

  const focusOnCvv = () =>
    (ref as React.RefObject<TokenExCreditCardFormRef>)?.current?.cvvFocus();

  return (
    <div onClick={focusOnCvv}>
      <label htmlFor="card-cvv" className="block text-sm font-bold text-navy">
        CVV
      </label>
      <div
        className={classNames(
          'relative h-[2.35rem] w-full overflow-hidden transition-all duration-300 ease-out pt-1.5 pl-1.5 pr-1.5',
          'border mt-1 shadow-sm text-sm border-gray-300 rounded-md placeholder-gray-400',
          cvvFocused ? 'border-blue-600 ring-1 ring-blue-600' : '',
          cvvError ? 'border-red-600 ring-1 ring-red-600' : ''
        )}
      >
        <div
          id={TOKENEX_CVV_ID}
          ref={containerRef}
          className={classNames(
            'w-full absolute top-[0.45rem] bottom-[0.375rem] px-1 transition-all duration-300 ease-out',
            loaded ? 'opacity-100' : 'opacity-0'
          )}
        ></div>
      </div>
    </div>
  );
});

export const TokenExCreditCardCvv = React.forwardRef<
  TokenExCreditCardFormRef,
  TokenExCreditCardFormProps
>((props, ref) => {
  const {data, loading, error, refetch} = useGetAuthKey({
    anonymous: props.anonymous,
  });

  if (!data || loading) return <Loading />;
  if (error) return <ErrorMessage message={error && error.message} />;

  return (
    <TokenExCreditCardInternal
      {...props}
      data={data}
      refetchAuthKey={refetch}
      ref={ref}
    />
  );
});
