import {FC, ReactNode, useState} from 'react';
import {XCircleIcon} from '@heroicons/react/20/solid';
import {createPortal} from 'react-dom';
import {useEscapeKey} from 'payble-app-shared/src/hooks/useEscapeKey';

export const LinkImageModal: FC<{src: string; children: ReactNode}> = ({
  src,
  children,
}) => {
  const [opened, setOpen] = useState(false);

  useEscapeKey(() => {
    setOpen(false);
  });

  return (
    <>
      <button
        className="text-blue-600 hover:underline"
        onClick={() => {
          setOpen(!opened);
        }}
      >
        {children}
      </button>
      {opened &&
        createPortal(
          <div
            className="fixed top-0 left-0 z-40 flex items-center justify-center w-full h-full text-center bg-black bg-opacity-75"
            onClick={() => {
              setOpen(false);
            }}
          >
            <button
              className="absolute w-10 text-white top-2 right-2"
              onClick={e => {
                e.stopPropagation();
                setOpen(false);
              }}
            >
              <XCircleIcon />
            </button>
            <a
              href={src}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <img src={src} className="max-w-full m-auto" />
            </a>
          </div>,
          document.body
        )}
    </>
  );
};
