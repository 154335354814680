export function getConfig() {
  let appURL: string;
  if (window.location.hostname === 'localhost') {
    appURL = `https://${window.location.hostname}:${window.location.port}`;
  } else {
    appURL = 'https://' + window.location.hostname;
  }
  const apiURL = appURL + '/api';

  return {
    apiUrl: apiURL,
    publicApiUrl: `${apiURL}/public`,
    authUrl: appURL,
  };
}
