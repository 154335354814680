import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {getBillerSlugFromUrl} from 'lib/url';
import {
  InstalmentPlanMode,
  useCancelInstalmentPlanMutation,
  useGetInstalmentPlanQuery,
} from 'lib/graphql/API';
import {Loading} from 'components/atoms/Loading';
import {navigate} from 'lib/navigation/routes';
import {HomeIcon} from '@heroicons/react/20/solid';
import {Debbie} from 'components/organisms/Debbie';

import {TryAgain} from 'components/molecules/TryAgain';
import {CancelConfirmation} from '../components/CancelConfirmation';
import {CancelIncorrectBalance} from '../components/CancelIncorrectBalance';
import {CancelChangePaymentMethod} from '../components/CancelChangePaymentMethod';
import {CancelOptions} from '../components/CancelOptions';
import {CancelChangePaymentSchedule} from '../components/CancelChangePaymentScheduleInterim';

export enum Options {
  PaymentSchedule = 'I want to change my payment schedule',
  PaymentMethod = 'I want to change my payment method',
  IncorrectBalance = "The balance doesn't look correct to me",
  OwnershipChange = 'I have sold my property',
  Other = 'Other',
  NoneSelected = '',
}

export const Cancel: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const [reason, setReason] = React.useState('');
  const [selected, setSelected] = useState<Options>(Options.NoneSelected);
  // setReason only happens on clicking next - check CancelOptions
  // const showNext = reason !== '' && selected !== Options.NoneSelected;
  const [showNext, setShowNext] = useState<boolean>(false);
  // Only use by payment schedule and method pages as they click continue to cancel,
  // for other paths confirmation is the fall back component as the Cancel page re-renders with new state with showNext as true
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Payble - Cancel Next Payment';
  }, []);

  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  const {loading, error, data} = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
  });

  const [cancel, {loading: cancelLoading, error: cancelError}] =
    useCancelInstalmentPlanMutation({
      variables: {input: {instalmentPlanId, reason}},
    });

  // Handle loading states
  if (loading || !data?.instalmentPlan) return <Loading />;
  if (error)
    return (
      <TryAgain
        errorMessage={error.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );

  const canCancel =
    data?.instalmentPlan?.status !== 'cancelled' &&
    data?.instalmentPlan?.status !== 'completed' &&
    data?.instalmentPlan?.status !== 'processing';

  const onCancel = async () => {
    const result = await cancel({
      variables: {input: {instalmentPlanId, reason}},
    });
    if (
      !result.errors &&
      result.data?.cancelInstalmentPlan?.status === 'cancelled'
    ) {
      navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
        slug: billerSlug,
        instalmentPlanId,
      });
    }
  };

  if (!canCancel) {
    navigate(
      '/biller/:slug/instalment-plan/:instalmentPlanId',
      {
        slug: billerSlug,
        instalmentPlanId,
      },
      true
    );
  }

  return (
    <div className="relative">
      <Debbie
        title="Cancel your payment plan"
        message="You can cancel your payment plan using the steps below."
      />

      <nav className="flex mt-5 mb-2" aria-label="Breadcrumb">
        <ol
          role="list"
          className="flex px-6 space-x-4 bg-white rounded-md shadow"
        >
          <li className="flex">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
                className="text-gray-400 hover:text-gray-500"
              >
                <HomeIcon
                  className="flex-shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </button>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <button
                onClick={() =>
                  navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
                    slug: billerSlug,
                    instalmentPlanId,
                  })
                }
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Payment Plan
              </button>
            </div>
          </li>
        </ol>
      </nav>

      <div className="mt-5 overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="flex flex-col">
            {showConfirmation ? (
              <CancelConfirmation
                instalmentPlan={data.instalmentPlan}
                cancelError={cancelError}
                cancelLoading={cancelLoading}
                billerSlug={billerSlug}
                instalmentPlanId={instalmentPlanId}
                canCancel={canCancel}
                onCancel={onCancel}
              />
            ) : (
              <>
                {showNext ? (
                  <>
                    {selected === Options.PaymentSchedule &&
                    data.instalmentPlan.mode !== InstalmentPlanMode.PayXOnY &&
                    data.instalmentPlan.mode !== InstalmentPlanMode.PayOnY ? (
                      <CancelChangePaymentSchedule
                        billerSlug={billerSlug}
                        instalmentPlanId={instalmentPlanId}
                        setShowConfirmation={setShowConfirmation}
                      />
                    ) : selected === Options.PaymentMethod ? (
                      <CancelChangePaymentMethod
                        billerSlug={billerSlug}
                        instalmentPlanId={instalmentPlanId}
                        setShowConfirmation={setShowConfirmation}
                      />
                    ) : selected === Options.IncorrectBalance ? (
                      <CancelIncorrectBalance
                        instalmentPlan={data.instalmentPlan}
                      />
                    ) : (
                      <CancelConfirmation
                        instalmentPlan={data.instalmentPlan}
                        cancelError={cancelError}
                        cancelLoading={cancelLoading}
                        billerSlug={billerSlug}
                        instalmentPlanId={instalmentPlanId}
                        canCancel={canCancel}
                        onCancel={onCancel}
                      />
                    )}
                  </>
                ) : (
                  <CancelOptions
                    instalmentPlan={data.instalmentPlan}
                    selected={selected}
                    setSelected={setSelected}
                    reason={reason}
                    setReason={setReason}
                    setShowNext={setShowNext}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
