import React from 'react';
import {DateTime} from 'luxon';
import {getBillerSlugFromUrl} from 'lib/url';

export const Footer: React.FC = () => {
  const slug = getBillerSlugFromUrl();
  const year = DateTime.local().year;

  return (
    <footer
      className="bg-white shadow-lg mt-4 border-t border bottom-0 z-10"
      style={{
        paddingBottom: 'env(safe-area-inset-bottom)',
      }}
    >
      <div className="max-w-7xl mx-auto px-2 sm:px-6 flex items-center justify-between lg:px-8 flex-row-reverse">
        <a
          href="https://www.payble.com.au"
          className="flex flex-col justify-center items-center sm:px-2 py-2 sm:ml-5 md:ml-0"
        >
          <div>
            <p className="leading-3 font-medium text-xs text-gray-600">
              Powered by
            </p>
          </div>
          <img className="h-7 mr-2 mt-3" src="/cms/payble-logo.png" />
        </a>
      </div>
      <div className="flex w-full justify-center pb-2 max-w-screen-md mx-auto">
        {slug === 'city-of-kingston' && (
          <>
            <p className="text-center text-xs text-gray-400 px-4">
              Information you submit is collected for the purpose of paying your
              rates and is subject to Payble's{' '}
              <a
                href="https://payble.com.au/terms-privacy/"
                target="_blank"
                className="text-blue-400 underline"
              >
                Terms and Privacy
              </a>
              , in collaboration with City of Kingston. Information you submit
              to Kingston is subject to their
              <a
                href="https://www.kingston.vic.gov.au/council/council-documents/plans-policies-and-reports/information-privacy"
                target="_blank"
                className="text-blue-400 underline"
              >
                {' '}
                Information Privacy Policy
              </a>
              .
            </p>
          </>
        )}
        {slug !== 'city-of-kingston' && (
          <a href="https://payble.com.au/terms-privacy/" target="_blank">
            <p className="text-xs text-center text-gray-500 underline">
              Terms | Privacy
            </p>
          </a>
        )}
      </div>
      <div className=" text-center text-xs text-gray-400 ">
        Payble &copy;{year} All Rights Reserved
      </div>
    </footer>
  );
};
