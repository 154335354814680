/**
 * @deprecated Just throw an exception
 */
export type Result<T> = T | Error;

/**
 * @deprecated Use errs.UnexpectedError.wrap(e)
 */
export function err(x: unknown): x is Error {
  return x instanceof Error;
}
/**
 * @deprecated Use errs.UnexpectedError.wrap(e)
 */
export function catcher(e: unknown): Error {
  if (e instanceof Error) {
    return e;
  } else {
    return new Error(
      `Caught error which was not instance of Error ${JSON.stringify(e)}`
    );
  }
}
