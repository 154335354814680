import React from 'react';
import {goBack} from 'lib/navigation/routes';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {useAnonymousPaymentNavigate} from '../hooks/useAnonymousPaymentNavigate';
import {PaymentAmountForm} from 'features/setup/components/PaymentAmountForm';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {Loading} from 'components/atoms/Loading';

export const PaymentAmountPage = () => {
  const {loading: loadingAccount, data: account} =
    useGetAccountFromSearchParams({anonymous: true});

  const navigate = useAnonymousPaymentNavigate();

  const billerConfig = useBillerConfig();

  if (loadingAccount || !account) {
    return <Loading />;
  }
  const paymentConfig = billerConfig.getAccountPaymentConfig({
    ...account,
    setupBreakdown: account.setupBreakdown ?? [],
    nextInstalmentAmount: account.targetInstalments?.[0]?.amount,
  });

  const {minAmount, maxAmount, defaultAmount, lockedAmount} = paymentConfig;

  return (
    <div className="my-8">
      <PaymentAmountForm
        debbieTitle={
          lockedAmount
            ? 'Please confirm the payment amount'
            : 'How much do you want to pay now?'
        }
        heading="Pay Now"
        description={`${lockedAmount ? 'Confirm the amount' : 'Enter an amount'} to pay now`}
        minAmount={minAmount}
        maxAmount={maxAmount}
        lockedAmount={lockedAmount}
        defaultAmount={defaultAmount}
        onSubmit={values => {
          navigate('/pay', {amountInCents: values.amountInCents.toString()});
        }}
        onClickBack={goBack}
      />
    </div>
  );
};
