import React, {ReactNode} from 'react';
import {useInterpret} from '@xstate/react';
import {authMachine, MachineInterpreter} from './machine';

interface AuthContext {
  authService: MachineInterpreter;
}

export const Context = React.createContext<AuthContext>({} as AuthContext);

export const Provider: React.FC<{children: ReactNode}> = ({children}) => {
  const authService = useInterpret(authMachine);

  return <Context.Provider value={{authService}}>{children}</Context.Provider>;
};
