import {DateTime, Settings} from 'luxon';

export class MockDate {
  private static originalNow = Settings.now;

  static mockTimezone(timeZone: string) {
    Settings.defaultZone = timeZone;
  }

  static resetMockTimezone() {
    Settings.defaultZone = 'utc';
  }

  static mockDateTime(date: DateTime) {
    Settings.now = () => date.toJSDate().getTime();
  }

  static resetDateTime() {
    Settings.now = MockDate.originalNow;
  }
}
