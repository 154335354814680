import React, {useEffect} from 'react';
import {classNames} from 'lib/styles';
import {RadioGroup} from '@headlessui/react';
import {PaymentMethodType} from 'lib/graphql/API';

import {CheckCircleIcon} from '@heroicons/react/20/solid';

import cardIcon from 'components/atoms/card.png';
import bankIcon from 'components/atoms/bank.png';
import {CARD_IMAGE} from 'payble-shared';
import {toPaymentMethodType} from '../../lib/types/DirectDebit';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';

export type PaymentType = {
  name: string;
  type: PaymentMethodType;
  icon: string;
};

export const PAYMENT_METHODS = [
  {
    id: 'credit-card-method',
    name: 'Credit/Debit Card',
    type: PaymentMethodType.Card,
    icon: cardIcon,
  },
  {
    id: 'au-bank-account-method',
    name: 'Bank Account',
    type: PaymentMethodType.DirectDebit,
    icon: bankIcon,
  },
  {
    id: 'nz-bank-account-method',
    name: 'Bank Account',
    type: PaymentMethodType.NzDirectDebit,
    icon: bankIcon,
  },
];

type PaymentMethodPickerProps = {
  setPaymentType: (paymentMethod: PaymentType) => void;
  billerSlug: string;
  paymentType?: PaymentType;
  accountType?: string;
};

const usePaymentMethods = (accountType?: string) => {
  const billerConfig = useBillerConfig();
  return PAYMENT_METHODS.filter(({type}) =>
    (accountType
      ? billerConfig.paymentMethodsForAccountType(accountType)
      : billerConfig.paymentMethods
    ).includes(toPaymentMethodType(type))
  );
};

export const PaymentMethodPicker: React.FC<PaymentMethodPickerProps> = ({
  setPaymentType,
  paymentType,
  accountType,
}) => {
  const billerConfig = useBillerConfig();
  const paymentMethods = usePaymentMethods(accountType);

  useEffect(() => {
    if (!paymentType && paymentMethods.length === 1) {
      setPaymentType(paymentMethods[0]);
    }
  }, [paymentMethods]);

  return (
    <RadioGroup value={paymentType} onChange={setPaymentType}>
      <div className="flex flex-col w-full pb-4 mt-8">
        {paymentMethods.map(method => (
          <RadioGroup.Option
            data-testid={method.id}
            key={method.type.toString()}
            value={method}
            className={({checked, active}) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-blue-500' : '',
                'relative mb-4 bg-white border rounded-lg shadow-sm p-4 flex items-center cursor-pointer focus:outline-none'
              )
            }
          >
            {({checked, active}) => (
              <>
                <div className="flex flex-1">
                  <div className="flex flex-1">
                    <div className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {method.name}
                      </RadioGroup.Label>
                    </div>
                  </div>
                </div>
                {method.type === PaymentMethodType.Card && (
                  <div className="flex gap-1 mr-2">
                    {billerConfig.acceptedCardTypes.map(cardType => (
                      <img
                        key={cardType}
                        src={CARD_IMAGE[cardType]}
                        className={classNames(
                          'h-6',
                          checked ? 'opacity-100' : 'opacity-50'
                        )}
                      />
                    ))}
                  </div>
                )}
                {[
                  PaymentMethodType.DirectDebit,
                  PaymentMethodType.NzDirectDebit,
                ].includes(method.type) && (
                  <div className="flex gap-1 mr-2">
                    <img
                      src="/cms/app-shared/bank/generic.svg"
                      className={classNames(
                        'h-6',
                        checked ? 'opacity-100' : 'opacity-50'
                      )}
                    />
                  </div>
                )}
                <CheckCircleIcon
                  className={classNames(
                    !checked ? 'invisible' : '',
                    'h-5 w-5 text-blue-600'
                  )}
                  aria-hidden="true"
                />
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
