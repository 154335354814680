import {ApolloClient, InMemoryCache, HttpLink, from} from '@apollo/client';
import {RetryLink} from '@apollo/client/link/retry';
import {setContext} from '@apollo/client/link/context';
import {ErrorLink, onError} from '@apollo/client/link/error';
import {fetch} from 'cross-fetch';
import {clear, loadCredentials} from '../auth/token';
import {getConfig} from 'lib/config/environment';
import * as Sentry from '@sentry/react';
import {getBillerSlugFromUrl} from 'lib/url';
import {captureException} from '@sentry/react';
import {scalarTypePolicies} from './API';
import {scalarTypePolicies as publicScalarTypePolicies} from './publicAPI'

const url = getConfig().apiUrl;
const publicUrl = getConfig().publicApiUrl;
const authLink = setContext(async (_, {headers}) => {
  const credentials = loadCredentials();
  return {
    headers: {
      ...headers,
      authorization: credentials ? `Bearer ${credentials.id_token}` : '',
    },
  };
});

const errorLink = onError(errorResponse => {
  const {graphQLErrors, networkError, operation} = errorResponse;

  // If the API returns an UNAUTHORIZED error, clear the token and redirect to the login page

  // A note from past James to future Payble people.
  // Using title is yuk 🤮 I am really sorry about this, please find a better way.
  if (
    graphQLErrors?.some(
      e => e.extensions?.code === 'UNAUTHENTICATED' // ||
      // e.message === 'The security token included in the request is expired'
    ) &&
    document.title !== 'Payble - Login'
  ) {
    clear();
    const slug = getBillerSlugFromUrl();
    window.location.href = `/biller/${slug}#session-expired`;
    return;
  }

  reportToSentry(errorResponse);
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: scalarTypePolicies
  }),
  link: from([
    authLink,
    errorLink,
    new RetryLink({}),
    new HttpLink({
      fetch,
      uri: url,
    }),
  ]),
});

const publicErrorLink = onError(errorResponse => {
  reportToSentry(errorResponse);
});

export const publicClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: publicScalarTypePolicies
  }),
  link: from([
    publicErrorLink,
    new RetryLink({}),
    new HttpLink({
      fetch,
      uri: publicUrl,
    }),
  ]),
});

const reportToSentry: ErrorLink.ErrorHandler = ({
  graphQLErrors,
  networkError,
  operation,
}) => {
  Sentry.withScope(scope => {
    // Annotate whether failing operation was query/mutation/subscription
    scope.setTag('operation', operation.operationName);
    scope.setExtra('variables', operation.variables);

    graphQLErrors?.forEach(err => captureException(err));
    if (networkError) {
      captureException(networkError);
    }

    let errorMessage = '';
    if (graphQLErrors) {
      errorMessage = graphQLErrors
        .map(
          ({message, path}) =>
            `[GraphQL error]: Message: ${message}, Path: ${path}`
        )
        .join(', ');
    }

    if (networkError) {
      errorMessage = `[Network error]: ${networkError}`;
    }

    if (errorMessage) {
      console.error(errorMessage);
    }
  });
};
