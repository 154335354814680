import {VariantProps, cn, cva} from '../lib/utils';
import React, {FC, HTMLAttributes} from 'react';

const textVariants = cva('', {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-md',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
    },
    variant: {
      default: 'text-foreground-400',
      heading: 'text-foreground font-bold',
      body: 'text-foreground-400',
      muted: 'text-foreground-300',
      danger: 'text-destructive',
    },
    weight: {
      light: 'font-light',
      regular: 'font-regular',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
    },
  },
  defaultVariants: {
    size: 'sm',
    weight: 'regular',
    variant: 'default',
  },
});

export interface TextProps
  extends VariantProps<typeof textVariants>,
    HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

export const Text: FC<TextProps> = ({children, className, ...props}) => {
  return (
    <div className={cn(textVariants(props), className)} {...props}>
      {children}
    </div>
  );
};
