import React from 'react';

type NoPlanOptionsProps = {};

export const NoPlanOptions: React.FC<NoPlanOptionsProps> = () => {
  return (
    <div>
      <h4 className="font-semibold text-center">No options available</h4>

      <p className="text-center my-4">
        We couldn't calculate any options starting that day.
      </p>
    </div>
  );
};
