import {APIClient, ConsumerAPIClient, consumerAPISpec} from 'payble-api-client';
import React, {ReactNode, useContext} from 'react';
import {loadCredentials} from './auth/token';
import {makeAPIHooks} from 'payble-api-react-query';

interface APIContext {
  api: ConsumerAPIClient;
}

const initialAuth = loadCredentials();

export const api = new APIClient({
  definitions: consumerAPISpec,
  baseURL: '/api/v2',
  defaultAuth: initialAuth
    ? {
        token: initialAuth.id_token,
        type: 'consumer',
      }
    : undefined,
});

export const Context = React.createContext<APIContext>({
  api,
});

export const Provider: React.FC<{children: ReactNode}> = ({children}) => {
  return <Context.Provider value={{api}}> {children} </Context.Provider>;
};

export const useAPI = () => {
  return useContext(Context);
};

const {useAPIInvalidate, useAPIMutation, useAPIQuery} = makeAPIHooks(
  consumerAPISpec,
  useAPI
);

export {useAPIInvalidate, useAPIMutation, useAPIQuery};
