import {
  Props,
  StyledRadioGroup,
} from 'features/setup/components/StyledRadioGroup';
import {useField, useFormikContext} from 'formik';
import React from 'react';

export function RadioGroupField({
  name,
  options,
}: {
  name: string;
  options: Props['options'];
}) {
  const {submitForm, setFieldValue} = useFormikContext();

  const [field] = useField(name);

  return (
    <>
      {/*Due to the behavior of the Headless UI radio group component,
        which does not trigger form submission unlike native radio inputs,
        we handle form submission manually in this section.*/}
      <div className="w-full" onKeyUp={e => e.key === 'Enter' && submitForm()}>
        <StyledRadioGroup
          value={field.value}
          options={options}
          onChange={v => {
            setFieldValue(name, v);
          }}
        />
      </div>
    </>
  );
}
