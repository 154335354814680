import {z} from 'zod';
import {FormConfig} from '../../types';

export const pathway = {
  title: 'Pathway',
  configType: 'biller',

  validation: z.object({
    apiKey: z.string().min(1, 'API Key is required'),
  }),

  form: [
    {
      type: 'text',
      name: 'apiKey',
      label: 'API Key',
    },
  ],
} satisfies FormConfig;
