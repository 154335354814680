import * as Sentry from '@sentry/react';
import {AxiosError} from 'axios';
import jwtDecode from 'jwt-decode';
import {getBillerSlugFromUrl} from 'lib/url';
import storage from 'local-storage-fallback';
import {errs} from 'payble-shared';

import {captureException} from '@sentry/react';
import {api} from '../api';
import {useBillerConfig} from '../appConfig/useBillerConfig';
import {
  Biller,
  Contact,
  GetBillerDocument,
  GetBillerQuery,
  GetSessionDocument,
  GetSessionQuery,
} from '../graphql/API';
import {client} from '../graphql/client';

// Using Native app to avoid redirect

const JWT_KEY = 'jwt_auth';

function getError(error: unknown): Error {
  // Attempt to extract error message from AxiosError response data
  const errorMessageFromResponse = (error as AxiosError<{message: string}>)
    ?.response?.data?.message;

  // Attempt to extract error message from generic AxiosError
  const errorMessageFromAxiosError = (error as AxiosError)?.message;

  return new Error(
    errorMessageFromResponse ||
      errorMessageFromAxiosError ||
      'Error while sending SMS'
  );
}

export const logout = (slug: string) => {
  return api
    ?.request('logout', {})
    .tap(() => {}, captureException)
    .tapEither(() => {
      clear();
      window.location.href = `/biller/${slug}/login#logged-out`;
    });
};

export const useAuth = () => {
  const billerConfig = useBillerConfig();

  return {
    logout: () => logout(billerConfig.billerSlug),
  };
};

export async function sendSMSCode(mobileNumber: string) {
  const billerSlug = getBillerSlugFromUrl();

  try {
    const response = await api.request('loginStart', {
      data: {
        mobileNumber,
        billerSlug,
      },
    });
    return response.get();
  } catch (error) {
    console.error(error);
    throw getError(error);
  }
}

type Credentials = {
  id_token: string;
};

export async function confirmCode(
  phoneNumber: string,
  verificationCode: string
) {
  const billerSlug = getBillerSlugFromUrl();
  try {
    const response = await api.request('loginComplete', {
      data: {
        mobileNumber: phoneNumber,
        billerSlug,
        verificationCode,
      },
    });
    const credentials = response.get();

    storeCredentials(credentials);

    api.setAuth({
      type: 'consumer',
      token: credentials.id_token,
    });

    return credentials;
  } catch (error) {
    console.error(error);
    throw getError(error);
  }
}

type Token = {
  exp: number;
  mobileNumber: string;
  billerId: string;
  contactId: string;
};

export async function validate() {
  const credentials = loadCredentials();

  if (!credentials) {
    throw new Error('Token does not exist');
  }

  const token: Token = jwtDecode(credentials.id_token);
  const hasExpired = !token || token.exp * 1000 < Date.now();

  if (hasExpired) {
    throw new Error('Token has expired');
  }
}

export function storeCredentials(credentials: Credentials) {
  storage.setItem(JWT_KEY, JSON.stringify(credentials));
}

export function loadCredentials() {
  const credentials = storage.getItem(JWT_KEY);

  if (!credentials) {
    return null;
  }

  try {
    const parsed: Credentials = JSON.parse(credentials);
    return parsed;
  } catch (error) {
    console.error('Unable to parse JWT');
    Sentry.captureException(error);
    clear();
    return null;
  }
}

export function clear() {
  storage.removeItem(JWT_KEY);
}

export async function getBiller(): Promise<
  Biller | errs.UnexpectedError | errs.NotFoundError
> {
  try {
    const result = await client.query<GetBillerQuery>({
      query: GetBillerDocument,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      const errors = errs.fromGraphQL({graphQLErrors: result.errors});
      return errors.find(errs.NotFoundError) ?? errors.first();
    }

    if (!result?.data?.biller) {
      return errs.NotFoundError.create('Biller not found');
    }

    return result.data.biller as Biller;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return errs.UnexpectedError.wrap(error, 'Network error');
  }
}

export async function getSessionContact(): Promise<
  Contact | errs.UnexpectedError | errs.NotFoundError
> {
  try {
    const result = await client.query<GetSessionQuery>({
      query: GetSessionDocument,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      const errors = errs.fromGraphQL({graphQLErrors: result.errors});
      return errors.find(errs.NotFoundError) ?? errors.first();
    }

    if (!result?.data?.contact) {
      return errs.NotFoundError.create('Contact not found');
    }

    return result.data.contact as Contact;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return errs.UnexpectedError.wrap(error, 'Network error');
  }
}
