import {useEffect, useRef} from 'react';

export function useBrowserBack(callback: () => void) {
  const addedCallback = useRef<() => void>();

  useEffect(() => {
    // If don't do anything and rerenders, cancel the previous listener
    if (addedCallback.current) {
      window.removeEventListener('popstate', addedCallback.current);
    }

    // Add new listener to callback
    const callbackOnce = () => {
      callback();
      window.removeEventListener('popstate', callback);
    };

    window.addEventListener('popstate', callbackOnce);

    // Save the listener to remove it later
    addedCallback.current = callbackOnce;

    return () => {
      // Do not remove the listener immediately when the component is unmounting
      // As the popstate happens after unmount is completed
      // do it at the next tick of the event loop
      requestAnimationFrame(() => {
        window.removeEventListener('popstate', callbackOnce);
      });
    };
  }, [addedCallback, callback]);

  return null;
}
