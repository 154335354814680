import {generatePath, useNavigate, useSearchParams} from 'react-router-dom';
import {useCallback} from 'react';
import {getBillerSlugFromUrl} from 'lib/url';

type NavigateSearchParams = Record<string, string>;

export function useNavigateWithCurrentParams<
  T extends NavigateSearchParams,
  P extends NavigateSearchParams = {slug: string},
>(defaultPathParams?: P, defaultSearchParams?: T) {
  const [currentSearchParams, setCurrentSearchParams] = useSearchParams();
  const navigate = useNavigate();

  if (defaultSearchParams) {
    const nextSearchParams = updateCurrentSearchParams(
      currentSearchParams,
      defaultSearchParams
    );
    setCurrentSearchParams(nextSearchParams);
  }

  return useCallback(
    (path: string, searchParams?: T, pathParams?: P, replace?: boolean) => {
      const initialPathParams = {slug: getBillerSlugFromUrl()};
      const nextSearchParams = updateCurrentSearchParams(
        currentSearchParams,
        searchParams
      );

      navigate(
        {
          pathname: generatePath(path, {
            ...initialPathParams,
            ...defaultPathParams,
            ...pathParams,
          }),
          search: nextSearchParams.toString(),
        },
        {
          replace,
        }
      );
    },
    [currentSearchParams, defaultPathParams, defaultSearchParams]
  );
}

function updateCurrentSearchParams(
  previousParams: URLSearchParams | Record<string, string>,
  searchParams?: URLSearchParams | Record<string, string>
) {
  const nextSearchParams = new URLSearchParams(previousParams);
  if (!searchParams) return nextSearchParams;

  for (const [key, value] of Object.entries(searchParams)) {
    if (value === '' || value === null || value === undefined) {
      nextSearchParams.delete(key);
    } else {
      nextSearchParams.set(key, value);
    }
  }
  return nextSearchParams;
}
