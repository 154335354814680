import React, {useEffect, useState} from 'react';
import {DirectDebitForm} from './DirectDebitForm';
import {validateDirectDebit} from 'lib/validation';
import {
  err,
  validateEmail,
  validateGivenName,
  validateFamilyName,
  normaliseAccountName,
  ACCOUNT_NAME_MAX_LENGTH,
} from 'payble-shared';
import {DirectDebitValues} from 'lib/types/DirectDebit';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {classNames} from 'lib/styles';
import {XCircleIcon} from '@heroicons/react/24/solid';
import {PaymentMethodAgreement} from 'components/organisms/PaymentMethodAgreement';
import {goBack} from 'lib/navigation/routes';
import {ApplyPaymentMethodToPlans} from './ApplyPaymentMethodToPlans';
import {PaymentMethodType} from '../../lib/graphql/API';
import {StyledCheckbox} from 'features/setup/components/StyledCheckbox';

type ContactDetails = {
  title: string;
  givenName: string;
  familyName: string;
  email: string;
};

type OnCompleteValues = {
  bank: {
    accountName: string;
    accountNumber: string;
    bsb: string;
  };
  contact: ContactDetails;
  usePaymentMethodForPlans?: string[];
  sendReceipts?: boolean;
};

type DirectDebitProps = {
  mode?: 'add';
  disabled: boolean;
  onComplete: (args: OnCompleteValues) => Promise<void>;
  onCompleteError?: string;
  contact?: ContactDetails;
  showSendReceipts?: boolean;
};

type State = 'input' | 'requesting' | 'confirmed';

type ErrorMessage = string | undefined;

export const DirectDebit: React.FC<DirectDebitProps> = ({
  disabled,
  onComplete,
  onCompleteError,
  contact,
  mode,
  showSendReceipts = false,
}) => {
  const [state, setState] = useState<State>('input');
  const [error, setError] = useState<ErrorMessage>();
  const [usePaymentMethodForPlans, setUsePaymentMethodForPlans] = useState<
    string[]
  >([]);
  const [title, __setTitle] = useState(contact ? contact.title : '');
  const [givenName, setGivenName] = useState(contact ? contact.givenName : '');
  const [familyName, setFamilyName] = useState(
    contact ? contact.familyName : ''
  );
  const [email, setEmail] = useState(contact ? contact.email : '');

  const [givenNameError, setGivenNameError] = useState<ErrorMessage>();
  const [familyNameError, setFamilyNameError] = useState<ErrorMessage>();
  const [emailError, setEmailError] = useState<ErrorMessage>();
  const [sendReceipts, setSendReceipts] = useState<boolean | undefined>();

  const [directDebit, setDirectDebit] = useState<DirectDebitValues>({
    accountName: contact
      ? normaliseAccountName(
          `${contact.givenName} ${contact.familyName}`
        ).slice(0, ACCOUNT_NAME_MAX_LENGTH)
      : '',
    accountNumber: '',
    bsbNumber: '',
    bank: null,
  });
  const [directDebitValid, setDirectDebitValid] = useState(false);

  const [hasAgreed, setHasAgreed] = useState(false);
  const canRequest =
    hasAgreed &&
    validateDirectDebit(directDebit) &&
    givenName !== '' &&
    !givenNameError &&
    familyName !== '' &&
    !familyNameError &&
    (contact?.email ? true : email !== '' && !emailError) &&
    directDebitValid;

  useEffect(() => {
    setError(onCompleteError);
  }, [onCompleteError]);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = validateEmail(e.target.value);
    if (!err(email)) {
      setEmail(e.target.value);
      setEmailError(undefined);
    } else {
      setEmailError(email.message);
    }
  };

  const onGivenNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const givenName = validateGivenName(e.target.value);
    if (!err(givenName)) {
      setGivenName(e.target.value);
      setGivenNameError(undefined);
    } else {
      setGivenNameError(givenName.message);
    }
  };

  const onFamilyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const familyName = validateFamilyName(e.target.value);
    if (!err(familyName)) {
      setFamilyName(e.target.value);
      setFamilyNameError(undefined);
    } else {
      setFamilyNameError(familyName.message);
    }
  };

  const request = async () => {
    // Ad hoc form validation for time being - new implementations should use Formik
    if (!canRequest) {
      console.warn('Cannot request token while form is invalid');
      return;
    }

    try {
      setState('requesting');
      setError(undefined);

      // Call function from parent
      await onComplete({
        bank: {
          accountName: directDebit.accountName,
          accountNumber: directDebit.accountNumber,
          bsb: directDebit.bsbNumber,
        },
        contact: {
          title,
          givenName,
          familyName,
          email,
        },
        sendReceipts,
        usePaymentMethodForPlans,
      });
      setState('confirmed');
    } catch (e) {
      console.error('Error completing bank details', e);
      setError((e as Error).message);
      setState('input');
    }
  };

  return (
    <div className="mt-2 md:col-span-2" id="direct-debit-form">
      <div className="md:mt-0 md:col-span-2">
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <h2 className="col-span-6 text-lg font-bold text-navy">
                Direct Debit Request
              </h2>
              {(!contact?.givenName || !contact?.familyName) && (
                <>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="givenNameLabel"
                      className="block text-sm font-bold text-navy"
                    >
                      Given name
                    </label>
                    <input
                      type="text"
                      name="givenName"
                      id="givenName"
                      autoComplete="given-name"
                      disabled={disabled}
                      defaultValue={givenName}
                      onChange={onGivenNameChange}
                      className={classNames(
                        'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
                        !givenNameError ? '' : 'border-red-600'
                      )}
                    />
                    {givenNameError && (
                      <span className="mt-1 text-sm text-red-600">
                        {givenNameError}
                      </span>
                    )}
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="familyName"
                      className="block text-sm font-bold text-navy"
                    >
                      Family name
                    </label>
                    <input
                      type="text"
                      name="familyName"
                      id="familyName"
                      autoComplete="family-name"
                      disabled={disabled}
                      defaultValue={familyName}
                      onChange={onFamilyNameChange}
                      className={classNames(
                        'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
                        !familyNameError ? '' : 'border-red-600'
                      )}
                    />
                    {familyNameError && (
                      <span className="mt-1 text-sm text-red-600">
                        {familyNameError}
                      </span>
                    )}
                  </div>
                </>
              )}

              {!contact?.email && (
                <div className="col-span-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-bold text-navy"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    data-testid="email"
                    disabled={disabled}
                    defaultValue={email}
                    onChange={onEmailChange}
                    className={classNames(
                      'mt-1 focus:ring-blue-600 focus:border-blue-600 placeholder-gray-300 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
                      !emailError ? '' : 'border-red-600'
                    )}
                  />
                  {emailError && (
                    <span className="mt-1 text-sm text-red-600">
                      {emailError}
                    </span>
                  )}
                </div>
              )}
              <DirectDebitForm
                value={directDebit}
                onChange={setDirectDebit}
                onValid={setDirectDebitValid}
                disabled={state === 'requesting'}
              />

              {mode === 'add' && (
                <ApplyPaymentMethodToPlans
                  onChange={setUsePaymentMethodForPlans}
                />
              )}

              {showSendReceipts && (
                <div className="w-full col-span-6">
                  <StyledCheckbox
                    checked={!!sendReceipts}
                    name="email-payment-receipts"
                    id="email-payment-receipts"
                    onClick={() => setSendReceipts(!sendReceipts)}
                  >
                    Send payment receipts to my email
                  </StyledCheckbox>
                </div>
              )}

              <hr />
              <PaymentMethodAgreement
                paymentMethodType={PaymentMethodType.DirectDebit}
                callback={value => setHasAgreed(value)}
              />
            </div>
          </div>
          {error ? (
            <div className="px-4 py-3 -mt-6 bg-white sm:px-6">
              <div className="p-4 rounded-md bg-red-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="w-5 h-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3
                      className="text-sm font-medium text-red-800"
                      id="error-message-direct-debit"
                    >
                      {error}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <button
        type="submit"
        disabled={!canRequest || disabled}
        onClick={request}
        data-testid="acknowledgeAndContinue"
        className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        {state !== 'input' || disabled ? <ButtonSpinner /> : null}
        Acknowledge and continue
      </button>
      <button
        onClick={goBack}
        disabled={state === 'requesting'}
        className="w-full mt-6 text-blue-600 transition hover:text-blue-700"
      >
        Back
      </button>
    </div>
  );
};
