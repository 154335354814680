import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';

export const loginStart = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'POST /auth/start',
  requestSchema: z.object({
    mobileNumber: z.string().min(1).trim(),
    billerSlug: z.string().min(1).trim(),
  }),
  responseSchema: z.object({
    formattedPhoneNumber: z.string(),
  }),
});

export const loginComplete = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'POST /auth/complete',
  requestSchema: z.object({
    mobileNumber: z.string().min(1).trim(),
    billerSlug: z.string().min(1).trim(),
    verificationCode: z.string().length(6).trim(),
  }),
  responseSchema: z.object({
    id_token: z.string(),
  }),
});

export const logout = defineHTTPEndpoint({
  authn: 'CONSUMER',
  operation: 'POST /auth/logout',
  requestSchema: null,
  responseSchema: null,
});
