import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router';
import {useActor} from '@xstate/react';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Contact, useGetPaymentQuery} from 'lib/graphql/API';
import {getBillerSlugFromUrl} from 'lib/url';
import * as Auth from 'lib/auth/Auth';
import {ConfirmationPayInFull} from 'features/setup/components/ConfirmationPayInFull';

// View a payment made on an account, specifically one which was a "pay in full"
export const Payment: React.FC = () => {
  const billerSlug = getBillerSlugFromUrl();
  const {paymentId} = useParams<{paymentId: string}>();
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);

  useEffect(() => {
    document.title = 'Payble - Payment Confirmation';
  }, []);

  if (!paymentId) return <ErrorMessage message="No payment ID" />;

  const {data, loading, error} = useGetPaymentQuery({
    variables: {
      id: paymentId,
    },
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage message={error.message} />;
  if (!data || !data.payment) return <>No payment found</>;

  return (
    <ConfirmationPayInFull
      billerSlug={billerSlug}
      paymentId={paymentId}
      contact={authState.context.contact as Contact}
      manage={true}
    />
  );
};
