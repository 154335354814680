import {Disclosure} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {classNames} from 'lib/styles';
import React, {ReactElement} from 'react';

type QuestionAnswerProps = {
  question: string;
  answer: ReactElement;
};

export const QuestionAnswer: React.FC<QuestionAnswerProps> = ({
  question,
  answer,
}) => {
  return (
    <Disclosure
      as="div"
      className="pt-6"
      defaultOpen={window.location.hash.includes('ddrsa')}
    >
      {({open}) => (
        <>
          <dt className="text-lg">
            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
              <span className="font-medium text-gray-900">{question}</span>
              <span className="ml-6 h-7 flex items-center">
                <ChevronDownIcon
                  className={classNames(
                    open ? '-rotate-180' : 'rotate-0',
                    'h-6 w-6 transform'
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-2">
            <p className="text-base text-gray-500">{answer}</p>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
