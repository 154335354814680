import {getBillerSlugFromUrl, getUrlParams} from '../lib/url';

const keys = {
  deviceId: 'device_id',
};

export class BrowserInfo {
  #deviceId: string;
  get deviceId() {
    return (this.#deviceId ??=
      localStorage.getItem(keys.deviceId) ?? crypto.randomUUID());
  }

  get currentPath() {
    let path = window.location.pathname;
    // Remove `/biller/:billerSlug` from the path
    path = path.replace(/\/biller\/[^/]+/, '');
    path ||= '/';
    return path;
  }

  get urlParams() {
    return getUrlParams();
  }

  get billerSlug() {
    return getBillerSlugFromUrl();
  }

  get hostname() {
    return window.location.hostname;
  }

  get pageTitle() {
    return document.title || 'N/A';
  }

  get isOnline() {
    return window.navigator.onLine;
  }

  get userAgent() {
    return window.navigator.userAgent;
  }

  get language() {
    return window.navigator.language;
  }

  get zoneOffset() {
    return -new Date().getTimezoneOffset() * 60000;
  }

  get referrer() {
    return document.referrer;
  }
}

export const browserInfo = new BrowserInfo();
