import cba from 'assets/cba.png';
import ing from 'assets/ing.png';

export function getBankIconByName(name: string) {
  if (name === 'CBA') {
    return cba;
  } else if (name === 'ING') {
    return ing;
  }
}
