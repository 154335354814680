import React from 'react';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CheckIcon,
  CurrencyDollarIcon,
  HomeIcon,
} from '@heroicons/react/20/solid';
import {Step} from './SetupStep';

type SetupStepsProps = {
  step: number;
};

export const SetupSteps: React.FC<SetupStepsProps> = ({step}) => {
  return (
    <nav
      aria-label="Progress"
      className="flex flex-row items-center justify-center mt-2 z-10"
    >
      <div className="max-w-2xl relative mt-2">
        <ol role="list" className="flex items-center justify-center">
          <Step
            step={1}
            screenReaderText="Welcome"
            icon={HomeIcon}
            progress={step}
          />

          <Step
            step={2}
            screenReaderText="Options"
            icon={ChatBubbleOvalLeftEllipsisIcon}
            progress={step}
          />

          <Step
            step={3}
            screenReaderText="Pay"
            icon={CurrencyDollarIcon}
            progress={step}
          />

          <Step
            step={4}
            screenReaderText="Complete"
            icon={CheckIcon}
            progress={step}
            isLast
          />
        </ol>
      </div>
    </nav>
  );
};
