import jsep, {
  Expression,
  BinaryExpression,
  UnaryExpression,
  MemberExpression,
  Literal,
  Identifier,
  ArrayExpression,
  ConditionalExpression,
} from 'jsep';
import ternary from '@jsep-plugin/ternary';
import object, {ObjectExpression} from '@jsep-plugin/object';

jsep.plugins.register(ternary);
jsep.plugins.register(object);

type Context = {
  [key: string]: any;
};

export function safeEvaluate(expression: string, context: Context = {}): any {
  try {
    return evaluate(expression, context);
  } catch (e) {
    return null;
  }
}

export function evaluate(expression: string, context: Context = {}): any {
  return evaluateAST(jsep(expression), context);
}

export function evaluateAST(
  node?: Expression | null,
  context: Context = {}
): any {
  if (!node) return null;

  switch (node.type) {
    case 'Literal': {
      return node.value;
    }

    case 'Identifier': {
      const identifier = node as Identifier;
      if (context[identifier.name] !== undefined) {
        return context[identifier.name];
      }
      throw new Error(`Undefined variable: ${identifier.name}`);
    }

    case 'BinaryExpression': {
      const binaryNode = node as BinaryExpression;
      const left = evaluateAST(binaryNode.left, context);
      const right = evaluateAST(binaryNode.right, context);

      switch (binaryNode.operator) {
        case '+':
          return left + right;
        case '-':
          return left - right;
        case '*':
          return left * right;
        case '/':
          return left / right;
        case '%':
          return left % right;
        case '**':
          return left ** right;
        default:
          throw new Error(`Unknown operator: ${binaryNode.operator}`);
      }
    }

    case 'UnaryExpression': {
      const unaryNode = node as UnaryExpression;
      const argument = evaluateAST(unaryNode.argument, context);
      switch (unaryNode.operator) {
        case '-':
          return -argument;
        case '+':
          return +argument;
        case '!':
          return !argument;
        default:
          throw new Error(`Unknown operator: ${unaryNode.operator}`);
      }
    }

    case 'ConditionalExpression': {
      // Ternary operator
      const conditionalNode = node as ConditionalExpression;
      const test = evaluateAST(conditionalNode.test, context);
      return test
        ? evaluateAST(conditionalNode.consequent, context)
        : evaluateAST(conditionalNode.alternate, context);
    }

    case 'ArrayExpression': {
      const arrayNode = node as ArrayExpression;
      return arrayNode.elements.map(element => evaluateAST(element, context));
    }

    case 'MemberExpression': {
      const memberNode = node as MemberExpression;
      const object = evaluateAST(memberNode.object, context);
      const property = memberNode.computed
        ? evaluateAST(memberNode.property, context)
        : (memberNode.property as Identifier).name;

      if (object[property] !== undefined) {
        return object[property];
      }
      throw new Error(`Property ${property} does not exist on object`);
    }

    case 'ObjectExpression': {
      const objectNode = node as ObjectExpression;
      return objectNode.properties.reduce((obj, prop) => {
        const key =
          prop.key.type === 'Literal'
            ? (prop.key as Literal).value
            : (prop.key as Identifier).name;

        if (typeof key !== 'string' && typeof key !== 'number') {
          throw new Error(`Key [${key}] must be a string or number`);
        }

        obj[key] = evaluateAST(prop.value, context);
        return obj;
      }, {} as Context);
    }

    default: {
      throw new Error(`Unknown node type: ${node.type}`);
    }
  }
}
