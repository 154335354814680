import {getBillerConfig} from 'payble-shared';
import {getYearlyRollOverStatusByBillerSlug} from 'payble-shared/src/biller-config/handlers';
import React from 'react';

export type BillerContact = {
  name: string;
  email: string;
  phone: string;
  url: string;
};

type QuestionAnswerDataParams = {
  billerContact: BillerContact;
  billerSlug: string;
};

export const questionAnswerData = ({
  billerContact,
  billerSlug,
}: QuestionAnswerDataParams) => {
  const {name, email, url} = billerContact;
  const mailto = `mailto:${email}`;
  const billerConfig = getBillerConfig(billerSlug);
  const hasYearlyRollOver = getYearlyRollOverStatusByBillerSlug(billerSlug);
  const cardPaymentOnly = !billerConfig.paymentMethods.includes('direct_debit');

  return [
    {
      question: 'What is Payble?',
      answer: (
        <>
          Payble is a digital payment platform that allows {name} residents to
          make flexible payments towards their rates.
        </>
      ),
    },
    {
      question: 'Who can use Payble?',
      answer: (
        <>
          Any {name} ratepayer who owns a mobile phone and is responsible for
          paying rates. Each rates notice has a unique Payble QR code that
          allows the ratepayer to set up flexible payment options or pay once.
        </>
      ),
    },
    {
      question: 'Do I have to use Payble?',
      answer: (
        <>
          No. Ratepayers have the freedom to choose how they pay their rates.
          Payble allows flexible payments and the ability to self-manage your
          account.
        </>
      ),
    },
    {
      question: 'Can I use Payble on a desktop?',
      answer: (
        <>
          Yes. You can access it via desktop by going to {url}, but please note
          you will require your mobile phone to receive a "One Time Password" to
          log in.
        </>
      ),
    },
    {
      question: 'Is Payble Australian?',
      answer: (
        <>
          Yes! Payble is an Australian company with our Head Office in NSW.
          Payble is backed by CommBank – Australia's largest bank
        </>
      ),
    },
    {
      question: 'Is Payble a budgeting tool?',
      answer: (
        <>
          Payble is not a budgeting tool (there are enough of them out there)!
          However, managing flexible payments should help you with your
          budgeting!
        </>
      ),
    },
    {
      question: 'What are they key features of Payble?',
      answer: (
        <>
          Control of what and when to pay – set up flexible payments that suit
          you with the ability to skip or change your payment schedule at any
          time.
        </>
      ),
    },
    {
      question: 'Can I pay my pet registration fees through Payble?',
      answer: <>Not yet – now it is only your rates – but watch this space</>,
    },
    {
      question: 'Is Payble secure?',
      answer: (
        <>
          Yes. It has been developed to meet the standards set out by the ACCC
          Consumer Data Right regulations.
        </>
      ),
    },
    {
      question: 'Is there 2 Factor Authentication (2FA) for security?',
      answer: (
        <>
          Users are always prompted to log in using their mobile number and a
          One Time Password.
        </>
      ),
    },
    {
      question: 'Can I set up flexible payments to pay my rates?',
      answer: (
        <>
          Set up or change automated recurring payments – remove the life admin
          hassle. Access payment options like flexible payment plans, changing
          payment dates or skipping a payment.
        </>
      ),
    },
    {
      question: 'How does Payble work?',
      answer: (
        <>
          Simply scan the QR code on your rates notice and choose how to pay
          your rates. {name} will then communicate with you to remind you of
          upcoming payments and provide you the ability to update your payment
          schedule through your mobile phone.
        </>
      ),
    },
    {
      question: 'Where can I find the Payble QR Code?',
      answer: (
        <>
          The Payble QR code is on the front page of the rates notice – you can
          also visit {url} at any time.
        </>
      ),
    },
    {
      question: 'Can I access Payble without a QR Code?',
      answer: <>Yes. Go to {url} on your phone or desktop browser</>,
    },
    ...(cardPaymentOnly
      ? [
          {
            question: 'What payment methods are supported?',
            answer: (
              <>
                You can choose to pay once or with flexible payments using a
                debit or credit card.
              </>
            ),
          },
        ]
      : [
          {
            question: 'What payment methods are supported?',
            answer: (
              <>
                You can choose to pay once or with flexible payments using
                either your Australian bank account or Debit/Credit card.
              </>
            ),
          },
        ]),
    {
      question: 'How do I change my payment method?',
      answer: (
        <>
          Changing a payment method in Payble is quick and easy. Once you have
          set up your payment, click in your account icon and view existing
          payment plans. You can add a new account or debit card to your
          profile. Click on your existing plan, cancel the account, or card the
          current plan is linked to and set up a new plan on your preferred
          payment method.
        </>
      ),
    },
    {
      question: 'Can I skip a payment?',
      answer: (
        <>
          With Payble, if you have setup flexible payments you can skip a
          payment. Click on your payment plan and select “Skip Next Payment”
          from the option menu. You cannot skip payments if you have chosen the
          “Autopay” payment type. If you have registered for Autopay, you
          automatically pay the total amount due on the due date.
        </>
      ),
    },
    {
      question: 'Can I cancel or change my payment schedule?',
      answer: (
        <>
          Yes – simply log in and select the account you wish to change or
          cancel the payment schedule for. Then from the "Options" menu, select
          "Cancel" and follow the prompts.
          <br />
          <br />
          If you want to change your payment schedule, go back to the Welcome
          page and select Link new property from the "Options" menu and select
          the new schedule you would like.
          <br />
          <br />
          You will also receive SMS confirmation of any changes made to your
          account.
        </>
      ),
    },
    {
      question: 'Can I make additional payments?',
      answer: (
        <>
          Yes, you can make additional payments. Depending on the type of
          payment plan, your future scheduled payments may be adjusted.
        </>
      ),
    },
    {
      question: 'How do I check if my payment goes though?',
      answer: (
        <>
          When your payment has been receipted it will be reflected in the
          Payble schedule giving you piece of mind. You will also be able to see
          this in on your bank statements. You will also receive and SMS
          notification.
        </>
      ),
    },
    {
      question:
        'How can I find my Payble schedule in my phone once I have set the payment schedule up?',
      answer: (
        <>
          {name} will SMS you billing confirmation ahead of the debit. You will
          be able to check your set up via the link in the SMS before the debit
          comes out. If you need to change it you can do in your Payble account.
        </>
      ),
    },
    {
      question:
        'How many different properties can I have on my Payble account?',
      answer: (
        <>
          There is no limit to how many properties you can have under one Payble
          account. Each property has a unique identifier and QR code, so if you
          own multiple properties you can schedule payments for each property
          under your Payble account, by scanning each QR code, and view your
          schedules in one place.
        </>
      ),
    },
    {
      question:
        'How do I change my details after setting up my Payble account?',
      answer: (
        <>
          You can change your contact details and Payment schedule through the
          Profile page in Payble.
        </>
      ),
    },
    {
      question: 'How do I cancel the service or change my plan?',
      answer: (
        <>
          Simply log in and select the account you want to cancel or change.
          From the "Options" menu, select "Cancel" and follow the prompts. If
          you want to change your payment schedule, go back to the Welcome page,
          select Link new account from the "Options" menu and select the new
          schedule you would like. You will also receive SMS confirmation of any
          changes made to your account.
        </>
      ),
    },
    {
      question: 'How do I exit the Payble browser?',
      answer: (
        <>
          You can securely log out by going to the "Options" menu and tapping
          "Sign Out". You will be prompted with a "Logout successful" screen.
        </>
      ),
    },
    {
      question:
        'Will I receive any other confirmation that my payment plan has successfully been set up?',
      answer: (
        <>
          Yes. You will receive an SMS reminder the day before a payment is
          scheduled. This allows you to ensure you have money in your account or
          prompt you to change your payment method or skip this payment.
        </>
      ),
    },
    {
      question:
        'How long does it take for a payment to be processed once I set up a direct debit?',
      answer: (
        <>
          Payments are processed on the scheduled date. This usually occurs
          between 12 to 2 pm, and you will receive an SMS notification. Payments
          scheduled on a weekend will be processed the following business day.
        </>
      ),
    },
    {
      question:
        "What will happen if there's no money in my account when a payment is due?",
      answer: (
        <>
          Failed credit or debit card payments will reject immediately. Payble
          will reattempt to process payment two days after, and you will be
          advised when this will occur by SMS. You can also update your payment
          methods to pay or skip this payment by logging in to Payble. Failed
          bank account payments will be reversed, you will be notified as soon
          as your bank rejects the payment request.
        </>
      ),
    },
    {
      question:
        "My monthly payment plan shows a different figure when selecting the plan after I've set it up.  Why? ",
      answer: (
        <>
          Payble allows you to set weekly, fortnightly, or monthly payments. You
          can also make voluntary payments or skip payments. The value of a
          skipped payment will be spread evenly over the remaining payments.
        </>
      ),
    },
    {
      question:
        'When can I change my payment frequency or type of payment plan?',
      answer: (
        <>
          Plans can be changed at any time before the due date if there are more
          than two payments left.
        </>
      ),
    },
    {
      question: 'How many times can I skip a payment?',
      answer: (
        <>
          Scheduled payments may be skipped twice at most. You cannot skip your
          final payment that is part of a flexible payment plan. It is important
          to know that interest is accrued daily on amounts outstanding after
          their due date – please refer to your rates notice for more
          information.
        </>
      ),
    },
    {
      question: 'What if I change banks or a credit card expires?',
      answer: (
        <>
          If you change banks or your current credit card expires. You can add
          new payment methods and remove old payment methods through Payble.
        </>
      ),
    },
    {
      question: `I have multiple properties in ${name}.  Do I need multiple accounts?`,
      answer: (
        <>
          You can have properties under one Payable account. Multiple properties
          can be linked to your mobile phone number. From this login, you can
          add as many properties as you would like to create flexible payment
          plans for.
        </>
      ),
    },
    {
      question:
        'I have a new credit card.  Do I need to update my details in Payble?',
      answer: (
        <>
          Yes. Update your payment method for your active payment plan from the
          "Options" menu. Select "Change Payment Method" and select the correct
          card or account.
        </>
      ),
    },
    {
      question: 'Do I need to close my Payble account if I sell my property?',
      answer: (
        <>
          Yes, you should cancel your payment plan prior to property settlement.
        </>
      ),
    },
    {
      question: 'How do I reset my password?',
      answer: (
        <>
          There is no need to reset your password. A "One Time" password is
          generated and sent via SMS each time you log in. This password is
          valid for 180 seconds for added security.
        </>
      ),
    },
    {
      question:
        'Who do I contact if there is a problem with my Payble account?',
      answer: (
        <>
          If there is a problem with the Payble link, or the functionality of
          Payble please contact {name} at <a href={mailto}>{email}</a> for
          support.
        </>
      ),
    },
    {
      question:
        'Who do I contact if there is a problem with my rates in Payble?',
      answer: (
        <>
          If there is a problem with your rates balances, or amounts due please
          contact {name} at <a href={mailto}>{email}</a> for support.
        </>
      ),
    },
    {
      question: 'How long do payments take to settle?',
      answer: (
        <>
          Card transactions generally settle immediately so your payment status
          will update to Approved. Bank account direct debit payments can take
          up to 3 business days. Payments after 5.30pm AEST will be processed
          the next business day.
        </>
      ),
    },
    ...(hasYearlyRollOver
      ? [
          {
            question:
              'Will my payment plan roll over to the next billing period?',
            answer: (
              <>
                Yes. Your payment plan will be completed each billing period and
                a new one will automatically recommence once your next annual
                rates notice has been issued. You will be notified of this in
                advance of the payment plan recommencing. You may choose to
                cancel or amend your payment plan at any time.
              </>
            ),
          },
          {
            question:
              'Will I need to re-enrol in a payment plan again next year?',
            answer: (
              <>
                No. If you have an active payment plan, it will be finalised and
                completed at the end of each billing period and a new one will
                automatically recommence once your next annual rates notice has
                been issued. You will be notified of this in advance of the
                payment plan recommencing. You may choose to cancel or amend
                your payment plan at any time.
              </>
            ),
          },
        ]
      : []),
  ];
};
