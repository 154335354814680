import React from 'react';

type RolloverNoticeProps = {
  showNotice: boolean;
};

export const RolloverNotice: React.FC<RolloverNoticeProps> = ({showNotice}) => {
  if (!showNotice) return null;

  return (
    <p className="py-5">
      Your flexible payment plan will automatically roll-over into future
      billing periods. We’ll send an SMS before any roll-over and you can always
      cancel at any time.
    </p>
  );
};
