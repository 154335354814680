import {errs} from 'payble-shared';
import {useState} from 'react';
import {analytics} from '../../../analytics/hooks/useAnalytics';
import {useEmailVerificationSendMutation} from '../../../lib/graphql/API';
import {useAuthState} from '../../setup/hooks/useAuthState';

export const useEmailVerificationSend = () => {
  const [canReSend, setCanReSend] = useState<boolean>(false);

  const [sendEmail, sendEmailState] = useEmailVerificationSendMutation({
    onCompleted: () => {
      setTimeout(() => setCanReSend(true), 10_000);
      analytics.addEvent('email_verification_sent');
    },
  });
  const {
    context: {contact},
  } = useAuthState();

  if (!contact) {
    throw new Error('No contact found in context');
  }

  const {emailVerified, email} = contact;

  const send = () =>
    email
      ? sendEmail()
      : Promise.reject(
          new Error('No email found when dispatching email verification')
        );

  if (emailVerified) {
    return {
      state: 'VERIFIED' as const,
      email,
    };
  }

  if (!email) {
    return {
      state: 'NO_EMAIL' as const,
    };
  }

  if (sendEmailState.error) {
    return {
      state: 'ERROR' as const,
      errorMessage: errs.fromGraphQL(sendEmailState.error).first().message,
      send,
      email,
    };
  }

  if (sendEmailState.loading) {
    return {
      state: 'LOADING' as const,
      email,
    };
  }

  if (canReSend && sendEmailState.called) {
    return {
      state: 'RESEND' as const,
      email,
      send,
    };
  }

  if (sendEmailState.called) {
    return {
      state: 'SENT' as const,
      email,
    };
  }

  return {
    state: 'PENDING' as const,
    email,
    send,
  };
};
