import {z} from 'zod';
import {FormConfig} from '../../types';

export const twilio = {
  title: 'TWILIO',
  configType: 'biller',

  validation: z.object({
    accountSid: z.string().min(1, 'Account SID is required'),
    authToken: z.string().min(1, 'Auth token is required'),
    senderId: z.string().min(1, 'Sender ID is required'),
    test: z.boolean().optional(),
  }),

  form: [
    {
      type: 'text',
      name: 'accountSid',
      label: 'Account SID',
    },
    {
      type: 'text',
      name: 'authToken',
      label: 'Auth token',
    },
    {
      type: 'text',
      name: 'senderId',
      label: 'Sender ID',
      description:
        'This must exactly match the sender ID configured for the biller. This was not previously a requirement but now Twilio enforces supplying this value in requests.',
    },
  ],
} satisfies FormConfig;
