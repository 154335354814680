import {Disclosure} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {classNames} from 'lib/styles';
import React from 'react';
import {HowToVideoData} from '../data/HowToVideoData';
import {HowToVideoRow} from './HowToVideoRow';

type HowToVideosProps = {};

export const HowToVideos: React.FC<HowToVideosProps> = () => {
  const videoHtmlIds = HowToVideoData.map(x => x.htmlId);
  return (
    <Disclosure
      as="div"
      className="pt-6"
      defaultOpen={videoHtmlIds.some(x => location.hash.includes(x))}
    >
      {({open}) => (
        <>
          <dt className="text-lg">
            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
              <span className="font-medium text-gray-900">
                Payble <b>'How-to'</b> videos
              </span>
              <span className="ml-6 h-7 flex items-center">
                <ChevronDownIcon
                  className={classNames(
                    open ? '-rotate-180' : 'rotate-0',
                    'h-6 w-6 transform'
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="md:m-7">
            {HowToVideoData.map(row => (
              <HowToVideoRow
                key={row.htmlId}
                title={row.title}
                cloudfareStreamVideoID={row.cloudfareStreamVideoID}
                videoLink={row.videoLink}
                htmlId={row.htmlId}
              />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
