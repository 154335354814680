import type {z} from 'zod';
import {apiSchema} from 'payble-api-client';
import {SessionData} from './Session';
import {BrowserInfo} from './BrowserInfo';

export type StoredEvent = {
  id: number;
  event: z.infer<(typeof apiSchema)['AnalyticsEvent']>;
};

export class StorageUtil {
  static readonly analyticsTokenKey = 'payble_tokens';
  static readonly sessionsKey = 'payble_sessions';
  static readonly eventsKey = 'payble_events';
  browser: BrowserInfo;

  constructor(browser: BrowserInfo) {
    this.browser = browser;
  }

  set analyticsToken(token: string) {
    const items = this.itemsDictionary(StorageUtil.analyticsTokenKey);
    items[this.browser.billerSlug] = token;
    localStorage.setItem(StorageUtil.analyticsTokenKey, JSON.stringify(items));
  }

  set session(sessionId: SessionData) {
    const items = this.itemsDictionary(StorageUtil.sessionsKey);
    items[this.browser.billerSlug] = JSON.stringify(sessionId);
    localStorage.setItem(StorageUtil.sessionsKey, JSON.stringify(items));
  }

  set events(data: StoredEvent[]) {
    const items = this.itemsDictionary(StorageUtil.eventsKey);
    items[this.browser.billerSlug] = JSON.stringify(data);
    localStorage.setItem(StorageUtil.eventsKey, JSON.stringify(items));
  }

  get analyticsToken() {
    const items = this.itemsDictionary(StorageUtil.analyticsTokenKey);
    return items[this.browser.billerSlug];
  }

  get session(): SessionData | null {
    try {
      const items = this.itemsDictionary(StorageUtil.sessionsKey);
      const sessionString = items[this.browser.billerSlug];
      const session = sessionString ? JSON.parse(sessionString) : null;
      if (typeof session !== 'object') {
        throw new Error('Invalid sessions in local storage');
      }
      return session;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  get events(): StoredEvent[] {
    try {
      const items = this.itemsDictionary(StorageUtil.eventsKey);
      const eventString = items[this.browser.billerSlug];
      const events = eventString ? JSON.parse(eventString) : [];
      if (!Array.isArray(events)) {
        throw new Error('Invalid events in local storage');
      }
      return events;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  // Map of billerId to analytics token / session id. Used to allow different tabs to use
  // different billers (almost exclusively for testing)
  private itemsDictionary(name: string): Record<string, string> {
    try {
      const itemName = localStorage.getItem(name);
      const items = itemName ? JSON.parse(itemName) : {};

      if (typeof items !== 'object') {
        throw new Error('Invalid items in local storage');
      }
      return items;
    } catch (e) {
      console.error(e);
      return {};
    }
  }
}
