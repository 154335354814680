import {isASCIIChar} from './string';

const specialChars = {
  '–': '-',
  '—': '-',
  '‒': '-',
  '‐': '-',
  '“': '"',
  '”': '"',
  '‘': "'",
  '’': "'",
};

const specialCharsToASCII = function (x: string) {
  return specialChars[x as keyof typeof specialChars] || x;
};

export const normaliseAccountName = (str: string) => {
  return str
    .normalize('NFD')
    .split('')
    .map(specialCharsToASCII)
    .filter(isASCIIChar)
    .join('');
};
