import {DomainError} from './DomainError';
import {UnexpectedError} from './StandardErrors';

export class Errors {
  constructor(private errors: DomainError[]) {}

  first() {
    return (
      this.errors[0] ??
      UnexpectedError.create('Something went wrong', {
        reason: 'No error was found in the error list',
      })
    );
  }

  find<T extends typeof DomainError>(klass: T): InstanceType<T> | undefined {
    return this.errors.find(e => e instanceof klass) as InstanceType<T>;
  }
}
