import * as React from 'react';

import {Calendar} from './Calendar';
import {Drawer} from './Drawer';
import {useDrawer} from '../hooks/useDrawer';
import {InputAddon} from './InputAddon';
import {DateTime} from 'luxon';

const DATE_FORMAT = 'yyyy-MM-dd';

const InputDate = ({
  title = '',
  placeholder = 'Add a date',
  min,
  max,
  value,
  onChange,
}: {
  title?: string;
  placeholder?: string;
  value?: string;
  min?: string;
  max?: string;
  onChange?: (date?: string) => void;
}) => {
  const [date, setDate] = React.useState<Date | undefined>(
    value ? DateTime.fromFormat(value, DATE_FORMAT).toJSDate() : undefined
  );
  const {drawerOpened, openDrawer, closeDrawer} = useDrawer({date: false});

  return (
    <>
      <InputAddon
        iconR="calendar"
        placeholder={placeholder}
        value={
          date ? DateTime.fromJSDate(date).toFormat(DATE_FORMAT) : undefined
        }
        onFocus={() => openDrawer('date')}
      />
      <Drawer
        open={drawerOpened.date}
        title={title}
        onCancel={() => closeDrawer('date')}
      >
        <div className="px-4">
          <div className="flex justify-center w-full">
            <Calendar
              mode="single"
              selected={date}
              onSelect={date => {
                setDate(date);
                closeDrawer('date');
                onChange?.(
                  date
                    ? DateTime.fromJSDate(date).toFormat(DATE_FORMAT)
                    : undefined
                );
              }}
              fromDate={
                min
                  ? DateTime.fromFormat(min, DATE_FORMAT).toJSDate()
                  : undefined
              }
              toDate={
                max
                  ? DateTime.fromFormat(max, DATE_FORMAT).toJSDate()
                  : undefined
              }
              required
            />
          </div>
          <Drawer.Footer>
            <Drawer.CloseButton onClick={() => closeDrawer('date')} />
          </Drawer.Footer>
        </div>
      </Drawer>
    </>
  );
};

InputDate.displayName = 'InputDate';

export {InputDate};
