import {FC, ReactNode} from 'react';
import {InputAddon, InputAddonProps} from './InputAddon';
import {FormField} from './FormField';
import {useRegisterField} from '../hooks/useRegisterField';

export interface FormInputProps extends InputAddonProps {
  label?: ReactNode;
  name: string;
}

export const FormInput: FC<FormInputProps> = ({label, name, ...props}) => {
  const {id, field, form} = useRegisterField(name);

  return (
    <FormField id={id} name={name} label={label}>
      <InputAddon
        id={id}
        {...props}
        {...field}
        disabled={
          props.disabled || field.disabled || form.formState.isSubmitting
        }
      />
    </FormField>
  );
};
