import {AsyncResult} from '@unruly-software/result';
import axios, {GenericAbortSignal} from 'axios';
import {errs} from 'payble-shared';
import {type billerAPISpec, type consumerAPISpec} from '..';
import {
  AnyAPISpec,
  AnyHTTPEndpointDefinition,
  HTTPEndpointRequestData,
  HTTPEndpointResponse,
} from './endpointDefinition';
import {toQueryString} from './queryString';

// Biller and consumer authentication are cookie-based and do not require
// additional headers.
type HTTPAuthTypes = {type: 'consumer'; token: string} | {type: 'anonymous'};

type AxiosLike = {
  request(args: {
    method: string;
    url: string;
    baseURL?: string;
    headers: Record<string, string>;
    signal?: GenericAbortSignal;
    paramsSerializer: typeof toQueryString;
    validateStatus: (status: number) => boolean;
    responseType: 'json';
    params?: Record<string, unknown>;
    data?: Record<string, unknown>;
  }): Promise<{
    status: number;
    data: unknown;
  }>;
};

export type ConsumerAPIClient = APIClient<typeof consumerAPISpec>;

export type BillerAPIClient = APIClient<typeof billerAPISpec>;

interface APIClientProps<T extends Record<string, AnyHTTPEndpointDefinition>> {
  definitions: T;
  paranoiaMode?: boolean;
  baseURL?: string;
  defaultAuth?: HTTPAuthTypes;
  http?: AxiosLike;
}

export type HTTPRequestOptions<D extends AnyHTTPEndpointDefinition> =
  HTTPEndpointRequestData<D> extends never
    ? {
        abort?: GenericAbortSignal;
        data?: never;
      }
    : {
        data?: HTTPEndpointRequestData<D>;
        abort?: GenericAbortSignal;
      };

export class APIClient<T extends AnyAPISpec> {
  constructor(private props: APIClientProps<T>) {
    if (props.defaultAuth) {
      this.setAuth(props.defaultAuth);
    }
  }

  private headers: {
    Authorization?: string;
  } = {};

  private get http() {
    return this.props.http ?? axios;
  }

  request = <EP extends keyof T>(
    endpoint: EP,
    options: HTTPRequestOptions<T[EP]>
  ): AsyncResult<HTTPEndpointResponse<T[EP]>, errs.DomainError> => {
    const {requestSchema, responseSchema, method, path, operation} =
      this.props.definitions[endpoint] ?? {};

    return AsyncResult.invoke(async () => {
      if (!method || !path) {
        throw new Error(
          `Invalid endpoint definition for ${endpoint as string}`
        );
      }

      const {data} = options;

      if (this.props.paranoiaMode && requestSchema) {
        requestSchema.parse(data);
      }

      const input = method === 'GET' ? {params: data} : {data};
      const response = await this.http.request({
        method,
        url: path,
        baseURL: this.props.baseURL,
        headers: this.headers,
        signal: options.abort,
        paramsSerializer: toQueryString,
        validateStatus: () => true,
        responseType: 'json',
        ...input,
      });

      if (response.status !== 200) {
        throw errs.enrichContext(errs.fromHTTP(response.data), {
          status: response.status,
        });
      }

      if (responseSchema) {
        const result = responseSchema.safeParse(response.data);
        if (!result.success) {
          throw errs.UnexpectedError.create('Something went wrong', {
            validation: result.error.flatten(),
          });
        }
        return result.data;
      }
      return null;
    }).mapFailure(err => errs.enrichContext(err, {endpoint, operation})) as any;
  };

  setAuth(auth: HTTPAuthTypes) {
    if (auth.type === 'consumer') {
      this.headers['Authorization'] = `Bearer ${auth.token}`;
    } else {
      this.headers['Authorization'] = undefined;
    }
  }
}
