import {usePatchSearchParams} from 'lib/navigation/usePatchSearchParams';
import {InfringementType} from 'lib/types/Infringement';
import {useParams} from 'react-router';

export function useInfringementParams() {
  const {type} = useParams<{type: InfringementType}>();
  const {params, patch} = usePatchSearchParams<{
    infringementType: InfringementType;
    accountType: string;
    accountExternalId: string;
    verificationCode: string;
  }>({
    infringementType: type ?? 'fine',
    accountType: 'infringements',
    accountExternalId: '',
    verificationCode: '',
  });

  const {infringementType, accountType, accountExternalId, verificationCode} =
    params;

  return {
    infringementType,
    accountType,
    accountExternalId,
    verificationCode,
    patch,
  };
}
