import React from 'react';
import {getBillerFriendlyNameFromSlug, getBillerSlugFromUrl} from 'lib/url';
import {getBillerConfig} from 'payble-shared';
import {markDownToHtml} from 'lib/markDownToHtml';

export const InformationDisclaimer = () => {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = getBillerConfig(billerSlug);
  const billerFriendlyName = getBillerFriendlyNameFromSlug(billerSlug);

  return (
    <div className="relative max-w-xl md:w-xl mx-auto pt-12 pb-6 px-4 sm:px-6 lg:py-16 lg:px-8">
      {billerConfig?.privacyWarning ? (
        <p
          className="text-xs text-gray-600 text-center [&>a]:underline"
          dangerouslySetInnerHTML={{
            __html: markDownToHtml(billerConfig.privacyWarning, ['a']),
          }}
        />
      ) : (
        <p className="text-xs text-gray-600 text-center">
          {billerFriendlyName} and Payble are collecting your personal
          information for the purpose of processing your payment request.{' '}
          {billerFriendlyName} may use your personal information to update{' '}
          customer information records and to contact you about other functions
          and services.
        </p>
      )}
    </div>
  );
};
