import {DomainError} from './DomainError';
import {UnexpectedError} from './StandardErrors';

export const enrichContext = (
  err: unknown,
  context: Record<string, unknown>
) => {
  if (err instanceof DomainError) {
    err.context = {...context, ...err.context};
    return err;
  } else {
    return UnexpectedError.wrap(err, 'Something went wrong', context);
  }
};
