import {ExclamationCircleIcon} from '@heroicons/react/24/outline';
import {navigate} from 'lib/navigation/routes';
import {buttonClasses} from 'lib/styles';
import React, {Dispatch} from 'react';
import {Stream} from '@cloudflare/stream-react';

type CancelChangePaymentScheduleProps = {
  billerSlug: string;
  instalmentPlanId: string;
  setShowConfirmation: Dispatch<React.SetStateAction<boolean>>;
};

export const CancelChangePaymentSchedule: React.FC<
  CancelChangePaymentScheduleProps
> = ({billerSlug, instalmentPlanId, setShowConfirmation}) => {
  const htmlId = 'skip-instalment';
  const cloudfareStreamVideoID =
    'https://iframe.cloudflarestream.com/437d245606b533cdaa8a7cee589528f6?preload=metadata';
  return (
    <>
      <ExclamationCircleIcon className="self-center w-20 mb-5" />
      <div className="mb-5">
        Did you know you can skip a scheduled payment and it will be rebalanced
        over your remaining payments?
      </div>
      <div>View our how-to video:</div>
      <div id={htmlId} className="w-full mt-2 aspect-video">
        <Stream controls responsive src={cloudfareStreamVideoID} />
      </div>
      <button
        className={buttonClasses}
        onClick={() => {
          navigate(
            '/biller/:slug/instalment-plan/:instalmentPlanId/skip-instalment',
            {slug: billerSlug, instalmentPlanId}
          );
        }}
      >
        Skip next payment
      </button>
      <div
        onClick={() => {
          setShowConfirmation(true);
          navigate('/biller/:slug/instalment-plan/:instalmentPlanId/cancel', {
            slug: billerSlug,
            instalmentPlanId,
          });
        }}
        className="text-center mt-5 cursor-pointer"
      >
        Continue to cancel
      </div>
    </>
  );
};
