import '../main.css';

import React from 'react';
import {Toaster} from '../components/Toaster';

export const PaybleUI: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <>
      <Toaster />
      {children}
    </>
  );
};
