import React from 'react';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {buttonClasses} from 'lib/styles';
import {Debbie} from './Debbie';

type BeautifiedErrorMessageProps = {
  debbieTitle: string;
  debbieMessage: string;
  errorMessage?: string;
  onClick: Function;
  buttonText?: string;
};

export const BeautifiedErrorMessage: React.FC<BeautifiedErrorMessageProps> = ({
  debbieTitle,
  debbieMessage,
  errorMessage,
  onClick,
  buttonText,
}) => {
  return (
    <>
      <div className="flex flex-col max-w-xl md:w-xl m-auto items-center justify-center">
        <Debbie title={debbieTitle} message={debbieMessage} />
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <button className={buttonClasses} onClick={() => onClick()}>
          {buttonText || 'Try Again'}
        </button>
      </div>
    </>
  );
};
