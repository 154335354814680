import {Bank, GetBankByCodeDocument, GetBankByCodeQuery} from 'lib/graphql/API';
import * as Sentry from '@sentry/react';
import {client} from 'lib/graphql/client';
import {errs} from 'payble-shared';

export async function getBankByCode(
  code: string,
  region: string
): Promise<Bank | null> {
  const result = await client.query<GetBankByCodeQuery>({
    query: GetBankByCodeDocument,
    variables: {
      code,
      region,
    },
    errorPolicy: 'all',
  });

  if (result.errors) {
    const error = errs.fromGraphQL({graphQLErrors: result.errors}).first();
    if (error.code !== 'NOT_FOUND') {
      Sentry.captureException(error);
    }

    return null;
  }

  return result?.data?.bankByCode as Bank;
}
