import React from 'react';

export const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center w-72 sm:w-96 mx-auto">
      <p className="text-base font-semibold text-navy">404</p>
      <h1 className="mt-2 text-4xl font-bold tracking-tight text-navy">
        Page not found
      </h1>
      <p className="mt-4 text-base text-gray-600 text-center">
        Sorry, we couldn’t find the page you’re looking for.
      </p>
      <div className="mt-4">
        <a href="/" className="text-base font-medium text-navy">
          Go back home
          <span aria-hidden="true"> &rarr;</span>
        </a>
      </div>
    </div>
  );
};
