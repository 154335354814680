import React from 'react';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';
import {Account, PayAccountAnonymouslyMutation} from 'lib/graphql/publicAPI';
import NumberFormat from 'react-number-format';
import {formatToDollars} from 'payble-shared';

export const PaymentStatus = ({
  account,
  payment,
}: {
  account: Account;
  payment: PayAccountAnonymouslyMutation['payAccountAnonymously'];
}) => {
  const billerConfig = useBillerConfig();

  return (
    <div className="mt-4 overflow-hidden rounded-md shadow">
      <div className="px-4 py-4 bg-white sm:p-6">
        <div className="border-b">
          <h4 className="mb-2 font-medium text-gray-900">Account details</h4>
          <div className="grid grid-cols-2 gap-4 pb-4">
            <p className="text-sm text-gray-600">
              {billerConfig.getExternalIdLabel(account.type)}
            </p>
            <p id="external-id" className="text-sm text-gray-600">
              {account.externalId}
            </p>
            <p className="text-sm text-gray-600">Address</p>
            <p id="address" className="text-sm text-gray-600">
              {account.description}
            </p>
          </div>
        </div>

        <div>
          <h4 className="mt-4 mb-2 font-medium text-gray-900">
            Payment information
          </h4>
          <div className="grid grid-cols-2 gap-4 pb-4">
            <p className="text-sm text-gray-600">Payment amount</p>
            <p className="text-sm text-gray-600">
              <NumberFormat
                value={formatToDollars(payment?.payment?.amount || 0)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
