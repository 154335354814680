import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import {cn} from '../lib/utils';
import {
  ComponentPropsWithoutRef,
  FC,
  ReactNode,
  forwardRef,
  useId,
} from 'react';
import {Icon, IconName} from './Icon';

export interface RadioGroupProps
  extends ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {
  className?: string;
  options?: {
    value: string;
    label: ReactNode;
    icon?:
      | IconName
      | {src: string; alt?: string; width?: number; height?: number};
    description?: ReactNode;
  }[];
}

const RadioGroup: FC<RadioGroupProps> = forwardRef<
  HTMLInputElement,
  RadioGroupProps
>(({className, options, disabled, ...props}, ref) => {
  const id = useId();

  return (
    <RadioGroupPrimitive.Root
      {...props}
      ref={ref}
      className={cn('grid gap-3', className)}
    >
      {options?.map(option => (
        <label
          htmlFor={`${option.value}-${id}`}
          className={cn(
            'flex gap-3 p-3 border border-border shadow-xs rounded-lg cursor-pointer justify-between',
            props.value === option.value ? 'bg-secondary' : 'bg-white'
          )}
          key={`${option.value}-${id}`}
        >
          {typeof option.icon === 'string' && (
            <Icon name={option.icon} className="shrink-0" />
          )}
          {typeof option.icon === 'object' && (
            <img
              {...option.icon}
              width={option.icon.width ?? 20}
              className="shrink-0"
            />
          )}
          <div className="flex flex-col flex-1 gap-3">
            <span className="text-sm font-semibold text-foreground">
              {option.label}
            </span>
            {option.description && (
              <p className="text-sm font-medium">{option.description}</p>
            )}
          </div>
          <RadioGroupPrimitive.Item
            id={`${option.value}-${id}`}
            value={option.value}
            disabled={disabled}
            className={cn(
              'flex items-center justify-center aspect-square h-5 w-5 rounded-full border border-border bg-input/30 text-primary shadow-sm focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50'
            )}
          >
            <RadioGroupPrimitive.Indicator className="w-full h-full bg-white border-[5px] rounded-full border-primary" />
          </RadioGroupPrimitive.Item>
        </label>
      ))}
    </RadioGroupPrimitive.Root>
  );
});

export {RadioGroup};
