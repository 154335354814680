import {useNavigateWithCurrentParams} from 'lib/navigation/useNavigateWithSearchParams';
import {useInfringementParams} from './useInfringementParams';

const BASE_URL = '/biller/:slug/infringements/:type';

type InfringementNavigatePathParams = {
  type: string;
};

type InfringementNavigateSearchParams = {
  accountType: string;
  accountExternalId: string;
};

export function useInfringementNavigate() {
  const {infringementType} = useInfringementParams();
  const navigate = useNavigateWithCurrentParams<
    InfringementNavigateSearchParams,
    InfringementNavigatePathParams
  >({type: infringementType});

  return (
    path: string,
    searchParams?: InfringementNavigateSearchParams,
    pathParams?: InfringementNavigatePathParams,
    replace?: boolean
  ) => {
    navigate(`${BASE_URL}${path}`, searchParams, pathParams, replace);
  };
}
