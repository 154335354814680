import {LinkImageModal} from 'components/atoms/LinkImageModal';
import {ComponentConfig} from 'payble-shared';

export const BillerConfigComponent = ({
  config,
}: {
  config: ComponentConfig | ComponentConfig[];
}) => {
  if (typeof config === 'string') return <>{config}</>;

  if (Array.isArray(config)) {
    return config.map((c, index) => (
      <>
        {index > 0 && ' '}
        <BillerConfigComponent config={c} />
      </>
    ));
  }

  if ('component' in config && config.component === 'a') {
    return (
      <a {...config.props} className="text-blue-600 hover:underline">
        {config.text}
      </a>
    );
  }

  if ('component' in config && config.component === 'LinkImageModal') {
    return <LinkImageModal {...config.props}>{config.text}</LinkImageModal>;
  }

  return <>{config.text}</>;
};
