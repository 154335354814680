import React from 'react';
import {XCircleIcon} from '@heroicons/react/24/outline';
import {classNames} from 'lib/styles';

export type ErrorProps = {
  message: string;
  className?: string;
  showReturnHome?: boolean;
};

export const ErrorMessage: React.FC<ErrorProps> = ({
  message,
  className = '',
  showReturnHome = false,
}) => {
  return (
    <div
      className={classNames('max-w-lg mx-auto px-2 cursor-default', className)}
    >
      <div className="px-4 py-5 sm:px-6"></div>
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{message}</h3>
            {showReturnHome && (
              <h3 className="text-sm font-medium text-red-800 cursor-pointer">
                Click here to return home.
              </h3>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
