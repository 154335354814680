import React, {ReactNode} from 'react';
import {Input} from 'payble-ui';

export type Props = {
  children: ReactNode;
  name: string;
  id: string;
  onClick: () => void;
  checked?: boolean;
  disabled?: boolean;
};

export const StyledCheckbox: React.FC<Props> = ({
  children,
  name,
  id,
  onClick,
  checked = false,
  disabled = false,
}) => {
  return (
    <div className="w-full flex flex-col mb-2">
      <div className="flex items-center space-between w-full">
        <div className="text-xs text-gray-500 mr-2 flex-grow">{children}</div>
        <div>
          <Input
            type="checkbox"
            name={name}
            checked={checked}
            onClick={onClick}
            id={id}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
