import {getBillerSlugFromUrl} from 'lib/url';
import {
  DEFAULT_ACCOUNT_TYPES_INFO,
  getBillerAccountTypeInfo,
  getBillerConfig,
} from 'payble-shared';
import {useSearchParams} from 'react-router-dom';

export function useGetAccountTypeInfo() {
  const billerSlug = getBillerSlugFromUrl();

  const billerConfig = getBillerConfig(billerSlug);
  const accountTypesInfo = billerConfig.getAccountTypesInfo();
  const [searchParams] = useSearchParams();

  const accountTypeInfo =
    getBillerAccountTypeInfo(
      billerSlug,
      searchParams.get('accountType') || accountTypesInfo[0].id
    ) ?? DEFAULT_ACCOUNT_TYPES_INFO[0];

  return accountTypeInfo;
}
