import {PaymentMethodType as InternalPaymentMethodType} from 'payble-shared';
import {useContext, useMemo} from 'react';
import {Context as BillerConfigContext} from './BillerConfig';

export const useBillerConfig = () => {
  const config = useContext(BillerConfigContext);
  if (!config) {
    throw new Error('No biller config');
  }

  return config;
};

export const usePaymentSurchargeInfo = (type: InternalPaymentMethodType) => {
  const billerConfig = useBillerConfig();
  return useMemo(() => {
    const {surchargeCalculator} = billerConfig;
    if (!surchargeCalculator.hasSurcharges || type !== 'card') {
      return {type: 'NONE'} as const;
    }
    const prettyType = type === 'card' ? ' card' : ' direct debit';

    const singleRate = surchargeCalculator.getSingleTypeSurcharge(type);
    if (singleRate) {
      return {
        type: 'SIMPLE',
        rate: singleRate,
        agreement: (
          <span>
            I understand a <b>surcharge of {singleRate.toPretty()}</b> applies
            to this and all future {prettyType} payments.
          </span>
        ),
      } as const;
    }

    return {
      type: 'COMPLEX',
      rateInfo: surchargeCalculator.prettyPrint(),
      agreement: (
        <span>
          I understand a <b>surcharge</b> applies to this and all future{' '}
          {prettyType} payments.
        </span>
      ),
    } as const;
  }, [billerConfig, type]);
};
