import {StorageUtil} from './StorageUtil';

export type SessionData = {
  sessionId: string;
  lastPing: number;
};

export class Session {
  private sessionTimeoutDuration: number;
  private storage: StorageUtil;

  sessionId: string;
  constructor(storage: StorageUtil) {
    this.sessionTimeoutDuration = 3600000; // 1hr in ms
    this.storage = storage;
    this.sessionId = this.sessionHeartbeat().sessionId;
  }

  private onNewSession: (() => unknown) | null = null;

  handleNewSession(callback: () => unknown) {
    this.onNewSession = callback;
  }

  private createSession() {
    this.onNewSession?.();
    return {
      sessionId: crypto.randomUUID(),
      lastPing: this.currentTime(),
    };
  }

  private currentTime() {
    return new Date().getTime();
  }

  sessionHeartbeat(): SessionData {
    const storedSession = this.storage.session;

    if (!storedSession) {
      console.log('Starting session');
      const session = this.createSession();
      this.storage.session = session;
      this.sessionId = session.sessionId;
      return session;
    }

    const hasExpired =
      this.currentTime() - storedSession.lastPing > this.sessionTimeoutDuration;

    if (hasExpired) {
      console.log('Session expired');
      const session = this.createSession();
      this.storage.session = session;
      this.sessionId = session.sessionId;
      return session;
    }

    // update last ping to current time
    const session = {...storedSession, lastPing: this.currentTime()};
    this.storage.session = session;
    this.sessionId = session.sessionId;
    return session;
  }
}
