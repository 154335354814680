export const NotificationTypes = [
  {value: 'sms', label: 'SMS'},
  {value: 'email', label: 'Email'},
] as const;

export type NotificationTypeValue = (typeof NotificationTypes)[number]['value'];

export enum NotificationKey {
  paymentSucceeded = 'payment_succeeded',
  instalmentReminder = 'instalment_reminder',
}

export enum NotificationSettingGroupName {
  paymentSuccess = 'payment_success',
  paymentReminder = 'payment_reminder',
}

export const NotificationSettingGroupConfig: Record<
  NotificationSettingGroupName,
  {
    name: NotificationSettingGroupName;
    label: string;
    description: string;
    settings?: NotificationKey[];
    defaults: {
      [K in NotificationTypeValue]?: boolean;
    };
  }
> = {
  [NotificationSettingGroupName.paymentSuccess]: {
    name: NotificationSettingGroupName.paymentSuccess,
    label: 'Successful payments',
    description: 'Notifications about successful payments.',
    settings: [NotificationKey.paymentSucceeded],
    defaults: {
      sms: true,
      email: false,
    },
  },
  [NotificationSettingGroupName.paymentReminder]: {
    name: NotificationSettingGroupName.paymentReminder,
    label: 'Upcoming payment reminders',
    description: 'Notifications about upcoming payment reminders.',
    settings: [NotificationKey.instalmentReminder],
    defaults: {
      sms: true,
      email: false,
    },
  },
};
