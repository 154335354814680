import {FC, ReactNode} from 'react';
import {FieldValues, UseFormReturn, useFormContext} from 'react-hook-form';

type FormConnectProps<T extends FieldValues = FieldValues> = {
  children: (values: T, form: UseFormReturn<T, any, undefined>) => ReactNode;
};

export const FormConnect: FC<FormConnectProps> = ({children}) => {
  const methods = useFormContext();
  return children(methods.watch(), {...methods});
};
