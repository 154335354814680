import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import {classNames} from 'lib/styles';

type TooltipMessageProps = {
  tooltipText?: string | null;
  tooltipTextColorAndHue: string;
  tooltipBgColorAndHue: string;
  children: React.ReactNode;
};

export const TooltipMessage: React.FC<TooltipMessageProps> = ({
  children,
  tooltipBgColorAndHue,
  tooltipTextColorAndHue,
  tooltipText,
}) => {
  return tooltipText && tooltipText !== '' ? (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={100}>
        <Tooltip.Trigger asChild>
          {React.Children.only(children)}
        </Tooltip.Trigger>
        <Tooltip.Content sideOffset={5} className="z-50">
          <Tooltip.Arrow
            className={classNames(
              'rotate-180 relative top-12 w-4 h-4',
              `fill-${tooltipBgColorAndHue}`
            )}
          />
          <span
            className={classNames(
              `bg-${tooltipBgColorAndHue}`,
              `text-${tooltipTextColorAndHue}`,
              'normal-case py-2 px-4 rounded shadow-md shadow-gray-300 relative top-[5.25rem] text-xs font-semibold'
            )}
          >
            {tooltipText}
          </span>
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  ) : (
    <>{React.Children.only(children)}</>
  );
};
