import React, {ReactNode} from 'react';
import {ErrorPage} from '../../features/navigation/Error';
import {useBillerConfig} from './useBillerConfig';

export const LivenessWrapper: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  const {isLive} = useBillerConfig();

  if (isLive) {
    return <>{children}</>;
  }

  const hostname = window.location.hostname.toLowerCase();

  // Don't allow access on exactly the production site
  if (hostname === 'app.payble.com.au') {
    return <ErrorPage />;
  }

  // Allow access on subdomains like -uat and -tomas
  if (hostname.endsWith('payble.com.au')) {
    return <>{children}</>;
  }

  // Allow local development
  if (hostname === 'localhost') {
    return <>{children}</>;
  }

  // Don't allow access on any other domain (such as .gov domains if not live)
  return <ErrorPage />;
};
