import React, {useState} from 'react';
import {PaymentMethodType} from '../../../lib/graphql/API';
import {usePaymentSurchargeInfo} from 'lib/appConfig/useBillerConfig';
import {toPaymentMethodType} from 'lib/types/DirectDebit';
import {SurchargeAgreementModal} from 'components/molecules/SurchargeAgreementModal';

type Props = {
  paymentMethodType: PaymentMethodType;
  showNotice: boolean;
  billerSlug: string;
};

export const NoticeOnSurcharge: React.FC<Props> = ({
  paymentMethodType,
  showNotice,
  billerSlug,
}) => {
  if (!showNotice) return null;

  const [surchargeModalOpen, setSurchargeModalOpen] = useState(false);
  const surcharge = usePaymentSurchargeInfo(
    toPaymentMethodType(paymentMethodType)
  );

  if (surcharge.type === 'SIMPLE') {
    if (billerSlug === 'hamilton-city-council') {
      return (
        <div className="border rounded-md shadow-sm bg-white p-4 relative mb-4 text-gray-900">
          <p className="text-md">
            Please note that a <b>{surcharge.rate.toPretty()}</b> surcharge
            applies to payments using a credit or debit card. This is because
            Council incurs additional costs for Visa and Mastercard
            transactions. No surcharge is applied to direct debit (bank account)
            transactions.
          </p>
        </div>
      );
    }
    return (
      <div className="border rounded-md shadow-sm bg-white p-4 relative mb-4 text-gray-900">
        <p className="text-md">
          Please note that a <b>{surcharge.rate.toPretty()}</b> credit card
          surcharge applies to all transactions made with a credit card. This
          fee is charged to cover the processing costs associated with credit
          card payments. We appreciate your understanding.
        </p>
      </div>
    );
  }

  if (surcharge.type === 'COMPLEX') {
    return (
      <div className="border rounded-md shadow-sm bg-white p-4 relative mb-4 text-gray-900">
        <p className="text-md">
          Please note that a{' '}
          <a
            className="text-blue-600 underline cursor-pointer"
            onClick={() => setSurchargeModalOpen(true)}
          >
            credit card surcharge applies
          </a>{' '}
          to all transactions made with a credit card. This fee is charged to
          cover the processing costs associated with credit card payments. We
          appreciate your understanding.
        </p>
        <SurchargeAgreementModal
          isOpen={surchargeModalOpen}
          setOpen={setSurchargeModalOpen}
          surcharge={surcharge.rateInfo}
        />
      </div>
    );
  }

  return null;
};
