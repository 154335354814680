import {AsyncResult} from '@unruly-software/result';
import {APIClient, apiSchema, consumerAPISpec} from 'payble-api-client';
import type {z} from 'zod';
import {AnalyticsContext} from './AnalyticsContext';

export type AnalyticsEventType = z.infer<
  (typeof apiSchema)['AnalyticsEvent']
>['type'];
interface InitArgs {
  api: APIClient<typeof consumerAPISpec>;
}

let singleton: Analytics | null = null;

// This is used due to axios not supporting "keepalive".
const sendAnalyticsEvents = AsyncResult.wrap(
  async (
    signal: AbortSignal,
    input: z.infer<(typeof apiSchema)['AnalyticsEventRequest']>
  ) => {
    const body =
      consumerAPISpec.analyticsPublishEvent.requestSchema.parse(input);
    const result = await fetch(
      '/api/v2' + consumerAPISpec.analyticsPublishEvent.path,
      {
        body: JSON.stringify(body),
        method: consumerAPISpec.analyticsPublishEvent.method,
        keepalive: true,
        signal,
      }
    );

    if (!result.ok) {
      throw new Error(
        `Failed to send analytics events. Got ${result.status}: ${await result.text()}`
      );
    }
  }
);

export class Analytics {
  constructor(
    private api: APIClient<typeof consumerAPISpec>,
    private context: AnalyticsContext
  ) {
    context.session.handleNewSession(() => this.addEvent('session_start'));
  }

  private isBlocked = false;

  static getSingleton(args: InitArgs) {
    if (singleton) {
      return singleton;
    }
    const context = new AnalyticsContext();
    const client = new Analytics(args.api, context);
    client.nextId = client.maxId + 1;
    singleton = client;

    context.session.sessionHeartbeat();
    client.identify();

    const ping = () => {
      if (context.storage.analyticsToken) {
        client.sendEvents();
        context.session.sessionHeartbeat();
      } else {
        client.identify();
      }
    };

    setInterval(ping, 2 * 1000);

    window.addEventListener('beforeunload', () => client.sendEvents());

    return client;
  }

  private failedIdentifies = 0;
  private currentlyIdentifying: Promise<unknown> | null = null;
  private async identify(analyticsToken = this.context.storage.analyticsToken) {
    if (this.failedIdentifies > 5 || this.isBlocked) {
      return;
    }
    if (!this.context.browser.isOnline || this.currentlyIdentifying) {
      return;
    }

    const abort = new AbortController();
    const timeout = setTimeout(
      () =>
        abort.abort(new Error('Timed out waiting to identify analytics token')),
      15 * 1000
    );
    this.currentlyIdentifying = this.api
      .request('analyticsIdentify', {
        abort: abort.signal,
        data: {
          billerSlug: this.context.browser.billerSlug,
          hostname: this.context.browser.hostname,
          analyticsToken: analyticsToken || undefined,
          language: this.context.browser.language,
          zoneOffset: this.context.browser.zoneOffset,
        },
      })
      .tap(
        ({analyticsToken}) => {
          if (analyticsToken === null) {
            this.isBlocked = true;
            return;
          }
          this.context.storage.analyticsToken = analyticsToken;
          this.sendEvents();
          this.failedIdentifies = 0;
        },
        err => {
          this.failedIdentifies++;
          console.error(err);
        }
      )
      .tapEither(() => {
        this.currentlyIdentifying = null;
        clearTimeout(timeout);
      })
      .get();
  }

  private currentlySending: Promise<unknown> | null = null;
  private async sendEvents() {
    if (
      !this.context.storage.analyticsToken ||
      !this.context.browser.isOnline
    ) {
      return;
    }
    const events = this.context.storage.events;
    if (events.length === 0 || this.currentlySending) {
      return;
    }
    if (events.length > 100) {
      console.error(`Too many events (${events.length}), clearing`);
      this.context.storage.events = [];
      return;
    }
    const toSend = events.slice(0, 10);

    const abort = new AbortController();
    const timeout = setTimeout(
      () =>
        abort.abort(
          new Error(
            `Timed out waiting to send ${events.length} analtics events`
          )
        ),
      15 * 1000
    );

    this.currentlySending = sendAnalyticsEvents(abort.signal, {
      analyticsToken: this.context.storage.analyticsToken,
      events: toSend.map(e => e.event),
    })
      .tap(() => this.removeEvents(events.map(e => e.id)), console.error)
      .tapEither(() => {
        this.currentlySending = null;
        clearTimeout(timeout);
      })
      .get();
  }

  private nextId = 1;
  private getNewId() {
    return this.nextId++;
  }

  reidentify() {
    if (this.isBlocked) {
      return;
    }
    const token = this.context.storage.analyticsToken;
    this.identify(token);
  }

  addEvent(type: AnalyticsEventType, meta: Record<string, any> = {}) {
    try {
      if (this.isBlocked) {
        return;
      }
      console.log(type, meta);
      this.context.storage.events = [
        ...this.context.storage.events,
        {
          id: this.getNewId(),
          event: {
            type,
            path: this.context.browser.currentPath,
            urlParams: this.context.browser.urlParams,
            timestamp: new Date().toISOString(),
            userAgent: this.context.browser.userAgent,
            session: this.context.session.sessionId,
            referrer: this.context.browser.referrer,
            meta,
          },
        },
      ];
    } catch (e) {
      console.error(e);
    }
  }

  private removeEvents(ids: number[]) {
    this.context.storage.events = this.context.storage.events.filter(
      e => !ids.includes(e.id)
    );
  }

  private get maxId() {
    return this.context.storage.events.reduce(
      (max, e) => Math.max(max, e.id),
      0
    );
  }
}

// This only exists to let me be lazy and not have to pass the api around
export function getAnalytics(api: APIClient<typeof consumerAPISpec>) {
  return Analytics.getSingleton({api: api});
}
