import React, {Fragment, useRef} from 'react';
import {Dialog, Transition} from '@headlessui/react';

export const SurchargeAgreementModal: React.FC<{
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  surcharge: {
    [key: string]: string;
  };
}> = ({isOpen, setOpen, surcharge}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-left sm:mt-5">
                  <table className="w-full">
                    <thead className="border-b">
                      <tr className="border-b text-gray-500 bg-gray-100">
                        <th className="h-12 align-middle font-medium text-muted-foreground px-4 py-2 text-left">
                          Payment Method
                        </th>
                        <th className="h-12 align-middle font-medium text-muted-foreground px-4 py-2 text-right">
                          Surcharge
                        </th>
                      </tr>
                    </thead>
                    <tbody className="[&_tr:last-child]:border-0">
                      {Object.entries(surcharge).map(([card, surcharge]) => (
                        <tr key={card} className=" text-gray-500 border-b">
                          <td className="p-4 py-2 align-middle px-4 py-2 text-left">
                            {card}
                          </td>
                          <td className="p-4 py-2 align-middle px-4 py-2 text-right">
                            {surcharge}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 inline-flex justify-center align-center w-full">
                <button
                  type="button"
                  className="inline-flex justify-center align-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={() => setOpen(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
