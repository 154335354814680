import {Debbie} from 'components/organisms/Debbie';
import {CheckIcon} from '@heroicons/react/20/solid';
import {useInfringementNavigate} from '../hooks/useInfringementNavigate';
import {goBack} from 'lib/navigation/routes';

export const PayNowFeatures = () => {
  const infringementNavigate = useInfringementNavigate();

  return (
    <>
      <Debbie title="You have selected to pay your total now" />
      <div className="mt-4 overflow-hidden rounded-md shadow">
        <div className="px-4 py-4 bg-white sm:p-6">
          <div className="flex flex-col justify-center flex-1 h-full ">
            <h3 className="mb-2 text-base font-medium text-left sm:text-lg">
              This means that you
            </h3>
            <dl className="text-base leading-7 text-gray-600">
              {[
                'Can pay using a credit or debit card',
                'Will receive a receipt for each payment',
                'Will have your infringement paid in full with Council',
              ].map((feature, key) => (
                <div key={key} className="relative mt-3 pl-9">
                  <dt className="text-gray-900 font-regular">
                    <CheckIcon
                      className="absolute left-0 w-5 h-5 text-blue-900 top-1"
                      aria-hidden="true"
                    />
                    {feature}
                  </dt>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={() => {
          infringementNavigate('/pay-now');
        }}
      >
        Next
      </button>
      <button
        className="mt-6 text-blue-600 transition hover:text-blue-700"
        onClick={goBack}
      >
        Back
      </button>
    </>
  );
};
