import {twMerge} from 'tailwind-merge';

/**
 * It takes an array of strings, filters out the falsy values, and joins the remaining strings with a
 * space
 * @param {string[]} classes - string[] - This is a rest parameter. It means that the function can take
 * any number of parameters.
 * @returns A string of all the classes that are truthy.
 */
export const classNames = (...classes: string[]) => {
  return twMerge(classes.filter(Boolean).join(' '));
};
