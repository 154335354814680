export const CARD_BRAND = [
  'AMEX',
  'DINERS_CLUB',
  'DISCOVER',
  'JCB',
  'MASTERCARD',
  'VISA',
  'UNIONPAY',
] as const;

export type CardBrand = (typeof CARD_BRAND)[number];

/**
 * We use "type" but a card may have both a funding type and a type.
 * https://docs.tokenex.com/docs/bin-lookup-overview#type-vs-fundingsource
 */
export const CARD_TYPE = [
  'CREDIT',
  'DEBIT',
  'PREPAID',
  'CHARGE_CARD',
  'DEFERRED_DEBIT',
] as const;
export type CardType = (typeof CARD_TYPE)[number];

const CARD_TYPE_TO_PRETTY: Record<CardType, string> = {
  CREDIT: 'Credit',
  DEBIT: 'Debit',
  PREPAID: 'Prepaid',
  CHARGE_CARD: 'Charge Card',
  DEFERRED_DEBIT: 'Debit',
};

const CARD_BRAND_TO_PRETTY: Record<CardBrand, string> = {
  AMEX: 'American Express',
  DINERS_CLUB: 'Diners Club',
  DISCOVER: 'Discover',
  JCB: 'JCB',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  UNIONPAY: 'UnionPay',
};

export const cardBrandToPretty = (brand: CardBrand) => {
  const pretty = CARD_BRAND_TO_PRETTY[brand];
  if (!pretty) {
    throw new Error(`Unknown card brand ${brand}`);
  }
  return pretty;
};

export const cardTypeToPretty = (type: CardType) => {
  const pretty = CARD_TYPE_TO_PRETTY[type];
  if (!pretty) {
    throw new Error(`Unknown card type ${type}`);
  }
  return pretty;
};

export interface TokenexCardInfo {
  brand: CardBrand;
  type: CardType;
  /**
   * @example 'US' | 'AU'
   */
  countryAlphaCode?: string;
  productName?: string;
  issuerCurrency?: string;
}
