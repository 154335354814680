export const possessive = (str: string) => {
  if (str.endsWith('s')) {
    return str + "'";
  }
  return str + "'s";
};

const domainNameRegex = /^([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/;

export const isJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const intFromString = (str: string) => {
  const num = parseInt(str, 10);
  if (isNaN(num)) {
    return null;
  }
  return num;
};

export const isDomainName = (domain: string) => domainNameRegex.test(domain);
