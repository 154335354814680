import * as React from 'react';
import {cn, cva} from '../lib/utils';

export interface TextareaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'> {
  size?: 'default' | '4xl' | '2xl';
}

const textareaVariants = cva(
  'caret-primary flex text-foreground-400 w-full shadow-sm rounded-md border border-input px-3 py-2 text-sm ring-offset-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:border-transparent disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-foreground-300',
  {
    variants: {
      size: {
        default: 'font-medium',
        '2xl':
          'text-2xl font-bold border-0 text-foreground shadow-none text-center focus-visible:ring-0',
        '4xl':
          'text-4xl font-bold border-0 text-foreground shadow-none text-center focus-visible:ring-0',
      },
      focused: {
        true: 'ring-2 ring-ring border-transparent',
        false: '',
      },
      readOnly: {
        true: 'bg-transparent',
        false: 'bg-background',
      },
    },
    defaultVariants: {
      size: 'default',
      focused: false,
      readOnly: false,
    },
  }
);

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({className, size, ...props}, ref) => {
    return (
      <textarea
        className={cn(textareaVariants({size, className}))}
        ref={ref}
        {...props}
      ></textarea>
    );
  }
);
Textarea.displayName = 'Textarea';

export {Textarea, textareaVariants};
