import React, {useEffect} from 'react';
import {useParams} from 'react-router';
import {Formik} from 'formik';

import {getBillerSlugFromUrl} from 'lib/url';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {
  InstalmentPlanMode,
  useGetInstalmentPlanQuery,
  useUpdateInstalmentAmountMutation,
} from 'lib/graphql/API';
import {Debbie} from 'components/organisms/Debbie';
import {navigate} from 'lib/navigation/routes';
import {HomeIcon} from '@heroicons/react/20/solid';
import {buttonClasses} from 'lib/styles';
import {ButtonSpinner} from 'components/atoms/Spinner';
import {TryAgain} from 'components/molecules/TryAgain';
import {formatToCents, formatToDollars} from 'payble-shared';
import CurrencyInput from 'react-currency-input-field';

export const UpdateAmount: React.FC = () => {
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const billerSlug = getBillerSlugFromUrl();

  useEffect(() => {
    document.title = 'Payble - Update payment amount';
  }, []);

  if (!instalmentPlanId) {
    return <>Cannot load payment plan without an ID</>;
  }

  if (billerSlug === '') {
    return <>Cannot load without a biller slug</>;
  }

  const {
    loading: instalmentPlanLoading,
    error: instalmentPlanError,
    data: instalmentPlanData,
  } = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
    fetchPolicy: 'network-only',
  });

  const [
    submitUpdateAmount,
    {loading: submittingUpdateAmount, error: updateAmountError},
  ] = useUpdateInstalmentAmountMutation();

  const nextInstalment = instalmentPlanData?.instalmentPlan?.instalments.find(
    x => x.status === 'overdue' || x.status === 'scheduled'
  );

  if (instalmentPlanLoading) {
    return <Loading />;
  }

  if (instalmentPlanError)
    return (
      <TryAgain
        errorMessage={instalmentPlanError.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug: billerSlug});
        }}
      />
    );

  return (
    <div className="relative">
      <Debbie
        title={'Update payment amount'}
        message={
          instalmentPlanData?.instalmentPlan?.mode ===
          InstalmentPlanMode.SmoothPay
            ? "This will update the amount you're paying between billing periods."
            : 'This will update the amount you pay next.'
        }
      />

      <nav className="flex mt-5 mb-2" aria-label="Breadcrumb">
        <ol
          role="list"
          className="flex px-6 space-x-4 bg-white rounded-md shadow"
        >
          <li className="flex">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/biller/:slug', {slug: billerSlug})}
                className="text-gray-400 hover:text-gray-500"
              >
                <HomeIcon
                  className="flex-shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </button>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <button
                onClick={() =>
                  navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
                    slug: billerSlug,
                    instalmentPlanId,
                  })
                }
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Payment Plan
              </button>
            </div>
          </li>
        </ol>
      </nav>

      <div className="mt-5 overflow-hidden shadow sm:rounded-md">
        <Formik<{amount?: number}>
          initialValues={{
            amount: nextInstalment
              ? formatToDollars(nextInstalment.amount)
              : undefined,
          }}
          onSubmit={async ({amount}, {setSubmitting}) => {
            if (!amount) {
              return;
            }
            setSubmitting(true);
            await submitUpdateAmount({
              variables: {
                input: {
                  instalmentPlanId,
                  amount: amount,
                },
              },
            });

            setSubmitting(false);

            return navigate('/biller/:slug/instalment-plan/:instalmentPlanId', {
              slug: billerSlug,
              instalmentPlanId,
            });
          }}
        >
          {({
            values,

            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="flex flex-col justify-center flex-1 h-full ">
                  <h3 className="mb-2 text-base font-medium text-left sm:text-lg">
                    New Payment amount
                  </h3>
                  <CurrencyInput
                    className={
                      'focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md'
                    }
                    placeholder="Please enter an amount"
                    prefix="$"
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    defaultValue={
                      nextInstalment
                        ? formatToDollars(nextInstalment.amount)
                        : undefined
                    }
                    onValueChange={(value, __name) => {
                      if (value === undefined) {
                        setFieldValue('amount', undefined);
                        return;
                      }

                      value &&
                        setFieldValue(
                          'amount',
                          formatToCents(parseFloat(value))
                        );
                    }}
                  />
                </div>

                {updateAmountError && (
                  <div>
                    <ErrorMessage message={updateAmountError.message} />
                  </div>
                )}

                <button
                  className={buttonClasses}
                  disabled={
                    isSubmitting || submittingUpdateAmount || !values.amount
                  }
                >
                  {(isSubmitting || submittingUpdateAmount) && (
                    <ButtonSpinner />
                  )}
                  Update Payment Amount
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
