import {getUrlParams} from 'lib/url';

export type Session = SessionEmpty | SessionLegacy | SessionV1;

export type SessionEmpty = {type: 'empty'};

export type SessionV1 = {
  type: 'v1';
  accountType: string;
  accountExternalId: string;
  source?: string;
  verificationCode?: string;
};

export type SessionLegacy = {
  type: 'legacy';
  customerReferenceId?: string;
  customerAccountName?: string;
  source?: string;
};

export function loadSessionData(): Session {
  const params = getUrlParams();

  // Multiple types of session exist:

  // 1. A vanilla login via a link (no params)
  // 2. A generated magic link from an SMS (params)
  // 3. A QR code link for Energy on in a legacy format
  // 4. A QR code link for KCC or MBRC in a newer format

  // Try to identify a new QR code from KCC or MBRC
  // ?t=rates&e=100009-8&s=notice-202207&v=1

  // verification code appended (optional) params["a"]
  // "a" = authorization

  // Mornington Peninsula Shire Council forgot to set the param t and just shipped with an empty string
  // The rate notices with this issue were posted august 2024

  let accountType = (params.t ?? params[''])?.toLowerCase();

  if (params.v && params.v === '1' && accountType && params.e) {
    if (accountType?.trim() === 'instalment') {
      /**
       * Cessnock City Council shipped a QR code that used "instalment" as the
       * account type instead of rates in late 2023. This patch can likely be
       * removed after mid 2024
       */
      accountType = 'rates';
    }

    const accountExternalId = params.e;
    const verificationCode = params.a;
    const source = params.s;

    return {
      type: 'v1',
      accountType,
      accountExternalId,
      verificationCode,
      source,
    };
  }

  // Identify a QR code from EnergyOn
  if (params.customerId && params.customerId.length > 0) {
    let source = params.utm_source;
    /**
     * Backwards compatibility with Energy On
     * Assume if the CustomerID was embedded in the URL they came from a dynamic QR code
     */
    if (!source && !!params.customerId) {
      source = 'qrcode';
    }
    return {
      type: 'legacy',
      customerReferenceId: params.customerId,
      customerAccountName: params.customerAccountName,
      source,
    };
  }

  // This is a session without any data
  return {
    type: 'empty',
  };
}
