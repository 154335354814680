import React, {Fragment} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/20/solid';
import {InstalmentPlanFragment, InstalmentPlanMode} from 'lib/graphql/API';
import {classNames} from 'lib/styles';
import {navigate} from 'lib/navigation/routes';

type InstalmentPlanMenuProps = {
  instalmentPlan: InstalmentPlanFragment;
  slug: string;
  isPaymentMethodActive: boolean;
  canUpdateInstalmentAmount: boolean;
};

export const InstalmentPlanMenu: React.FC<InstalmentPlanMenuProps> = ({
  instalmentPlan,
  slug,
  isPaymentMethodActive,
  canUpdateInstalmentAmount,
}) => {
  const isInstalmentProcessing =
    instalmentPlan.instalments.filter(x => x.status === 'processing').length >
    0;

  const canCancel =
    instalmentPlan.status !== 'cancelled' &&
    instalmentPlan.status !== 'completed';

  const canSkipInstalment =
    !isInstalmentProcessing &&
    instalmentPlan.status !== 'cancelled' &&
    instalmentPlan.status !== 'completed' &&
    instalmentPlan.status !== 'processing';

  const canPayRemainder =
    !isInstalmentProcessing &&
    instalmentPlan.status !== 'cancelled' &&
    instalmentPlan.status !== 'completed' &&
    instalmentPlan.status !== 'processing' &&
    isPaymentMethodActive;

  const canChangePaymentMethod =
    instalmentPlan.status !== 'cancelled' &&
    instalmentPlan.status !== 'completed';

  const cancel = () => {
    if (canCancel) {
      navigate('/biller/:slug/instalment-plan/:instalmentPlanId/cancel', {
        instalmentPlanId: instalmentPlan.id,
        slug,
      });
    }
  };

  const skipInstalment = () => {
    if (canSkipInstalment) {
      navigate(
        '/biller/:slug/instalment-plan/:instalmentPlanId/skip-instalment',
        {
          instalmentPlanId: instalmentPlan.id,
          slug,
        }
      );
    }
  };

  const payRemainder = () => {
    if (canPayRemainder) {
      navigate(
        '/biller/:slug/instalment-plan/:instalmentPlanId/pay-remainder',
        {
          instalmentPlanId: instalmentPlan.id,
          slug,
        }
      );
    }
  };

  const updateAmount = () => {
    if (canUpdateInstalmentAmount) {
      navigate(
        '/biller/:slug/instalment-plan/:instalmentPlanId/update-amount',
        {
          instalmentPlanId: instalmentPlan.id,
          slug,
        }
      );
    }
  };

  const changePaymentMethod = () => {
    if (canChangePaymentMethod) {
      navigate(
        '/biller/:slug/instalment-plan/:instalmentPlanId/change-payment-method',
        {
          instalmentPlanId: instalmentPlan.id,
          slug,
        }
      );
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="h-[44px] transition inline-flex justify-center w-full rounded-md border shadow px-5 py-2 border-transparent bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
          <span className="mt-1">Options</span>
          <ChevronDownIcon
            className="mt-1 -mr-1 ml-2 h-5 w-5"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            {instalmentPlan.mode === InstalmentPlanMode.PayEveryX && (
              <Menu.Item>
                {({active}) => (
                  <div
                    onClick={payRemainder}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer',
                      !canPayRemainder ? 'opacity-50 cursor-not-allowed' : ''
                    )}
                  >
                    <ArchiveBoxIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Pay remainder
                  </div>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({active}) => (
                <div
                  onClick={changePaymentMethod}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer',
                    !canChangePaymentMethod
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  )}
                >
                  <ArrowRightCircleIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Change Payment Method
                </div>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            {canUpdateInstalmentAmount && (
              <Menu.Item>
                {({active}) => (
                  <div
                    onClick={updateAmount}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer',
                      !canUpdateInstalmentAmount
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    )}
                  >
                    <CurrencyDollarIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Update Payment Amount
                  </div>
                )}
              </Menu.Item>
            )}
          </div>
          <div className="py-1 z-50">
            {instalmentPlan.mode !== InstalmentPlanMode.PayXOnY &&
              instalmentPlan.mode !== InstalmentPlanMode.PayOnY && (
                <Menu.Item>
                  {({active}) => (
                    <div
                      onClick={skipInstalment}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm cursor-pointer',
                        !canSkipInstalment
                          ? 'opacity-50 cursor-not-allowed'
                          : ''
                      )}
                    >
                      <DocumentDuplicateIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Skip Next Payment
                    </div>
                  )}
                </Menu.Item>
              )}
            <Menu.Item>
              {({active}) => (
                <div
                  onClick={cancel}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer',
                    !canCancel ? 'opacity-50 cursor-not-allowed' : ''
                  )}
                >
                  <PencilSquareIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Cancel Payment Plan
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
