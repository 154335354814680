import React, {ReactChild} from 'react';
import {DateTime} from 'luxon';
import {InstalmentPlanMode, Payment, PaymentMode} from 'lib/graphql/API';
import {CreatedInstalmentPlan} from '../graphql/actions';
import {useBillerConfig} from 'lib/appConfig/useBillerConfig';

export const ConfirmationPaymentDateSection = ({
  payment,
  instalmentPlan,
}: {
  payment?: Payment;
  instalmentPlan?: CreatedInstalmentPlan;
}) => {
  const billerConfig = useBillerConfig();
  if (payment?.mode === PaymentMode.PayInFull) {
    return (
      <PaymentDateSectionCopy
        label={'Payment date'}
        description={DateTime.local({zone: 'Australia/Sydney'}).toLocaleString(
          DateTime.DATE_FULL
        )}
      />
    );
  }
  if (payment?.mode === PaymentMode.PayAmount) {
    return (
      <PaymentDateSectionCopy
        label={'Payment date'}
        description={DateTime.local({zone: 'Australia/Sydney'}).toLocaleString(
          DateTime.DATE_FULL
        )}
      />
    );
  }

  if (instalmentPlan) {
    const label =
      instalmentPlan.mode === InstalmentPlanMode.PayOnY
        ? 'Payment date'
        : 'First payment date';

    return (
      <PaymentDateSectionCopy
        label={label}
        description={
          instalmentPlan.nextInstalmentDueAt
            ?.toDateTime({billerConfig})
            ?.toLocaleString(DateTime.DATE_FULL) ?? 'Check your notice'
        }
      />
    );
  }

  return null;
};

function PaymentDateSectionCopy({
  label,
  description,
}: {
  label: string;
  description: ReactChild;
}) {
  return (
    <>
      <p id="payment-date-label" className="text-sm text-gray-600">
        {label}
      </p>
      <p id="payment-date-description" className="text-sm text-gray-600">
        {description}
      </p>
    </>
  );
}
