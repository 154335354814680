import React from 'react';
import {AccountTypeInfo, getBillerConfig} from 'payble-shared';
import {RadioGroup} from '@headlessui/react';
import {CheckCircleIcon} from '@heroicons/react/20/solid';
import {classNames} from 'lib/styles';
import {useField, useFormikContext} from 'formik';
import {getBillerSlugFromUrl} from 'lib/url';

type LookupAccountTypeRadioProps = {
  supportedPaymentCategory: AccountTypeInfo['paymentCategory'];
};

export const LookupAccountTypeRadio: React.FC<LookupAccountTypeRadioProps> = ({
  supportedPaymentCategory,
}) => {
  const billerSlug = getBillerSlugFromUrl();
  const billerConfig = getBillerConfig(billerSlug);
  const accountTypesInfo = billerConfig
    .getAccountTypesInfo()
    .filter(
      ({paymentCategory}) => paymentCategory === supportedPaymentCategory
    );

  const [field] = useField('accountType');
  const {setFieldValue} = useFormikContext();

  return (
    <>
      <label
        htmlFor="accountType"
        className="block text-sm font-bold text-navy"
      >
        Account type
      </label>
      <RadioGroup
        value={field.value}
        onChange={v => {
          setFieldValue('accountType', v);
        }}
      >
        <RadioGroup.Label className="text-sm font-medium text-gray-900">
          Select an account type to lookup
        </RadioGroup.Label>

        <div className="grid grid-cols-1 mt-4 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          {accountTypesInfo.map(({id, title, description}) => (
            <RadioGroup.Option
              key={id}
              value={id}
              className={({checked, active}) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-blue-500 ring-2 ring-blue-500' : '',
                  'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                )
              }
            >
              {({checked, active}) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label className="block text-sm font-medium text-gray-900">
                        {title}
                      </RadioGroup.Label>
                      <RadioGroup.Description className="flex items-center mt-1 text-sm text-gray-500">
                        {description}
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? 'invisible' : '',
                      'h-5 w-5 text-blue-600'
                    )}
                    aria-hidden="true"
                  />
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-blue-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg pointer-events-none'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
};
