import {addContactToAccount, getAuthKey} from 'features/setup/graphql/actions';
import {err} from 'payble-shared';
import {useCallback, useState} from 'react';

type LinkAccountProps = {
  accountExternalId: string;
  accountType: string;
  billerSlug: string;
  verificationCode: string;
};

export function useLinkAccount() {
  const [loading, setLoading] = useState(false);

  const linkAccount = useCallback(
    async ({
      accountExternalId,
      accountType,
      verificationCode,
    }: LinkAccountProps) => {
      setLoading(true);

      const account = await addContactToAccount(
        accountExternalId,
        accountType,
        verificationCode
      );

      if (err(account)) {
        setLoading(false);
        throw account;
      }

      const authKey = await getAuthKey();
      setLoading(false);

      if (err(authKey)) {
        throw authKey;
      }
      return {account, authKey};
    },
    [setLoading]
  );

  return {
    loading,
    linkAccount,
  };
}
