import {FC, ReactNode} from 'react';
import {Label} from './Label';
import {Text} from './Text';
import {FormFieldError} from './FormFieldError';
import {cn, cva} from '../lib/utils';

export interface FormFieldProps
  extends React.HTMLAttributes<Omit<HTMLDivElement, 'label'>> {
  children: ReactNode;
  name: string;
  label?: ReactNode;
  labelPosition?: 'top' | 'left' | 'between';
  description?: ReactNode;
}

const fieldVariants = cva('flex gap-3', {
  variants: {
    labelPosition: {
      top: 'flex-col',
      left: 'items-center',
      between: 'justify-between',
    },
  },
  defaultVariants: {
    labelPosition: 'top',
  },
});

export const FormField: FC<FormFieldProps> = ({
  label,
  labelPosition = 'top',
  description,
  name,
  id,
  children,
  className,
  ...props
}) => {
  return (
    <div {...props} className={cn(fieldVariants({labelPosition, className}))}>
      {label && (
        <Label htmlFor={id}>
          {label}
          {description && <Text variant="muted">{description}</Text>}
        </Label>
      )}
      {children}
      <FormFieldError name={name} />
    </div>
  );
};
