import {InstalmentPlan, InstalmentPlanMode} from 'lib/graphql/API';

export function getInstalmentPlanHeading(instalmentPlan: InstalmentPlan) {
  let heading;

  switch (instalmentPlan.mode) {
    case InstalmentPlanMode.PayXOnY:
      heading = 'autopay';
      break;

    case InstalmentPlanMode.PayOnY:
      heading = 'scheduled one-off payment';
      break;

    default:
      heading = instalmentPlan.frequency
        ? `flexible ${instalmentPlan.frequency} payment`
        : 'flexible payment';
      break;
  }

  return heading;
}
