export const toSentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
};

export const plural = (str: string) => {
  if (str.endsWith('s')) {
    return str + "'";
  }
  return str + "'s";
};
