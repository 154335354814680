import {ExclamationCircleIcon} from '@heroicons/react/24/outline';
import {navigate} from 'lib/navigation/routes';
import {buttonClasses} from 'lib/styles';
import React, {Dispatch} from 'react';
import {Stream} from '@cloudflare/stream-react';

type CancelChangePaymentMethodProps = {
  billerSlug: string;
  instalmentPlanId: string;
  setShowConfirmation: Dispatch<React.SetStateAction<boolean>>;
};

export const CancelChangePaymentMethod: React.FC<
  CancelChangePaymentMethodProps
> = ({billerSlug, instalmentPlanId, setShowConfirmation}) => {
  const htmlId = 'manage-plan';
  const cloudfareStreamVideoID =
    'https://iframe.cloudflarestream.com/8379649a1ba9f8e664e1b7c6a27474dc?preload=metadata';
  return (
    <>
      {' '}
      <ExclamationCircleIcon className="self-center w-20 mb-5" />
      <div className="mb-3">
        Did you know you can change your payment method without cancelling your
        plan?
        <br />
        <br />
        View our how-to video:
        <div id={htmlId} className="w-full mt-2 aspect-video">
          <Stream controls responsive src={cloudfareStreamVideoID} />
        </div>
      </div>
      <button
        className={buttonClasses}
        onClick={() => {
          navigate(
            '/biller/:slug/instalment-plan/:instalmentPlanId/change-payment-method',
            {slug: billerSlug, instalmentPlanId}
          );
        }}
      >
        Change payment method
      </button>
      <div
        // to={`/biller/${billerSlug}/instalment-plan/${instalmentPlanId}/cancel`}
        onClick={() => {
          setShowConfirmation(true);
          navigate('/biller/:slug/instalment-plan/:instalmentPlanId/cancel', {
            slug: billerSlug,
            instalmentPlanId,
          });
        }}
        className="text-center mt-5 cursor-pointer"
      >
        Continue to cancel
      </div>
    </>
  );
};
