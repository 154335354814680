import {z} from 'zod';
import {
  AnalyticsEventRequest,
  AnalyticsIdentifyRequest,
  AnalyticsIdentifyResponse,
} from '../schemas/analytics';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';

// This is backed by an SQS Queue. No handler should be invoked for this
// endpoint but we use the API client to simplify the interface.
export const analyticsPublishEvent = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'POST /an/events',
  requestSchema: AnalyticsEventRequest,
  responseSchema: z.unknown(),
});

export const analyticsIdentify = defineHTTPEndpoint({
  authn: 'PUBLIC',
  operation: 'POST /an/identify',
  requestSchema: AnalyticsIdentifyRequest,
  responseSchema: AnalyticsIdentifyResponse,
});
