import DOMPurify from 'dompurify';
import snarkdown from 'snarkdown';

export function markDownToHtml(
  markdown: string,
  allowedTags: string[]
): string {
  // hook to make all links to open a new window
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });
  return DOMPurify.sanitize(snarkdown(markdown), {
    ALLOWED_TAGS: allowedTags,
  });
}
