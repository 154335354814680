import {HomeIcon} from '@heroicons/react/20/solid';
import {useActor} from '@xstate/react';
import {Loading} from 'components/atoms/Loading';
import {TryAgain} from 'components/molecules/TryAgain';
import {Debbie} from 'components/organisms/Debbie';
import {
  InstalmentPlan,
  InstalmentPlanMode,
  PaymentMethodStatus,
  useGetInstalmentPlanQuery,
} from 'lib/graphql/API';
import {navigate} from 'lib/navigation/routes';
import {getBillerSlugFromUrl} from 'lib/url';
import {DateTime} from 'luxon';
import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router';
import {InstalmentPlanMenu} from '../components/InstalmentPlanMenu';
import {InstalmentTable} from '../components/InstalmentTable';
import * as Auth from 'lib/auth/Auth';
import {RolloverNotice} from 'components/atoms/RolloverNotice';
import {getYearlyRollOverStatusByBillerSlug} from 'payble-shared/src/biller-config/handlers';

export const Schedule: React.FC = () => {
  const {instalmentPlanId} = useParams<{instalmentPlanId: string}>();
  const slug = getBillerSlugFromUrl();
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const hasYearlyRollOver = getYearlyRollOverStatusByBillerSlug(slug);

  useEffect(() => {
    document.title = 'Payble - Payment Schedule';
  }, []);

  if (!instalmentPlanId) return <>Cannot load payment plan without an ID</>;

  const {loading, error, data} = useGetInstalmentPlanQuery({
    variables: {
      id: instalmentPlanId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loading />;
  if (error)
    return (
      <TryAgain
        errorMessage={error.message}
        onClick={() => {
          navigate('/biller/:slug/setup', {slug});
        }}
      />
    );
  if (!data || !data?.instalmentPlan) return <>No payment plan found</>;

  const currentPaymentMethod = authState.context.contact?.paymentMethods?.find(
    p => p.id === data.instalmentPlan?.paymentMethodId
  );

  const isPaymentMethodActive =
    currentPaymentMethod?.status === PaymentMethodStatus.Active;
  return (
    <div className="relative">
      <Debbie
        title={`Your schedule for #${data.instalmentPlan.account?.externalId}`}
        message={getDebbieMessage(data.instalmentPlan as InstalmentPlan)}
      />
      <div className="flex mt-5 mb-2">
        <div className="flex-1">
          <nav className="flex" aria-label="Breadcrumb">
            <ol
              role="list"
              className="flex px-6 space-x-4 bg-white rounded-md shadow"
            >
              <li className="flex">
                <div className="flex items-center">
                  <button
                    onClick={() => navigate('/biller/:slug', {slug})}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </button>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <button
                    onClick={() =>
                      navigate(
                        '/biller/:slug/instalment-plan/:instalmentPlanId',
                        {slug, instalmentPlanId}
                      )
                    }
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    Payment Plan
                  </button>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="flex pl-4">
          <InstalmentPlanMenu
            slug={slug}
            instalmentPlan={data?.instalmentPlan}
            isPaymentMethodActive={isPaymentMethodActive}
            canUpdateInstalmentAmount={
              data.instalmentPlan.canUpdateInstalmentAmount
            }
          />
        </div>
      </div>

      <div className="mt-5 overflow-hidden shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <InstalmentTable instalmentPlan={data.instalmentPlan} />
          <RolloverNotice showNotice={hasYearlyRollOver} />
        </div>
      </div>
    </div>
  );
};

function getDebbieMessage(instalmentPlan: InstalmentPlan) {
  if (instalmentPlan.mode === InstalmentPlanMode.PayXOnY) {
    if (!instalmentPlan.nextInstalmentDueAt) {
      return 'You have registered for autopay. No payments have been scheduled yet. Please check your notice.';
    } else {
      return `You have registered for autopay. Your next payment will be on ${instalmentPlan.nextInstalmentDueAt.toFormat(
        'dd LLL yyyy'
      )}.`;
    }
  } else {
    const frequency = instalmentPlan.frequency;
    const targetDate = instalmentPlan.targetDate
      ? instalmentPlan.targetDate.toFormat('dd LLL yyyy')
      : null;

    let startDate = DateTime.fromISO(instalmentPlan.createdAt).toFormat(
      'dd LLL yyyy'
    );

    if (instalmentPlan.instalments[0]) {
      const {dueAt} = instalmentPlan.instalments[0];
      startDate = dueAt.toFormat('dd LLL yyyy');
    }

    return `You started the ${
      frequency ? frequency : 'payment'
    } plan on ${startDate}${
      targetDate
        ? ` and it will finish on ${targetDate}. There are ${instalmentPlan.instalments.length} payments remaining.`
        : '.'
    }`;
  }
}
