import {markDownToHtml} from 'lib/markDownToHtml';
import {getBillerConfig} from 'payble-shared';
import React from 'react';

type Props = {
  billerSlug: string;
};
export const NoticeOnInterest: React.FC<Props> = ({billerSlug}) => {
  const billerConfig = getBillerConfig(billerSlug);

  if (billerConfig.noticeOnInterest) {
    return billerConfig.noticeOnInterest.map(p => (
      <p
        className="text-sm text-gray-600 [&>h4]:font-medium [&>h4]:text-black [&>h4]:text-left [&>h4]:text-base [&>h4]:mt-6 mb-4"
        dangerouslySetInnerHTML={{
          __html: markDownToHtml(p, ['h4']),
        }}
      />
    ));
  }
};
