import {Debbie} from 'components/organisms/Debbie';
import {useGetAccountFromSearchParams} from 'lib/account/useGetAccountFromSearchParams';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {formatToDollars, AbsoluteDate} from 'payble-shared';

import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';

import NumberFormat from 'react-number-format';
import {goBack} from 'lib/navigation/routes';
import {useInfringementNavigate} from '../hooks/useInfringementNavigate';
import {BeautifiedErrorMessage} from 'components/organisms/BeautifiedErrormessage';

function StatusPill({status, color}: {status: string; color: string}) {
  let bg = '';

  switch (color) {
    case 'red':
      bg = 'bg-red-400';
      break;
    case 'amber':
      bg = 'bg-red-300';
      break;
    case 'green':
      bg = 'bg-green-300';
      break;
  }

  return (
    <span
      className={`inline-flex px-4 py-2 text-xs leading-5 text-white rounded-full ${bg}`}
    >
      {status}
    </span>
  );
}

const InfringementStatus: React.FC<{
  targetDate: AbsoluteDate;
  dueDate: AbsoluteDate;
  now: AbsoluteDate;
  collectionExemption: boolean;
}> = ({targetDate, dueDate, now, collectionExemption}) => {
  if (now.isAfter(targetDate) || collectionExemption) {
    return <StatusPill status="Unable to pay" color="red" />;
  }

  if (now.isBefore(dueDate)) {
    return <StatusPill status="Ready for payment" color="green" />;
  }

  if (now.isAfter(dueDate) && now.isBefore(targetDate)) {
    return <StatusPill status="At risk for penalties" color="amber" />;
  }
};

export const ConfirmAccountPayment = () => {
  const {
    loading,
    data: account,
    error,
  } = useGetAccountFromSearchParams({anonymous: true});
  const infringementNavigate = useInfringementNavigate();
  const billerConfig = useBillerConfig();

  const now = AbsoluteDate.today({billerConfig});

  const lastDayToSetupPlan = now.minus({
    days: 8,
  });

  if ((loading || !account) && !error) return <Loading />;

  if (error) {
    return (
      <BeautifiedErrorMessage
        debbieTitle="Oops, something went wrong!!"
        debbieMessage={error}
        onClick={() => {
          infringementNavigate('/');
        }}
      />
    );
  }

  return (
    <div className="relative flex flex-col h-full gap-4">
      <Debbie title="Here’s your infringement information" />
      <div className="mt-4 overflow-hidden rounded-md shadow">
        <div className="px-4 py-4 bg-white sm:p-6">
          <div className="border-b">
            <div className="grid grid-cols-2 gap-4 pb-4">
              <div>
                <h4 className="mb-2 font-medium text-gray-900">
                  Infringement number
                </h4>
                <p className="text-sm text-gray-600">{account.externalId}</p>
              </div>
              <div>
                <h4 className="mb-2 font-medium text-gray-900">
                  Infringement description
                </h4>
                <p className="block text-sm text-gray-600 break-words">
                  {account.description}
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 pt-4 d">
            {account?.issueDate && (
              <div>
                <h4 className="mb-2 font-medium text-gray-900">Issue date</h4>
                <p className="text-sm text-gray-600">
                  {account?.issueDate?.toFormat('dd MMM yyyy')}
                </p>
              </div>
            )}
            {account?.dueDate && account.targetDate && (
              <div>
                <h4 className="mb-2 font-medium text-gray-900">Status</h4>
                <InfringementStatus
                  dueDate={account.dueDate}
                  targetDate={account.targetDate}
                  collectionExemption={!!account?.collectionExemption}
                  now={now}
                />
              </div>
            )}

            <div>
              <h4 className="mb-2 font-medium text-gray-900">Total balance</h4>
              <p className="text-sm text-gray-600">
                <NumberFormat
                  value={formatToDollars(account.amountOwing)}
                  displayType={'text'}
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      {error ? <ErrorMessage message={error} /> : null}

      {account?.collectionExemption || account.targetDate?.isBefore(now) ? (
        <ErrorMessage
          message={`This account is unable to be paid at this time. Please contact ${billerConfig.supportEmail} for more information.`}
          className="px-0"
        />
      ) : (
        <>
          {
            <button
              onClick={() => {
                infringementNavigate('/pay-now-features');
              }}
              className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Pay total now
            </button>
          }

          {account.targetDate?.isAfter(lastDayToSetupPlan) && (
            <button
              className="inline-flex items-center justify-center w-full px-6 py-3 mt-4 text-base font-medium text-center text-white transition bg-blue-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                infringementNavigate('/flexible-features');
              }}
            >
              Create flexible payment plan
            </button>
          )}

          <a
            target="_blank"
            className="text-center text-gray-500 cursor-pointer hover:underline"
            href="https://www.greaterdandenong.vic.gov.au/parking-infringements"
          >
            Something not right or need to request a review?
          </a>
        </>
      )}
      <button
        type="button"
        className="mt-6 text-blue-600 transition hover:text-blue-700"
        onClick={goBack}
      >
        Back
      </button>
    </div>
  );
};
