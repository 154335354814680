import React, {useContext} from 'react';
import {useActor} from '@xstate/react';
import {XCircleIcon} from '@heroicons/react/20/solid';

import * as Auth from 'lib/auth/Auth';
import {buttonClasses} from 'lib/styles';
import {validatePhoneNumber, err} from 'payble-shared';

import {ButtonSpinner} from 'components/atoms/Spinner';
import {useBillerConfig} from '../../../lib/appConfig/useBillerConfig';

type SendCodeProps = {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  onSend: () => void;
};

export const SendCode: React.FC<SendCodeProps> = ({
  phoneNumber,
  setPhoneNumber,
  onSend,
}) => {
  const {authService} = useContext(Auth.Context);
  const [authState] = useActor(authService);
  const billerConfig = useBillerConfig();

  const validPhoneNumber = validatePhoneNumber(phoneNumber);

  // Submit form on enter
  const onReturn = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!authState.matches('sendingSMS') && !err(validPhoneNumber)) {
        onSend();
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-72 mx-auto">
      <p className="mt-8 text-lg w-72 font-bold text-center text-navy">
        Enter your mobile number
      </p>
      <p className="mt-4 w-72 text-center text-sm text-gray-600">
        You will receive an SMS code to confirm your identity. By entering your
        phone number you consent to Payble contacting you by SMS with a 6 digit
        one time code.
      </p>

      <form
        onSubmit={e => {
          e.preventDefault();
          onSend();
        }}
      >
        <input
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          onKeyUp={e => onReturn(e)}
          type="tel"
          autoFocus={true}
          className="mt-4 w-60 focus:ring-blue-600 focus:border-blue-600 block shadow-sm border-gray-300 rounded-md text-xl text-center placeholder-gray-200"
          placeholder={billerConfig.mobileNumberPlaceholder}
        />

        <button
          type="submit"
          className={buttonClasses}
          disabled={authState.matches('sendingSMS') || err(validPhoneNumber)}
        >
          {authState.matches('sendingSMS') && <ButtonSpinner />}
          Send my SMS code
        </button>
      </form>
      {authState.matches('smsFailed') && (
        <div className="rounded-md bg-red-50 p-4 mt-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {authState.context.errorMessage ?? 'Error while sending SMS'}
              </h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
